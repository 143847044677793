import React, { useContext, useEffect, useState, useMemo } from "react";
import { Button, Col, Row, Input, Table } from "antd";
import "../assets/css/omessage.css";
import { AppContext } from "../context/AppContext";
import "./../assets/css/users.css";
import { DeleteComponent } from "./utils/CommonVessels";
import HsButton from "../components/custom/input/button/Button";
import { successNotifyWithDescription } from "./utils/CommonNotifications";
import { CommonSpinLoading, formatDate } from "./utils/CommonVessels";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import useHttp from "../hooks/useHttp";

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault("local");

function BlockedNumbers() {
  const [appProperties] = useContext(AppContext);
  const { fetchData } = useHttp();
  const [blockedNumberValue, setBlockedNumberValue] = useState("");
  const [savedBlockedNumbers, setSavedBlockedNumbers] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [blockedNumberValueFailure, setBlockedNumberValueFailure] = useState([]);
  const [searchValue, setSearchValue] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value;
    const validInput = value.replace(/[^0-9,+\s]/g, "");
    setBlockedNumberValue(validInput);
  };

  useEffect(() => {
    const fetchBlockedNumbers = async () => {
      if (appProperties) {
        try {
          const body = "asc";
          const response = await fetchData(`blockednumbers?asc=false`, "GET", body, appProperties);
          const responseData = JSON.parse(response);

          const blockedNumbers = responseData?.data?.blockedNumbers || [];
          const dataSource = blockedNumbers.map((item, index) => ({
            email: appProperties?.licenseObj?.allLicensedUserDetails.find((user) => user.osyncUserId === item.createdBy)?.email ?? "-",
            key: index,
            blockedNumber: item.blockedNumber,
            createdTime: item.createdTime,
          }));

          setSavedBlockedNumbers(dataSource);
          setShowTable(dataSource.length > 0);
        } catch (error) {
          console.error("Error fetching blocked numbers:", error);
          setSavedBlockedNumbers([]); // Reset blocked numbers in case of failure
          setShowTable(false);
        } finally {
          setShowLoading(false); // Ensure this is always called
        }
      }
    };

    fetchBlockedNumbers();
  }, [appProperties]);

  const addBlockedNumbers = async () => {
    const trimmedBlockedNumber = blockedNumberValue.trim();
    if (!trimmedBlockedNumber) {
      setSearchValue(false);
      setBlockedNumberValueFailure("Enter a number to block!");
      return;
    }
    const isAlreadyBlocked = filteredBlockedNumbers.find((obj) => obj?.blockedNumber === trimmedBlockedNumber);
    if (isAlreadyBlocked) {
      setSearchValue(false);
      setBlockedNumberValueFailure("Number already blocked.");
      return;
    }

    try {
      setShowTable(true);
      const response = await fetchData(`blockednumber?phoneNumber=${encodeURIComponent(trimmedBlockedNumber)}`, "POST", null, appProperties);
      const responseData = JSON.parse(response);
      const blockedNumber = responseData?.data || {};
      blockedNumber.email =
        appProperties?.licenseObj?.allLicensedUserDetails.find((user) => user.osyncUserId === blockedNumber?.createdBy)?.email ?? "not found";
      setSavedBlockedNumbers((prev) => [blockedNumber, ...prev]);
      setBlockedNumberValue("");
      setBlockedNumberValueFailure(false); // Clear any error message
      successNotifyWithDescription("Add Blocked Numbers", "Number blocked successfully");
    } catch (error) {
      console.error("Error adding blocked number:", error);
      setBlockedNumberValueFailure("Failed to block the number. Please try again.");
    }
  };

  const deleteBlockedNumbers = async (record) => {
    const phoneNumber = record.blockedNumber;
    try {
      await fetchData(`blockednumber?phoneNumber=${encodeURIComponent(phoneNumber)}`, "DELETE", null, appProperties);
      setSavedBlockedNumbers((prev) => prev.filter((obj) => obj?.blockedNumber !== phoneNumber));
      successNotifyWithDescription("Deleted Blocked Numbers", "Number unblocked successfully!");
    } catch (error) {
      console.error("Error deleting blocked number:", error);
    }
  };

  const handleSearchChange = (e) => {
    if (e.key === "Enter") {
      const value = e.target.value.trim();
      if (!value) {
        setSearchValue(false);
        setSearchQuery("");
        return;
      }
      const localMatch = savedBlockedNumbers.find((number) => {
        return number.blockedNumber === value;
      });

      if (localMatch) {
        setSearchValue(false);
        setSearchQuery(localMatch.blockedNumber);
        return;
      }
      fetchData(`blockednumber?phoneNumber=${value}`, "GET", null, appProperties)
        .then((response) => {
          const responseData = JSON.parse(response);
          const blockedNumbers = responseData?.data?.blockedNumber;

          if (!blockedNumbers) {
            setSearchValue(true);
            setBlockedNumberValueFailure([]);
            setSearchQuery("");
            return;
          }

          setSearchValue(false);
          setSearchQuery(blockedNumbers);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setSearchValue(true);
        });
    }
  };

  const filteredBlockedNumbers = useMemo(() => {
    return searchQuery ? savedBlockedNumbers.filter((number) => number.blockedNumber.includes(searchQuery)) : savedBlockedNumbers;
  }, [searchQuery, savedBlockedNumbers]);

  const columns = [
    {
      title: "Phone Number",
      dataIndex: "blockedNumber",
      key: "blockedNumber",
      width: "15rem",
    },
    {
      title: "Blocked by user",
      dataIndex: "email",
      key: "email",
      width: "11rem",
    },
    {
      title: "Created Time",
      key: "createdTime",
      width: "15rem",
      dataIndex: "createdTime",
      render: (text) => {
        return <div>{formatDate(text)}</div>;
      },
    },
    {
      key: "deleteSelected",
      render: (record) => <DeleteModal record={record} appProperties={appProperties} deleteBlockedNumbers={deleteBlockedNumbers} />,
      width: "20%",
    },
  ];

  return (
    <>
      <div className="m-1, mt-3 ms-2">
        <Row align="middle" className="d-none d-lg-block">
          <Col span={11} className="ps-4">
            <div className="d-flex justify-content-start align-items-center">
              <Row className="mt-2">
                <Col style={{ cursor: "default" }} className="actionIconsSprite authInfo-icon"></Col>
                <Col className="ms-2 contentbarHeadingColor">Block numbers to stop messages and avoid spam effortlessly.</Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={14} lg={8}>
            <Row align="middle" className="ps-4 pt-4">
              <Col span={24}>
                <div className="d-flex justify-content-start hs-h-40 add-block-number">
                  <Input placeholder="Enter phone number to block" value={blockedNumberValue} onChange={handleInputChange} />
                </div>
                <div>
                  <div id="fdnameError" className="hs-fs-12">
                    {blockedNumberValueFailure}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col xs={10} lg={9} className="pt-4 ps-4 ">
            <div className="d-flex justify-content-start align-items-center">
              <HsButton buttonClassName="" onClick={addBlockedNumbers} icon={<div className="actionIconsSprite blockedNumbersWhiteIcon" />}>
                Add to Block List
              </HsButton>
            </div>
          </Col>
          <Col xs={24} lg={7}>
            <Row align="middle" className="pt-4 ps-lg-5 ps-4">
              <Col span={22}>
                <div className="d-flex justify-content-end hs-h-40 add-block-number">
                  <Input
                    className="contact-search-input hs-border-8"
                    prefix={<span className="actionIconsSpriteForSideBar blockedNumberSearchIcon" />}
                    placeholder="Look up blocked numbers"
                    onKeyDown={handleSearchChange}
                  />
                </div>
                <div>
                  <div id="fdnameError" className="hs-fs-12" style={{ display: searchValue ? "inherit" : "none" }}>
                    Phone Number not found!
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        {showLoading ? (
          <CommonSpinLoading />
        ) : showTable ? (
          <Table
            className="savedTempTable automationTable ms-3"
            scroll={{ x: 1100, y: 520 }}
            pagination={false}
            columns={columns}
            dataSource={filteredBlockedNumbers}
          />
        ) : null}
      </div>
    </>
  );
}

export default BlockedNumbers;

const DeleteModal = ({ record, appProperties, deleteBlockedNumbers }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <>
      <DeleteComponent
        modalOpen={deleteModalOpen}
        setModalOpen={setDeleteModalOpen}
        record={record}
        confirmDeleteIcon={"confirmDeleteIcon"}
        confirmDeleteBtnName={"UnBlock"}
        confirmDeleteComponentTitle={"Unblock Number?"}
        confirmDeleteComponent={
          "Unblocking the number will allow it to send and receive messages/calls again. Are you sure you want to proceed?"
        }
        appProperties={appProperties}
        handleDelete={deleteBlockedNumbers}
      />
      <Button
        onClick={() => {
          setDeleteModalOpen(true);
        }}
        type="link"
        style={{ color: "#605bff" }}
      >
        <span className="actionIconsSprite unBlockIcon"></span>Unblock
      </Button>
    </>
  );
};
