import { Button, Form, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import useHttp from "../../../hooks/useHttp";
import VoiceSelect from "../common/VoiceSelect";

const MusicUploadComponent = ({ callFlowId, nodeId, musicData, setMusicData, integId, inputValue, switchChecked }) => {
    const { fetchData } = useHttp();
    const [loading, setLoading] = useState(false);
    const [appProperties] = useContext(AppContext)
    const [inputForm] = Form.useForm();
    const [textState, setTextState] = useState('');

    const fetchMusicData = () => {
        console.log("fffFetch Automated Message -use-effect-", nodeId, integId, callFlowId, musicData?.apply)
        if (callFlowId && integId && !musicData?.apply) {
            console.log("fff4Fetch Automated Message -use-effect-", nodeId, integId, callFlowId)

            fetchData(`voice/${integId}/callFlow/${callFlowId}/automatedMessage?nodeId=${nodeId}`, "GET", null, appProperties).then((res) => {
                const responseData = JSON.parse(res);
                const contentType = responseData?.data?.[0]?.automatedMessageContentType;
                console.log("Automated Message Name>>>", responseData?.data?.[0]?.automatedMessageName || "default.mp3")
                if (contentType === 1) {
                    setMusicData(prevState => ({
                        ...prevState,
                        selectType: "playAudio",
                        fileName: responseData?.data?.[0]?.automatedMessageName || "default.mp3",
                        type: "Audio File",
                        nodeId: nodeId
                    }));
                } else if (contentType === 2) {
                    setMusicData({
                        ...musicData,
                        selectType: "url",
                        url: responseData?.data?.[0]?.automatedMessage,
                        type: "URL",
                        nodeId: nodeId
                    });
                } else if (contentType === 3) {
                    setMusicData({
                        ...musicData,
                        selectType: "textToSpeech",
                        text: responseData?.data?.[0]?.automatedMessage,
                        type: "TTS",
                        nodeId: nodeId
                    });
                    setTextState(responseData?.data?.[0]?.automatedMessage)
                }
            });
        }
    };
    console.log("Music data", musicData)

    useEffect(() => {

        if (integId) {
            fetchMusicData();
            console.log("Fetching data for the first time", nodeId);
        }
    }, [callFlowId, nodeId, integId]);

    const handleTypeChange = (value) => {
        console.log("Value>>>", value)
        setMusicData({ ...musicData, selectType: value });
    };

    const handleApply = async () => {

        if (musicData?.file && musicData?.selectType === "playAudio") {
            setMusicData({
                ...musicData,
                music: musicData?.file,
                type: "Audio File",
                nodeId: nodeId,
                apply: true
            });
            message.success(`${musicData?.file.name} uploaded successfully.`);
        } else if (musicData?.text && musicData?.selectType === "textToSpeech") {
            const fields = await inputForm.validateFields();
            console.log("debug_t fields", fields);
            setMusicData({
                ...musicData,
                music: musicData?.text,
                type: "TTS",
                nodeId: nodeId,
                apply: true
            });
            message.success(`Applied successfully.`);
        } else if (musicData?.url && musicData?.selectType === "url") {
            setMusicData({
                ...musicData,
                music: musicData?.url,
                type: "URL",
                nodeId: nodeId,
                apply: true
            });
            message.success(`Applied successfully.`);
        }
        else if (inputValue) {
            setMusicData({
                ...musicData,
                apply: true
            });
            message.success("Applied Successfully.")
        }
    };

    const props = {
        name: "file",
        multiple: false,
        accept: ".mp3,.wav",
        onChange(info) {
            setMusicData({
                ...musicData,
                fileName: info.file.name,
                file: info.file.originFileObj,
            });
        },
        onDrop(e) {
            const droppedFile = e.dataTransfer.files[0];
            if (droppedFile) {
                setMusicData({
                    ...musicData,
                    fileName: droppedFile.name,
                    file: droppedFile,
                });
            }
        },
    };

    const options = [
        { value: "textToSpeech", label: "Text to speech" },
        { value: "playAudio", label: "Play Audio" },
        { value: "url", label: "Url" },
    ];
    console.log("Voice Mail status>>", switchChecked)




    console.log("Music Data", musicData)
    return (
        <div>
            {console.log("Music data", musicData)}
            <Form form={inputForm} >
                <>
                    <VoiceSelect
                        onChange={handleTypeChange}
                        options={options}
                        value={musicData?.selectType}
                        props={props}
                        loading={loading}
                        fileName={musicData?.fileName}
                        textToSpeech={musicData?.text}
                        setTextToSpeech={(value) => setMusicData(prevState => ({
                            ...prevState,
                            text: value
                        }))}
                        url={musicData?.url}
                        setUrl={(value) => setMusicData({ ...musicData, url: value })}
                        optionSpecification={true}
                        label={"Type"}
                    />

                    <Button className="callFlowButton mt-4" onClick={handleApply}>
                        Apply
                    </Button>
                </>
            </Form>

        </div >

    );
};

export default MusicUploadComponent;
