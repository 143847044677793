import { Col, Row, Spin, Drawer, Form, List, Select, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import "../assets/css/voiceTeam.css";
import "../assets/css/base-styles.css";
import { useVoiceTeams } from "../hooks/useVoiceTeams";
import VoiceTeamCard from "./common/VoiceTeamCardComponent";
import Input from "../../components/custom/input/input/Input";
import HSelect from "../../components/custom/input/select/Select";
import { AppContext } from "../../context/AppContext";
import useUsers from "../../hooks/useUsers";
import CommonUserAvatar from "../CommonUserAvatar";
import { getCurrentFeature, voicePermissionOptions } from "../utils/VoiceUtils";
import { DeleteComponent, EmptyListView } from "../../pages/utils/CommonVessels";
import { featureUpgradeAlertPopup } from "../../pages/utils/CommonNotifications";
import VoiceTeamAgentsModal from "./common/VoiceTeamAgentsModal";
import HButton from "../../components/custom/input/button/Button";
import useHttp from "../../hooks/useHttp";
import { useVoiceContext } from "../context/VoiceContext";

const VoiceTeam = () => {
  const [voiceTeams, setVoiceTeams] = useState([]);
  const [callProperties, setCallProperties] = useVoiceContext();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [selectedTeamObj, setSelectedTeamObj] = useState({});
  const [isEditClick, setIsEditClick] = useState(false);
  const [viewAgentsModalOpen, setViewAgentsModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [teamToDelete, setTeamToDelete] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [usersToDelete, setUsersToDelete] = useState([]);
  const [addAgentSelectValue, setAddAgentSelectValue] = useState("");
  const [responseObj, setResponseObj] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { getVoiceTeams, createTeam, updateTeam, deleteTeamWithAgents } = useVoiceTeams();
  const [pipeDriveUsers, setPipeDriveUsers] = useState([]);
  const [upgradeAlertPopup, setUpgradeAlertPopup] = useState(false);

  const [licensedUsers, setLicensedUsers] = useState([]);
  const [form] = Form.useForm();
  const [appProperties] = useContext(AppContext);
  const { fetchUsers } = useUsers();

  useEffect(() => {
    const featureName = getCurrentFeature(appProperties);
    if (featureName === "Message") {
      setUpgradeAlertPopup(true);
    }
  }, [appProperties]);

  const fetchPipeDriveUsers = () => {
    if (appProperties) {
      setUsersLoading(true);
      fetchUsers(appProperties).then((response) => {
        if (response) {
          const updatedUsers = appProperties?.licenseObj?.allLicensedUserDetails?.map((user) => {
            const userData = response?.data?.find((item) => item.id === user.remoteUserId);
            return {
              ...user,
              ...userData,
              firstName: userData?.firstName || user?.email?.split("@")[0],
              voicePermission: 1,
            };
          });
          setPipeDriveUsers(response?.data);
          setLicensedUsers(updatedUsers);
          setUsersLoading(false);
          return response;
        }
      });
    }
  };
  console.log("AppProperties", appProperties);
  const handlePermissionChange = (value, osyncUserId) => {
    const updatePermission = (users) => users.map((user) => (user.osyncUserId === osyncUserId ? { ...user, voicePermission: value } : user));
    setSelectedUsers((prevUsers) => updatePermission(prevUsers));
  };
  console.log("userstodelete", usersToDelete);

  const handleAddAgents = (osyncUserId, Obj) => {
    setAddAgentSelectValue(Obj?.label);
    setUsersToDelete((prev) => {
      if (Array.isArray(prev)) {
        return prev.filter((id) => id !== osyncUserId);
      }
      return [];
    });
    const selectedUserObj = licensedUsers?.find((user) => user.osyncUserId === osyncUserId);
    if (osyncUserId != null) {
      if (selectedUserObj && !selectedUsers.some((user) => user.osyncUserId === selectedUserObj.osyncUserId)) {
        setSelectedUsers((prevSelected) => [...prevSelected, selectedUserObj]);
      }
    }
  };
  useEffect(() => {
    setIsLoading(true);
    getVoiceTeams()
      .then((response) => {
        setVoiceTeams(response);
        setResponseObj(false);
      })
      .finally(() => setIsLoading(false));
  }, [responseObj, callProperties.agentStatusValue]);

  useEffect(() => {
    if (appProperties?.leftServiceId && appProperties?.randomIntegId) {
      fetchPipeDriveUsers();
    }
  }, [appProperties?.leftServiceId, appProperties?.randomIntegId]);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
    setAddAgentSelectValue("");
    setIsEditClick(false);
  };
  const handleDrawerClose = () => {
    setAddAgentSelectValue("");
    setIsEditClick(false);
    setIsDrawerOpen(false);
    form.resetFields();
    setSelectedUsers([]);
    setUsersToDelete([]);
  };
  const handleCreateTeam = async () => {
    if (selectedUsers.length <= 0) {
      message.warning("Please select at least one user to create a team.");
      return;
    }

    try {
      setButtonLoading(true);

      const payload = {
        friendlyName: form.getFieldValue("friendlyName"),
        agentList: selectedUsers,
      };

      const response = await createTeam(payload);
      setButtonLoading(false);

      if (response) {
        setResponseObj(true);
        setAddAgentSelectValue("");
        setIsDrawerOpen(false);
        message.success("Team Created Successfully.");
      } else {
        message.error("Failed to create the team. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      setButtonLoading(false);
      message.error("An error occurred while creating the team. Please try again.");
    }
  };

  const handleEditTeam = () => {
    try {
      setButtonLoading(true);
      const payload = {
        usersToAdd: selectedUsers,
        friendlyName: form.getFieldValue("friendlyName"),
        usersToDelete: usersToDelete,
      };
      if (selectedTeamId) {
        updateTeam(selectedTeamId, payload).then((response) => {
          if (response) {
            setAddAgentSelectValue("");
            setResponseObj(true);
            setButtonLoading(false);
            setIsDrawerOpen(false);
            setUsersToDelete([]);
            setSelectedUsers([]);
            message.success("Team Updated Successfully.");
          }
        });
      }
    } catch (error) {
      console.log("Error", error);
      setButtonLoading(false);
    }
  };

  const handleDeleteAgent = async (osyncUserId) => {
    if (selectedUsers.length <= 1) {
      message.error("At least one user must remain in the team.");
      return;
    } else {
      setUsersToDelete([...usersToDelete, osyncUserId]);
      setSelectedUsers((prev) => prev.filter((user) => user.osyncUserId !== osyncUserId));
    }
  };
  const onAddAgentsClick = (teamObj) => {
    setSelectedTeamId(teamObj?.teamId);
    setIsEditClick(true);
    form.setFieldValue("friendlyName", teamObj?.friendlyName);

    const existingAgentIds = teamObj?.voiceAgentsEntity?.map((agent) => agent.osyncUserId);

    const updatedSelectedUsers = licensedUsers
      .filter((user) => {
        const isAgent = existingAgentIds.includes(user.osyncUserId);
        const isAlreadySelected = selectedUsers.some((selectedUser) => selectedUser.osyncUserId === user.osyncUserId);
        return isAgent && !isAlreadySelected;
      })
      .map((user) => {
        const agentFromTeam = teamObj.voiceAgentsEntity.find((agent) => agent.osyncUserId === user.osyncUserId);
        return {
          ...user,
          voicePermission: agentFromTeam?.voicePermission ?? user.voicePermission,
        };
      });
    console.log("updatedSelectedUsers", updatedSelectedUsers);
    // if (updatedSelectedUsers) {
    //   setIsDrawerOpen(true);
    //   setSelectedUsers((prevSelected) => [...prevSelected, ...updatedSelectedUsers]);
    // }
    if (updatedSelectedUsers.length > 0) {
      setSelectedUsers((prevSelected) => {
        const newSelectedUsers = [...prevSelected, ...updatedSelectedUsers];
        setIsDrawerOpen(true);
        return newSelectedUsers;
      });
    }
  };

  const onViewAgentsClick = (teamObj) => {
    setViewAgentsModalOpen(true);

    const agentsDetails = teamObj.voiceAgentsEntity.map((agent) => {
      const licensedUser = licensedUsers.find((user) => user.osyncUserId === agent.osyncUserId);
      return { ...agent, ...licensedUser };
    });

    const onlineAgents = agentsDetails.filter((agent) => teamObj?.teamMetrics.onlineAgents.includes(agent.osyncUserId));
    const busyAgents = agentsDetails.filter((agent) => teamObj?.teamMetrics.busyAgents.includes(agent.osyncUserId));
    const offlineAgents = agentsDetails.filter((agent) => teamObj?.teamMetrics.offlineAgents.includes(agent.osyncUserId));

    setSelectedTeamObj({ onlineAgents, busyAgents, offlineAgents });
  };

  const showDeleteModalPopup = (teamId) => {
    setShowDeleteModal(true);
    setTeamToDelete(teamId);
  };
  const handleDeleteTeam = (teamId) => {
    deleteTeamWithAgents(teamId)
      .then((response) => {
        const deletedTeam = response;
        setVoiceTeams((prevTeams) => prevTeams.filter((team) => team.teamId !== deletedTeam.teamId));
        message.success("Team deleted Successfully.");
      })
      .catch((error) => {
        console.error("Error deleting team:", error);
      });
  };

  const handleModalClose = () => {
    setViewAgentsModalOpen(false);
  };
  console.log("Team selectedUsers", selectedUsers, licensedUsers, usersToDelete);
  return (
    <div className="p-lg-3">
      <Row justify="space-between" align="middle" className="pb-2">
        <Row>
          <Col className="voice-info-icon callActionIcons me-lg-2" style={{ cursor: "default" }}></Col>
          <Col>
            To learn more about the teams page,{" "}
            <a href="" target="_blank" rel="noreferrer" className="custom-anchor">
              Click here
            </a>
          </Col>
        </Row>
        <Row className="me-lg-3">
          <HButton
            buttonClassName="create-Team-Btn"
            size="l"
            onClick={handleDrawerOpen}
            icon={<div className="actionIconsSprite plusIconWhite" />}
          >
            Create Team
          </HButton>
        </Row>
      </Row>

      {isLoading ? (
        <div className="d-flex align-items-center flex-column">
          <Spin className="m-5 pt-5" indicator={<LoadingOutlined spin className="mt-5" />} />
        </div>
      ) : voiceTeams.length > 0 ? (
        <VoiceTeamCard
          voiceTeams={voiceTeams}
          onAddAgentsClick={onAddAgentsClick}
          showDeleteModalPopup={showDeleteModalPopup}
          onViewAgentsClick={onViewAgentsClick}
        />
      ) : (
        <div className="d-flex align-items-center justify-content-center flex-column">
          <div className="emptyTeamsPageIcon" />
          <div className="fw-bold">No Teams Record</div>
          <div className="hs-color-dark-grey mt-2">Your Team Records will appear here</div>
        </div>
      )}
      <Drawer
        rootClassName="createFlowDrawer"
        title={<div className="p-2 fs-6">{isEditClick ? "Edit Team" : "Create Team"}</div>}
        onClose={handleDrawerClose}
        open={isDrawerOpen}
        width={510}
        footer={
          <HButton
            buttonClassName="w-100"
            onClick={() => {
              if (isEditClick) {
                handleEditTeam();
              } else {
                handleCreateTeam();
              }
            }}
            loading={buttonLoading}
          >
            {isEditClick ? "Update" : "Save"}
          </HButton>
        }
      >
        <Form form={form}>
          <Row>
            <Input
              placeHolder="Enter a friendly name"
              formItemName="friendlyName"
              formItemLabel="Friendly Name"
              required={true}
              errorMessage="Please provide a friendly name."
              autoFocus
              size="m"
            />
            <label className="mt-lg-4">Add User</label>
            <HSelect
              customProps={{
                value: addAgentSelectValue,
                className: "mt-lg-2",
                onChange: (value, obj) => {
                  handleAddAgents(value, obj);
                },
              }}
              placeholder="Choose Agents"
              options={licensedUsers?.map((user) => ({
                value: user.osyncUserId,
                label: user?.firstName,
              }))}
            />
            <div className="w-100 mt-lg-3">
              <div className="d-flex flex-row justify-content-between">
                <span className="fs-14-fw-500">Users</span>
                <span className="fs-14-fw-500">Access</span>
                <span></span>
              </div>

              <List
                className="drawerList mt-lg-2"
                itemLayout="horizontal"
                loading={isEditClick ? (selectedUsers.length > 0 ? false : true) : false}
                dataSource={selectedUsers}
                renderItem={(item, index) => {
                  return (
                    <List.Item
                      actions={[
                        <div
                          className="callActionIcons voiceAgentsDeleteIcon cursor-pointer me-4"
                          onClick={() => handleDeleteAgent(item?.osyncUserId)}
                        />,
                      ]}
                    >
                      <Col span={10}>
                        <List.Item.Meta
                          avatar={<CommonUserAvatar name={item?.firstName} icon={item?.avatar} size={40} />}
                          title={<p className="mt-2 fs-14-fw-500">{item?.firstName}</p>}
                        />
                      </Col>
                      <Col>
                        <Select
                          // defaultValue={item?.voicePermission || 1}
                          value={item?.voicePermission}
                          rootClassName=" teams-permission-select"
                          className=" me-1 fs-6 mb-1"
                          suffixIcon={<div className="permissionDropDownIcon callActionIcons" />}
                          dropdownStyle={{
                            width: "160px",
                          }}
                          options={voicePermissionOptions}
                          onChange={(value) => {
                            handlePermissionChange(value, item.osyncUserId);
                          }}
                        ></Select>
                      </Col>
                    </List.Item>
                  );
                }}
                locale={{
                  emptyText: (
                    <EmptyListView
                      // tagLineOne={<div className="text-white">No call history available</div>}
                      icon={`emptyTeamsPageIcon display-inline-block`}
                      tagLineTwo={`No Users added`}
                      tagHeight={"43vh"}
                    />
                  ),
                }}
              />
            </div>
          </Row>
        </Form>
      </Drawer>

      <DeleteComponent
        confirmDeleteComponentTitle="Delete Team?"
        confirmDeleteIcon="hs-delete-icon"
        confirmDeleteBtnName="Delete"
        modalOpen={showDeleteModal}
        setModalOpen={setShowDeleteModal}
        record={teamToDelete}
        confirmDeleteComponent={"Deleting the team will remove it and unlink all users assigned to it. Are you sure you want to proceed?"}
        appProperties={appProperties}
        handleDelete={handleDeleteTeam}
      />
      <VoiceTeamAgentsModal
        viewAgentsModalOpen={viewAgentsModalOpen}
        setViewAgentsModalOpen={setViewAgentsModalOpen}
        handleModalClose={handleModalClose}
        selectedTeamObj={selectedTeamObj}
      />
      {upgradeAlertPopup && featureUpgradeAlertPopup(appProperties, upgradeAlertPopup, setUpgradeAlertPopup)}
    </div>
  );
};

export default VoiceTeam;
