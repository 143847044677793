import { ExportOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useRef, useState } from "react";

import { Button, Col, Drawer, Dropdown, Form, Image, Menu, Row, Table, Tabs, Tooltip, Typography } from "antd";
import { AppContext } from "../context/AppContext";
import useTemplates from "../hooks/useTemplates";
import useWorkFlow from "../hooks/useWorkFlow";
import "./../assets/css/templates.css";
import { CommonSpinLoading, DeleteComponent, getLabelForTemplate, removeKeysFromUrlParams } from "./utils/CommonVessels";

import EmtpyBoxSVG from "../assets/svg/empty-box .svg";
import { ActivityDetailsModal } from "./utils/CommonVessels";

import CheckboxGroup from "../components/custom/input/checkbox/CheckboxGroup";
import { APP_URL, ASSIST_MAIL, WHATSAPP_BETA_DISPLAY_NAME } from "../constants/AppConstants";
import AddSMSTemplateDrawer from "./templates/drawer/AddSMSTemplateDrawer";
import AddWhatsappTemplateDrawer from "./templates/drawer/AddWhatsappTemplateDrawer";
import { failureNotificationWithLink, successNotifyWithDescription } from "./utils/CommonNotifications";
import { mapToMediaList } from "./utils/commonUtils";
import { EmptyListView, NoChannelPresent, formatDate } from "./utils/CommonVessels";
const { Text } = Typography;

function Templates() {
  const [appProperties] = useContext(AppContext);
  const { fetchSavedTemplates, deleteTemplate } = useTemplates();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [savedTemplates, setSavedTemplates] = useState([]);
  const [callSavedTempTable, setCallSavedTempTable] = useState(true);
  const [currentIntegration, setCurrentIntegration] = useState(appProperties?.installedApps?.[0]);
  let urlParams = new URLSearchParams(window.location.search);

  const [templateType, setTemplateType] = useState(
    urlParams.has("ttype")
      ? () => {
          const type = urlParams.get("ttype");
          if (type) {
            removeKeysFromUrlParams("ttype");
            if (type === "wp") {
              return "whatsapp";
            } else {
              return "application";
            }
          } else {
            return "application";
          }
        }
      : "application"
  );

  const [selectedModule, setSelectedModule] = useState("");
  const [content, setContent] = useState({
    deal: "",
    lead: "",
    person: "",
    organization: "",
  });
  const [fileList, setFileList] = useState([]);

  const [friendlyName, setFriendlyName] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [tempModal, setTempModal] = useState("");
  const [openPopover, setOpenPopover] = useState({});
  const [showTable, setShowTable] = useState(false);
  const [isWfDrawerOpen, setIsWfDrawerOpen] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [disabledSelect, setDisabledSelect] = useState(false);
  const [userActivityData, setUserActivityData] = useState();
  const [activityDetailsModalOpen, setActivityDetailsModalOpen] = useState(false);
  const [visibility, setVisibility] = useState("2");
  const [visibleDropdown, setVisibleDropdown] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [matchedPhoneAccessValue, setMatchedPhoneAccessValue] = useState();

  let openTemplateOffCanvas = urlParams.get("openTemplate");
  let moduleResource = urlParams.get("resource");

  useEffect(() => {
    console.log("openTemplateOffCanvas>>>>>>>>>>>>>>>>>>>", openTemplateOffCanvas);
    if (openTemplateOffCanvas) {
      setIsDrawerOpen(true);
      setTempModal("AddTemp");
      setSelectedModule(moduleResource);

      const url = new URL(window.location.href);

      // Get the search parameters
      const params = new URLSearchParams(url.search);

      params.delete("openTemplate");

      // Change the endpoint to '/users'
      const newEndpoint = "/templates";

      // Construct the new URL
      const newUrl = `${url.origin}${newEndpoint}?${params.toString()}`;

      // Update the URL in the browser without reloading
      window.history.pushState({}, "", newUrl);
    }
  }, [openTemplateOffCanvas]);

  const addTemplate = () => {
    setIsDrawerOpen(true);
    setFriendlyName("");
    setSelectedModule(moduleResource);
    setTemplateId("");
    setDisabledSelect(false);
    setTempModal("AddTemp");
    setVisibility("2");
  };
  const editTemplate = (tempData) => {
    setVisibleDropdown(null);
    setFriendlyName(tempData.friendlyName);
    setSelectedModule(tempData.moduleName);
    setDisabledSelect(true);
    setContent({ ...content, [tempData.moduleName]: tempData.content });
    setTemplateId(tempData.templateId);
    setTempModal("updateTemp");
    setSelectedTemplate(tempData);
    setTemplateType(tempData?.templateType);

    setFileList(tempData?.mediaFileList ? mapToMediaList(tempData?.mediaFileList, false) : []);
    setIsDrawerOpen(true);
    if (tempData.visibility === "Private") {
      setVisibility("1");
    } else if (tempData.visibility === "Team") {
      setVisibility("2");
    }
  };

  const deleteTemp = (templateData, setModalOpen) => {
    var templateId = templateData.templateId;
    deleteTemplate(appProperties, templateId).then((response) => {
      if (response?.success === true) {
        setCallSavedTempTable(true);
        successNotifyWithDescription("Delete Template", "Template deleted. Create a new one as needed.");
      }
      setOpenPopover((prevOpenPopover) => ({
        ...prevOpenPopover,
        [templateId]: false,
      }));
    });
  };
  const handleMenuClick = (e, record) => {
    setVisibleDropdown(visibleDropdown === record.templateId ? null : record.templateId);
  };

  const menu = (record) => <TemplateDelete record={record} />;

  const TemplateDelete = (props) => {
    const [open, setOpen] = useState(false);
    const { record } = props;

    const handleDeleteClick = () => {
      setOpen(true);
      setVisibleDropdown(null); // Close the dropdown when opening the modal
    };
    return (
      <>
        <Menu>
          <Menu.Item key="editTemplate" onClick={() => editTemplate(record)}>
            <div className="actionIconsSprite editTemplate-icon">
              <span className="ps-4" style={{ fontSize: "12px" }}>
                Edit
              </span>
            </div>
          </Menu.Item>
          <Menu.Item key="deletetemplate" onClick={() => handleDeleteClick(record)}>
            <div className="actionIconsSprite deleteTemplate-icon">
              <span className="ps-4" style={{ fontSize: "12px" }}>
                Delete
              </span>
            </div>
          </Menu.Item>
        </Menu>
        <DeleteComponent
          modalOpen={open}
          setModalOpen={setOpen}
          record={record}
          confirmDeleteIcon={"hs-delete-icon"}
          confirmDeleteBtnName={"Delete"}
          confirmDeleteComponentTitle={"Sure you want to Delete ?"}
          confirmDeleteComponent={"delete this template? It might still be used in automations or campaigns."}
          appProperties={appProperties}
          handleDelete={deleteTemp}
        />
      </>
    );
  };

  const templateTableColumns = [
    {
      title: "Friendly Name",
      dataIndex: "friendlyName",
      key: "friendlyName",
      width: 30,
      render: (_, item) => {
        return getLabelForTemplate(item.friendlyName, item.templateType, item?.content);
      },
    },
    {
      title: "Visibility",
      dataIndex: "visibility",
      key: "visibility",
      width: 30,
    },
    {
      title: "Module",
      dataIndex: "moduleName",
      key: "moduleName",
      width: 30,
    },
    {
      title: "Created Time",
      key: "parsedCreatedTime",
      dataIndex: "createdTime",
    },
    {
      key: "editDeleteSettings",
      render: (record) => (
        <Dropdown
          rootClassName="editDeleteSettingDropdown"
          overlay={menu(record)}
          trigger={["click"]}
          visible={visibleDropdown === record.templateId}
          onVisibleChange={() => handleMenuClick(null, record)}
        >
          <div style={{ cursor: "pointer" }} className="actionIconsSprite  editDeleteSettingsIcon"></div>
        </Dropdown>
      ),
      width: "5%",
    },
  ];
  useEffect(() => {
    if ((appProperties?.serviceInstalled && callSavedTempTable === true) || appProperties.subAccount) {
      fetchSavedTemplates(appProperties).then((response) => {
        const updatedSavedTable = response?.data?.map((templateEntity) => {
          let visibilityText = "";
          if (templateEntity?.visibility) {
            if (templateEntity.visibility === 1) {
              visibilityText = "Private";
            } else if (templateEntity.visibility === 2) {
              visibilityText = "Team";
            }
          } else {
            visibilityText = "Team";
          }
          var createdOrModifyBy = templateEntity.usersList;
          return {
            ...templateEntity,
            parsedCreatedTime: <div>{formatDate(templateEntity?.createdTime)}</div>,
            visibility: visibilityText,
            createdBy: JSON.stringify(createdOrModifyBy),
          };
        });

        setSavedTemplates(updatedSavedTable);

        if (response?.data !== null) {
          setShowTable(true);
        }
        setCallSavedTempTable(false);
        setShowLoading(false);
      });
    }
    const matchedPhoneAccess = appProperties?.licenseObj?.allLicensedUserDetails?.find(
      (user) => user?.remoteUserId === appProperties?.userId
    )?.phoneAccess;
    setMatchedPhoneAccessValue(matchedPhoneAccess);
  }, [appProperties.serviceInstalled, appProperties.subAccount, callSavedTempTable]);
  const handleCloseUser = () => {
    setActivityDetailsModalOpen(false);
  };

  return (
    <div className="m-3">
      {appProperties?.installedApps?.some((authorizeData) => authorizeData?.right?.auth?.authorized || matchedPhoneAccessValue !== 0) ? (
        <>
          <div className="d-flex align-items-center justify-content-between pb-1">
            <div className="ms-3">
              <Row className="mt-2">
                <Col className="actionIconsSprite authInfo-icon" style={{ cursor: "default" }}></Col>
                <Col className="ms-2">Simplify your messaging with customizable templates for efficient communication.</Col>
              </Row>
            </div>
            <Button className="addTempBtn" type="" onClick={addTemplate} icon={<div className="actionIconsSprite plusIconWhite" />}>
              Add Templates
            </Button>
          </div>

          {isDrawerOpen ? (
            <AddTemplates
              templateType={templateType}
              setTemplateType={setTemplateType}
              setIsDrawerOpen={setIsDrawerOpen}
              isDrawerOpen={isDrawerOpen}
              appProperties={appProperties}
              setCallSavedTempTable={setCallSavedTempTable}
              setSelectedModule={setSelectedModule}
              selectedModule={selectedModule}
              content={content}
              setContent={setContent}
              friendlyName={friendlyName}
              setFriendlyName={setFriendlyName}
              templateId={templateId}
              tempModal={tempModal}
              disabledSelect={disabledSelect}
              setVisibility={setVisibility}
              visibility={visibility}
              currentIntegration={currentIntegration}
              setCurrentIntegration={setCurrentIntegration}
              fileList={fileList}
              setFileList={setFileList}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
            />
          ) : null}

          {showLoading ? (
            <CommonSpinLoading />
          ) : showTable ? (
            <Table
              scroll={{ x: 1100, y: 515 }}
              className="savedTempTable ms-3 automationTable"
              pagination={false}
              columns={templateTableColumns}
              dataSource={savedTemplates}
              style={{ cursor: "text" }}
              locale={{
                emptyText: (
                  <EmptyListView
                    icon={"actionIconsSpriteForSideBar emptyAutomationListView display-inline-block"}
                    tagLineOne={"Add template now!"}
                    tagLineTwo={"Add Templates for Tailored Responses"}
                    tagHeight={"50vh"}
                  />
                ),
              }}
            />
          ) : (
            <div className="d-flex justify-content-center">
              <Image style={{ width: "10rem", marginTop: "10rem" }} src={EmtpyBoxSVG} preview={false} draggable={false} />
            </div>
          )}
          {isWfDrawerOpen ? (
            <WorkFlow
              templateId={templateId}
              appProperties={appProperties}
              isWfDrawerOpen={isWfDrawerOpen}
              setIsWfDrawerOpen={setIsWfDrawerOpen}
            />
          ) : null}
        </>
      ) : (
        <NoChannelPresent />
      )}
      <ActivityDetailsModal data={userActivityData} handleCloseUser={handleCloseUser} open={activityDetailsModalOpen} />
    </div>
  );
}

export default Templates;

const AddTemplates = React.memo((props) => {
  const {
    isDrawerOpen,
    setIsDrawerOpen,
    appProperties,
    setCallSavedTempTable,
    friendlyName,
    setFriendlyName,
    setSelectedModule,
    selectedModule,
    content,
    setContent,
    templateId,
    tempModal,
    disabledSelect,
    setVisibility,
    visibility,
    fileList,
    setFileList,
    setCurrentIntegration,
    currentIntegration,
    templateType,
    setTemplateType,
    selectedTemplate,
    setSelectedTemplate,
  } = props;

  const [module, setModule] = useState([]);

  const [showAddTemplateForm, setShowAddTemplateForm] = useState(isDrawerOpen);
  const [addWhatsappTemplateForm] = Form.useForm();

  const { fetchModules, fetchFields, addTemplate, updateTemplate } = useTemplates();
  const [searchFields, setSearchFields] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const [moduleFields, setModuleFields] = useState([]);
  const [filteredFields, setFilteredFields] = useState([]);
  const [invalidName, setInvalidName] = useState(false);
  const [invalidContent, setInvalidContent] = useState(false);
  const [invalidField, setInvalidField] = useState(false);
  const [fetchField, setFetchField] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [fieldLoading, setFieldLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [lookupsFields, setLookupModule] = useState();
  const friendlyNameRef = useRef(null);

  const handleUpdateTemplate = (templateBody, templateId) => {
    setLoading(true);
    updateTemplate(appProperties, templateBody, templateId)
      .then((response) => {
        if (response?.success === true) {
          setCallSavedTempTable(true);
          handleClose();
          successNotifyWithDescription("Edit Template", "Template updated! Your message is refreshed.");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (templateType === "whatsapp") {
      const whatsappIntegration = appProperties?.installedApps?.filter((installedApp) => installedApp?.right?.service_name === "whatsapp")?.[0];
      setCurrentIntegration(whatsappIntegration);
    }
  }, [templateType]);

  const handleClose = () => {
    if (templateId !== "") {
      setContent({ ...content, [selectedModule]: "" });
    }
    setSelectedTemplate();
    setTemplateType("application");
    setFileList([]);
    setShowAddTemplateForm(false);
    setIsDrawerOpen(false);
  };
  useEffect(() => {
    if (!friendlyName) {
      friendlyNameRef.current?.focus();
    }
  }, [friendlyName, fieldLoading]);

  useEffect(() => {
    if (appProperties?.serviceInstalled) {
      fetchModules(appProperties, null, "templates").then((response) => {
        setModule(response?.data ?? []);
        if (response) {
          setFetchField(true);
          if (selectedModule) {
            setSelectedModule(selectedModule);
          } else {
            setSelectedModule(response.data[0]);
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    setFieldLoading(true);
    const lookupFields = "true";
    if (appProperties?.serviceInstalled && selectedModule !== "" && fetchField === true) {
      fetchFields(appProperties, selectedModule, lookupFields).then((response) => {
        if (response && response?.fields?.length > 0) {
          const fieldData = response.fields;
          const combinedFields = [
            {
              fields: fieldData,
              moduleName: selectedModule,
              fieldNameDisplay: selectedModule,
            },
          ];
          setModuleFields(combinedFields);

          if (response && response?.lookups) {
            let fieldArr = [];

            response?.lookups?.forEach((lookupModuleFields) => {
              const lookupFieldData = lookupModuleFields.fields;
              const combinedFields = {
                fields: lookupFieldData,
                moduleName: lookupModuleFields.fieldName,
                fieldNameDisplay: lookupModuleFields.fieldApiName,
              };
              fieldArr.push(combinedFields);
            });
            setModuleFields((prev) => [...prev, ...fieldArr]);
          }

          setFieldLoading(false);
        } else {
          setModuleFields([]);
          setFieldLoading(false);
        }
      });
    }
  }, [selectedModule, fetchField]);

  const handleChangeModule = (value) => {
    setInvalidField(false);
    setSelectedModule(value);
  };

  const handleSearchField = (event) => {
    const field = event.target.value;
    setSearchFields(field);
  };

  useEffect(() => {
    if (searchFields === "") {
      setFilteredFields(moduleFields);
    } else {
      const filter = moduleFields
        ?.map((field) => ({
          ...field,
          fields: field.fields.filter((f) => f.fieldName?.toLowerCase().includes(searchFields.toLowerCase())),
        }))
        .filter((field) => field.fields.length > 0);
      setFilteredFields(filter);
    }
  }, [moduleFields, searchFields]);
  const handleSelectFields = (fieldApiName, lookupModule) => {
    var newContent = "";

    const selectedFields = moduleFields?.find((field) => field.fieldNameDisplay === lookupModule);

    const selectedFieldObj = selectedFields.fields.find((obj) => obj?.fieldApiName === fieldApiName);

    if (selectedFieldObj) {
      if (!lookupModule) {
        lookupModule = selectedModule;
      }
      const fields = " {{" + lookupModule + "." + selectedFieldObj.fieldApiName + "}} ";

      const beforeCursor = content[selectedModule]?.slice(0, cursorPosition);
      const afterCursor = content[selectedModule]?.slice(cursorPosition);

      if (!beforeCursor && !afterCursor) {
        newContent = fields;
      } else {
        newContent = beforeCursor + fields + afterCursor;
      }

      const newCursorPosition = cursorPosition + fields.length;
      setSelectedField([...selectedField, fieldApiName]);
      setContent({ ...content, [selectedModule]: newContent });
      setCursorPosition(newCursorPosition);
      setSearchFields("");
    }
  };

  const handleAddTemplateSuccessResponse = () => {
    setCallSavedTempTable(true);
    handleClose();
    setContent({ ...content, [selectedModule]: "" });
    successNotifyWithDescription("Add Template", "Template added! Personalize client messages with ease.");
    setLoading(false);
  };

  const handleAddTemplateFailureResponse = (response) => {
    var temLimit = response?.license?.description;
    failureNotificationWithLink(temLimit, "mail to", `mailto:${ASSIST_MAIL}`);
    setLoading(false);
  };

  const handleContentChange = (event) => {
    setContent({ ...content, [selectedModule]: event.target.value });
    setCursorPosition(event.target.selectionStart);
  };
  const handleTextAreaClick = (event) => {
    const cursorPosition1 = event.target.selectionStart;
    setCursorPosition(cursorPosition1);
  };
  const handleTemplate = async () => {
    if (templateType === "whatsapp") {
      try {
        const addWhatsappTemplateFormResponse = await addWhatsappTemplateForm.validateFields();
        let templateData = selectedTemplate;
        templateData.friendlyName = addWhatsappTemplateFormResponse?.friendlyName;
        templateData.moduleName = addWhatsappTemplateFormResponse?.selectedModule;
        templateData.visibility = 2; // Setting template visibility as TEAM by default
        if (tempModal === "AddTemp") {
          setLoading(true);
          addTemplate(appProperties, templateData, currentIntegration?.integProps?.integId).then((response) => {
            if (response?.success === true) {
              handleAddTemplateSuccessResponse();
            } else if (response?.license?.code === "FEATURE_LIMIT_EXCEEDED") {
              handleAddTemplateFailureResponse(response);
            }
          });
        } else {
          handleUpdateTemplate(templateData, templateData?.templateId);
        }
      } catch (e) {}
    } else {
      const mediaFileList = fileList
        ? fileList.map((file) => {
            return {
              name: file.name ?? "file",
              url: file.url,
              size: file.size ?? -1,
              contentType: file.contentType ?? "dummy/dummy",
              thumbnailUrl: file.thumbnailUrl,
            };
          })
        : [];
      let integId = appProperties.randomIntegId;
      const tempData = {
        content: content[selectedModule],
        friendlyName: friendlyName,
        integId: integId,
        moduleName: selectedModule,
        visibility: visibility,
        mediaFileList: mediaFileList,
      };
      if (friendlyName?.trim() === "") {
        setInvalidName(true);
      } else if (!selectedModule) {
        setInvalidField(true);
      } else if ((!content[selectedModule] || content[selectedModule]?.trim() === "") && (!mediaFileList || mediaFileList?.length < 1)) {
        setInvalidContent(true);
      } else if (friendlyName !== "" && (content[selectedModule] !== "" || (mediaFileList && mediaFileList?.length > 0))) {
        if (templateId !== "") {
          setLoading(true);
          updateTemplate(appProperties, tempData, templateId).then((response) => {
            if (response?.success === true) {
              setCallSavedTempTable(true);
              handleClose();
              setFileList([]);
              successNotifyWithDescription("Edit Template", "Template updated! Your message is refreshed.");
            }
            setLoading(false);
          });
        } else {
          setLoading(true);
          addTemplate(appProperties, tempData).then((response) => {
            if (response?.success === true) {
              handleAddTemplateSuccessResponse();
            } else if (response?.license?.code === "FEATURE_LIMIT_EXCEEDED") {
              handleAddTemplateFailureResponse(response);
            }
          });
        }
      }
    }
  };

  const handleChange = (value) => {
    setVisibility(value);
  };

  return (
    <>
      <Drawer
        className="templateDrawer d-flex flex-column"
        title={tempModal === "AddTemp" ? "Add Template" : "Edit Template"}
        open={showAddTemplateForm}
        onClose={handleClose}
        placement="right"
        closeIcon={<div className="actionIconsSprite modalCloseIcon" />}
        width={"33rem"}
        height={"100rem"}
        footer={
          <div className=" templatesAddButton ">
            <Button className="templateAdd" type="" size="large" onClick={handleTemplate} loading={loading}>
              {tempModal === "AddTemp" ? "Add" : "Update"}
            </Button>
          </div>
        }
      >
        <TemplateTypeCheckBoxes setTemplateType={setTemplateType} templateType={templateType} tempModal={tempModal} />

        {templateType === "whatsapp" ? (
          <AddWhatsappTemplateDrawer
            add={tempModal === "AddTemp"}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            addWhatsappTemplateForm={addWhatsappTemplateForm}
            setCurrentIntegration={setCurrentIntegration}
            friendlyName={friendlyName}
          />
        ) : (
          <div className="mt-3">
            <AddSMSTemplateDrawer
              setContent={setContent}
              setInvalidName={setInvalidName}
              searchFields={searchFields}
              filteredFields={filteredFields}
              handleSearchField={handleSearchField}
              setFriendlyName={setFriendlyName}
              disabledSelect={disabledSelect}
              invalidField={invalidField}
              friendlyName={friendlyName}
              invalidName={invalidName}
              selectedModule={selectedModule}
              handleChangeModule={handleChangeModule}
              module={module}
              fieldLoading={fieldLoading}
              handleSelectFields={handleSelectFields}
              setInvalidContent={setInvalidContent}
              handleContentChange={handleContentChange}
              content={content}
              handleTextAreaClick={handleTextAreaClick}
              fileList={fileList}
              setFileList={setFileList}
              invalidContent={invalidContent}
              friendlyNameRef={friendlyNameRef}
              visibility={visibility}
              handleChange={handleChange}
            />
          </div>
        )}
      </Drawer>
    </>
  );
});

const handleOnChange = (value, setValue) => {
  setValue(value[0]);
};

const WorkFlow = React.memo((props) => {
  const { setIsWfDrawerOpen, isWfDrawerOpen, appProperties, templateId } = props;

  const [drawerOpen, setDrawerOpen] = useState(isWfDrawerOpen);
  const [iconState, setIconState] = useState({});
  const [data, setData] = useState([]);
  const [helpLink, setHelpLink] = useState("");
  const { fetchWebhookNotifications } = useWorkFlow();
  console.log(props, "props for workFlow");
  const handleClose = () => {
    setDrawerOpen(false);
    setIsWfDrawerOpen(false);
  };
  useEffect(() => {
    if (appProperties?.serviceInstalled) {
      console.log(props, "props for workFlow");
      fetchWebhookNotifications(appProperties, templateId).then((response) => {
        console.log(response, "workflow response", response?.data?.data ?? []);
        const responseData = response?.data?.data;
        console.log(response, "workflow response", responseData);
        const updatedData = responseData.map((obj) => {
          const borderBox = obj.copyAction
            ? {
              border: "1px solid #d0d0d0",
              height: "2.5rem",
              whiteSpace: "noWrap",
              overflowX: "auto",
              maxWidth: "30rem",
              borderRadius: 8,
            }
            : { fontStyle: "italic" };
          return {
            ...obj,
            name: <div style={{ fontWeight: "600", maxWidth: "13rem" }}>{obj.name}</div>,
            value: <div style={{ ...borderBox, padding: "6px 2px 0px 2px" }}>{obj.value}</div>,
          };
        });
        setData(updatedData);
        const helpLinks = response?.data?.helpDocumentLink;
        setHelpLink(helpLinks);
      });
    }
  }, []);

  const handleWorkFlowData = (record) => {
    navigator.clipboard
      .writeText(record.value.props.children)
      .then(() => {
        setIconState((prevState) => ({
          ...prevState,
          [record.id]: true,
        }));
        setTimeout(() => {
          setIconState((prevState) => ({
            ...prevState,
            [record.id]: false,
          }));
        }, 500);
      })

      .catch((error) => {
        console.error("copy failed ", error);
      });
  };
  const handleHelpLink = () => {
    window.open(helpLink);
  };
  const columns = [
    { dataIndex: "name", key: "name" },
    {
      dataIndex: "value",
      key: "value",
    },
    {
      dataIndex: "copyAction",
      key: "copyAction",
      render: (copyAction, record) => (
        <div className="d-grid">
          {iconState[record.id] ? <div className="copiedWorkFlowData">copied!</div> : null}
          {copyAction ? <div className="tempIcons copyWorkFlowData-icon" onClick={() => handleWorkFlowData(record)}></div> : null}
        </div>
      ),
    },
  ];

  const installedApps = appProperties.installedApps;
  const installedItems = installedApps.map((app, index) => ({
    key: app.integProps.integId,
    label: app.right.service_display_name, // Use service_display_name as the label
  }));

  const onChangeRightService = (key) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.name.props.children === "URL to Notify"
          ? {
            ...item,
            value: (
              <div
                style={{
                  border: "1px solid #d0d0d0",
                  height: "2.5rem",
                  whiteSpace: "nowrap",
                  overflowX: "auto",
                  maxWidth: "30rem",
                  borderRadius: 8,
                  padding: "6px 2px 0px 2px",
                }}
              >
                {`${APP_URL}/api/v1/omessage/${key}/workflowSend`}
              </div>
            ),
          }
          : item
      )
    );
  };

  return (
    <>
      <Drawer
        className=" workFlowDrawer"
        title={
          <div className="d-flex align-item-center justify-content-center p-2">
            WorkFlow
            <Button
              style={{
                color: "#109cf1",
                borderColor: "#109cf1",
                marginLeft: ".5rem",
              }}
              icon={<ExportOutlined />}
              ghost
              onClick={handleHelpLink}
            >
              help
            </Button>
          </div>
        }
        open={drawerOpen}
        closeIcon={<div className="actionIconsSprite modalCloseIcon" />}
        onClose={handleClose}
        placement="left"
        width={"50rem"}
        height={"100rem"}
      >
        <div>
          Configure the Webhook settings to enable seamless communication, ensuring timely and automated information delivery to your system
          through Webhook messages.
        </div>
        <Tabs className="ms-5" items={installedItems} onChange={onChangeRightService} />
        <div className="d-flex justify-content-center">
          <Table className="workFlowTemp" pagination={false} columns={columns} dataSource={data} />
        </div>
      </Drawer>
    </>
  );
});

const TemplateTypeCheckBoxes = React.memo(({ setTemplateType, templateType, tempModal }) => {
  const handleOnChangeForTemplateType = (values) => {
    handleOnChange(values, setTemplateType);
  };

  return (
    <div className="w-100">
      <CheckboxGroup
        items={[
          { label: "SMS/MMS", value: "application" },
          { label: WHATSAPP_BETA_DISPLAY_NAME, value: "whatsapp" },
        ]}
        disabled={tempModal !== "AddTemp"}
        value={templateType}
        handleOnChange={handleOnChangeForTemplateType}
        indexOfDefaultSelectedValue={0}
        actAsRadioButton={true}
        groupLabel={"Type"}
      />
    </div>
  );
});
function handleAddTemplateFailureResponse(response) {
  let temLimit = response?.license?.description;
  failureNotificationWithLink(temLimit, "mail to", `mailto:${ASSIST_MAIL}`);
}
