import React from "react";
import Link from "../../../components/custom/info/Link";

//####################### STEPS #####################//
export const getSmsSteps = () => [
  {
    title: (
      <>
        Authorize SMS channel.
        <Link externalLink baseUrl={"https://youtu.be/FgJ_OhCa0UA"}>
          Learn more
        </Link>
      </>
    ),
  },
  {
    title: (
      <>
        Enable SMS-enabled numbers.
        <Link externalLink baseUrl={"https://help.oapps.xyz/portal/en/kb/articles/hellosend-whatsapp-integration"}>
          Learn more
        </Link>
      </>
    ),
  },
  {
    title: <>Send 1-1 messages, campaigns, and automations.</>,
  },
];

export const getVoiceSteps = (callFlowEndPoint, params, setCallProperties, setShowGotoLeftService) => [
  {
    title: (
      <>
        <b>Authorize Twilio: </b>Log in to your Twilio account and activate voice-enabled numbers.
      </>
    ),
  },
  {
    title: (
      <>
        <b> Call Flow:</b> Enabled numbers are automatically mapped to the Default Call Flow. Customize settings such as{" "}
        <b> business hours, welcome message, and busy message. </b>
        <Link externalLink baseUrl={`${callFlowEndPoint}?${params.toString()}`}>
          Verify Call Flow Here.
        </Link>
      </>
    ),
  },
  {
    title: (
      <>
        <b>Teams:</b> The current user and number will be linked to the Default Team by default.{" "}
        <Link externalLink baseUrl={`/voice/teams?${params.toString()}`}>
          Verify Teams here.
        </Link>
      </>
    ),
  },
  {
    title: (
      <>
        All done! Make your first call{" "}
        <button
          style={{ background: "none", border: "none", color: "var(--hs-link-color)", textDecoration: "none", cursor: "pointer" }}
          onClick={() => {
            setCallProperties((prev) => ({
              ...prev,
              isDialPadVisible: true,
            }));
            setShowGotoLeftService(false);
          }}
        >
          Here.
        </button>
      </>
    ),
  },
];

export const getWhatsAppSteps = (WHATSAPP_DISPLAY_NAME, appProperties) => [
  {
    title: (
      <>
        Authorization of {WHATSAPP_DISPLAY_NAME} Service -
        <Link externalLink baseUrl={"https://youtu.be/FgJ_OhCa0UA"}>
          How to Authorize
        </Link>
      </>
    ),
  },
  {
    title: (
      <>
        Configuration of {WHATSAPP_DISPLAY_NAME}-Enabled Number -
        <Link externalLink baseUrl={"https://help.oapps.xyz/portal/en/kb/articles/hellosend-whatsapp-integration"}>
          Setup Guide
        </Link>
      </>
    ),
  },
  {
    title: (
      <>
        Importing of {WHATSAPP_DISPLAY_NAME} Templates -
        <Link appProperties={appProperties} baseUrl={"/settings/templates?openTemplate=true&ttype=wp&"}>
          Add Templates
        </Link>
      </>
    ),
  },
];
