import ZohoCRM from "./ZohoCRMController";
import { ZOHO_CRM_API_VARIABLE_NAME } from "./../constants/AppConstants";

const ZohoCRMEmbedded = {
  init: function (setCallProperties) {
    return new Promise((resolve, reject) => {
      window?.ZOHO?.embeddedApp?.on("Dial", function (data) {
        // You can add additional logic here, such as logging or triggering other actions
        let callToNumber = data?.Code + data?.Number;
        let associatedObjectId = data?.EntityID;
        let associatesObjectType = data?.EntityType;
        if (callToNumber) {
          if (setCallProperties) {
            setCallProperties((prev) => {
              return {
                ...prev,
                callerNumber: callToNumber,
                callRecordId: associatedObjectId,
                callRecordModule: associatesObjectType,
                callType: "outbound",
              };
            });
          }
          ZohoCRMEmbedded.showCallNotification(data, "outbound");
        }
      });

      window?.ZOHO?.embeddedApp?.on("DialerActive", function () {
        console.log("tukzer >>>>>> Dialer Activated", setCallProperties);
      });
      window?.ZOHO?.embeddedApp.on("PageLoad", function (data) {
        console.log(
          "createRecordPage tukzer_unit >>>>>> :::::::::::: handleFetchTemplates dinku embeddedApp :::::::::: 33333 ----",
          data?.EntityId
        );
        ZohoCRM.selectedEntityModuleFromZohoCRM = data?.Entity;
        if (data?.module) {
          ZohoCRM.selectedEntityModuleFromZohoCRM = data?.module;
        }
        if (setCallProperties) {
          setCallProperties((prev) => {
            return { ...prev, loadedSDK: data };
          });
        }

        ZohoCRM.selectedEntityIdsFromZohoCRM = data?.EntityId;
        console.log(
          "createRecordPage tukzer_unit >>>>>> :::::::::::: handleFetchTemplates dinku embeddedApp :::::::::: 33333 ----",
          data?.EntityId
        );
        if (data?.EntityId) {
          ZohoCRM.serviceInitialized = true;
        }
        ZohoCRM.configData = data?.configdata?.parameters;
        resolve(data);
      });

      window?.ZOHO?.embeddedApp?.init().then(function () {
        console.log("tukzer_unit >>>>>> :::::::::::: EmbeddedApp inited successfully >> ", setCallProperties, window.location.pathname);
        if (setCallProperties) {
          setCallProperties((prev) => {
            return { ...prev, serviceInitialized: true };
          });
        }
      });
    });
  },
  showCallNotification: function (pipedriveSDK, callState) {
    return new Promise((resolve, reject) => {
      if (callState != "outbound") {
        window?.ZOHO?.CRM?.UI?.Dialer.notify();
      }
      window?.ZOHO?.CRM?.UI?.Dialer.maximize();
    });
  },
  hideCallNotification: function (pipedriveSDK) {
    return new Promise((resolve, reject) => {
      window?.ZOHO?.CRM?.UI?.Dialer.minimize();
    });
  },
  setWebhook: function (webhookUrl, webhookId, appProperties, integId) {
    return new Promise((resolve, reject) => {
      console.log("dtiou >>> inside >> webhookUrl", webhookUrl);
      console.log("dtiou >>> inside >> webhookId", webhookId);

      console.log("dtiou >>> inside >> integId", integId);
      var parameters = {
        selectedId: "${!" + appProperties.module + ".Id}",
        module: appProperties.module,
        eventId: webhookId,
        integId: integId,
      };
      var customActionValueToBeStored = {
        parameters: JSON.stringify(parameters),
      };

      console.log("dtiou >>> inside >> customActionValueToBeStored", customActionValueToBeStored);
      customActionValueToBeStored.url = webhookUrl;

      console.log("dtiou customActionValueToBeStored >>>>>>>>>>", JSON.stringify(customActionValueToBeStored));
      window.ZOHO.CRM.ACTION.setConfig(customActionValueToBeStored);
    });
  },
  getWebhook: function () {
    return new Promise((resolve, reject) => {
      console.log(":::::::::::: handleFetchTemplates dtiou ZohoCRM.configData :::::::::: 33333 ----", ZohoCRM.configData);
      resolve(ZohoCRM.configData);
    });
  },
  getUserData: function (isUserHashNeeded) {
    return new Promise((resolve, reject) => {
      window.ZOHO.CRM.CONFIG.getCurrentUser()
        .then(function (data) {
          window.ZOHO.CRM.CONFIG.getOrgInfo()
            .then(function (dataOrg) {
              var companyId = dataOrg.org[0].zgid;
              var emailId = data.users[0].email;
              var userId = data.users[0].zuid;
              var companyOrgId = companyId;

              var userData = {
                companyId: companyId,
                userId: userId,
                companyOrgId: companyOrgId,
                emailId: emailId,
              };
              if (isUserHashNeeded) {
                ZohoCRMEmbedded.getApplicationToken(userId).then(function (userHash) {
                  if (userHash) {
                    userData["userHash"] = userHash;
                  } else {
                    userData["userHash"] = "-";
                  }
                  resolve(userData);
                });
              } else {
                resolve(userData);
              }
            })
            .catch(function (err) {
              reject(err);
            });
        })
        .catch(function (err) {
          console.log("handleFetchTemplates getUserData catch >>>>>>>>>>", err);
        });
    }).catch(function (err) {
      console.log("handleFetchTemplates whole catch >>>>>>>>>>", err);
    });
  },
  getFilterObjectForShrinkView: function () {
    return new Promise((resolve, reject) => {
      resolve({});
    });
  },
  saveApplicationToken: function (hashToBeSaved) {
    return new Promise((resolve, reject) => {
      var customVariableApiName = ZOHO_CRM_API_VARIABLE_NAME + "hash";
      ZohoCRMEmbedded.get(customVariableApiName)
        .then(function (userHashObjectStoredFromZohoCRM) {
          window.ZOHO.CRM.CONFIG.getCurrentUser()
            .then(function (data) {
              var userId = data.users[0].zuid;
              let promiseArray = [];
              var userHashJson = {};
              if (typeof userHashObjectStoredFromZohoCRM == "string") {
                try {
                  userHashJson = JSON.parse(userHashObjectStoredFromZohoCRM);
                } catch (e) {
                  console.log(e);
                }
              }

              if (userHashJson === undefined || userHashJson === "") {
                userHashJson = {};
              }
              userHashJson[userId] = hashToBeSaved;
              var customVariableJson = {
                apiname: customVariableApiName,
                value: userHashJson,
              };

              promiseArray.push(saveCRMData(customVariableJson));
              Promise.all(promiseArray).then(function () {
                resolve();
              });
            })
            .catch(function (err) {
              reject(err);
            });
        })
        .catch(function (err) {
          reject(err);
        });
    });
  },
  get: function (key) {
    return new Promise((resolve, reject) => {
      window.ZOHO.CRM.API.getOrgVariable(key)
        .then(function (response) {
          if (typeof response == "string") {
            response = JSON.parse(response);
          }
          var successResp = response.Success;
          if (typeof successResp == "string") {
            successResp = JSON.parse(successResp);
          }

          var contentObj = successResp?.Content;
          if (contentObj) {
            resolve(contentObj);
          } else {
            resolve("");
          }
        })
        .catch(function (err) {
          reject(err);
        });
    });
  },
  getApplicationToken: function (userId) {
    return new Promise((resolve, reject) => {
      var customVariableApiName = ZOHO_CRM_API_VARIABLE_NAME + "hash";
      ZohoCRMEmbedded.get(customVariableApiName)
        .then(function (userHashObjectStoredFromZohoCRM) {
          if (userHashObjectStoredFromZohoCRM) {
            var userHashJson = {};
            if (typeof userHashObjectStoredFromZohoCRM == "string") {
              try {
                userHashJson = JSON.parse(userHashObjectStoredFromZohoCRM);
              } catch (e) {
                console.log(e);
              }
            }

            if (userHashJson === undefined || userHashJson === "") {
              userHashJson = {};
            }
            if (userId in userHashJson) {
              var hash = userHashJson[userId];
              resolve(hash);
            } else {
              resolve("");
            }
          } else {
            resolve("");
          }
        })
        .catch(function (err) {
          console.log("h1s-token-zoho-crm :: yet-to-save :: getApplicationToken ::  final catch  else 2ee>>>>>", err);
          resolve("");
        });
    });
  },
  openRecord: function (sdk, id, module) {
    return new Promise((resolve, reject) => {
      console.log("opened successfully >> module >>> " + module);
      console.log("opened successfully >> RecordID >>> " + id);
      window.ZOHO.CRM.UI.Record.open({ Entity: module, RecordID: id }).then(function (data) {
        console.log("opened successfully >>>>> " + data);
      });
    });
  },
  // createRecord: function (sdk, module, openCreateLeadOrContactForm, setOpenCreateLeadOrContactForm) {
  //   return new Promise((resolve, reject) => {
  //     console.log("createRecordPage s uccessfully >> module >>> " + module);
  //     var entityModule = "";
  //     if (module === "lead") {
  //       entityModule = "Leads";
  //     } else if (module === "contact") {
  //       entityModule = "Contacts";
  //     }

  //     console.log("createRecordPage successfully >> entityModule >>> " + entityModule);

  //     window.ZOHO.CRM.UI.Record.create({ Entity: entityModule }).then(function (data) {
  //       console.log("createRecordPage successfully >>>>> " + data);
  //       window.ZOHO.CRM.UI.Record.populate({ Description: "Populating test data", Phone: "hellow-dine" }).then(function (data) {
  //         console.log("createRecordPage populateRecord successfully >> data >>> " + data);
  //         resolve(data);
  //       });
  //     });
  //   });
  // },
  populateRecord: function (sdk, id, module, phone) {
    return new Promise((resolve, reject) => {
      console.log("createRecordPage populateRecord successfully >> module >>> " + module);
      console.log("createRecordPage populateRecord successfully >> phone >>> " + phone);
    });
  },
  getRecordById: function (sdk, id, module) {
    return new Promise((resolve, reject) => {
      window.ZOHO.CRM.API.getRecord({
        Entity: module,
        RecordID: id,
      }).then(function (record) {
        console.log("ZCRM >>>>>>>>> getRecordById >>>>>>>>>>> ", record);
        resolve(record?.data?.[0]);
      });
    });
  },
};

function saveCRMData(parameterMap) {
  return new Promise((resolve, reject) => {
    window.ZOHO.CRM.CONNECTOR.invokeAPI("crm.set", parameterMap)
      .then(function (data) {
        resolve(data);
      })
      .catch(function (err) {
        console.log("hs-token-zoho-crm :: saveCRMData >>>>>> ERRROR>>>>>>> saved_after_err", err);
      });
  });
}

export default ZohoCRMEmbedded;
