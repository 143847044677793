import React, { useContext } from "react";
import useHttp from "../../hooks/useHttp";
import { AppContext } from "../../context/AppContext";

export const useVoiceAgentStatus = () => {
  const { fetchData } = useHttp();
  const [appProperties] = useContext(AppContext);
  const voiceIntegrations = appProperties?.installedApps?.filter((integration) => integration.right.serviceType === "VOICE");
  const voiceIntegId = voiceIntegrations?.[0]?.integProps?.integId;
  const getCurrentVoiceAgentStatus = () => {
    return new Promise((resolve, reject) => {
      if (voiceIntegId) {
        fetchData(`agent/status`, "GET", null, appProperties).then((response) => {
          if (response) {
            const resData = JSON.parse(response);
            resolve(resData?.data);
          }
        });
      }
    });
  };
  return { getCurrentVoiceAgentStatus };
};
