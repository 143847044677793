import { Input } from 'antd'
import React from 'react'

const NodesInput = ({ value, type, onChange }) => {
    return (
        <>
            <Input type={type} className="mt-1  ringIntervalInput" onChange={onChange}  value={value}/>
        </>
    )
}

export default NodesInput