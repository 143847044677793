import { Row } from "antd";
import React, { useEffect } from "react";
import "../assets/css/voiceCallLog.css";

const IncomingPopUp = (props) => {
  let {
    customerNumber,
    hangUpCall,
    lastName,
    callerImageComponent,
    answerCall,
    searchValue,
    rejectCall,
    incomingCallTo,
    incomingCall,
    skeletonImage,
    skeletonField,
    customerInfoDetails,
  } = props;

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  return (
    <div className="overflow-auto h-100 pt-2">
      <div className="d-flex flex-column align-items-center hs-h-60">
        <div className="d-flex justify-content-center align-items-center w-75 hs-h-15 incoming-popup-configured-number">
          <div className="d-flex justify-content-center align-items-center">
            <div class="callActionIcons from-number-call-icon"></div>
            <div className="text-white hs-fs-14 hs-fw-500 w-100">{incomingCallTo}</div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center hs-h-45 w-100 call-ringing-screen">
          {lastName || customerNumber || searchValue ? <>{callerImageComponent}</> : <> {skeletonImage} </>}
        </div>

        <div class="d-flex flex-column hs-h-45 w-100 justify-content-evenly align-items-center">
          {/* {lastName ? <div className="text-white  hs-fs-18 hs-fw-600 cursor-default ellipsis hs-h-20">{lastName}</div> : <> {skeletonField} </>} */}
          <span className="text-white hs-fs-14 hs-fw-500 cursor-default ellipsis" style={{ maxWidth: 150 }}>
            {lastName}
          </span>
          <div className="text-white hs-fs-16 hs-fw-400 cursor-default ellipsis hs-h-20">
            {customerNumber ? customerNumber : searchValue ? searchValue : null}
          </div>
          {customerInfoDetails}
        </div>
        <div className="d-flex justify-content-center align-items-center hs-color-grey fs-6 hs-fw-500 hs-h-10">
          {incomingCall ? `incoming` : `dialing`}
        </div>
      </div>
      <div className="d-flex flex-row justify-content-around align-items-center  hs-h-20">
        {incomingCall ? (
          <>
            <div className="callIcon" onClick={answerCall} />
            <div className="callEndIcon" onClick={rejectCall} />
          </>
        ) : (
          <div className="callEndIcon" onClick={() => hangUpCall} />
        )}
      </div>
    </div>
  );
};

export default IncomingPopUp;
