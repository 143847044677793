import { useContext } from "react";
import useHttp from "../../hooks/useHttp";
import { AppContext } from "../../context/AppContext";

export const useVoiceTeams = () => {
  const { fetchData } = useHttp();
  const [appProperties] = useContext(AppContext);
  const voiceIntegrations = appProperties?.installedApps?.filter((integration) => integration.right.serviceType === "VOICE");
  const voiceIntegId = voiceIntegrations?.[0]?.integProps?.integId;
  const getVoiceTeams = () => {
    return new Promise((resolve, reject) => {
      if (appProperties !== undefined) {
        fetchData(`${voiceIntegId}/teams`, "GET", null, appProperties).then((response) => {
          if (response) {
            const responseData = JSON.parse(response);
            const teamsData = responseData?.data;
            resolve(teamsData);
          }
        });
      }
    });
  };

  const updateAgentsInMultipleTeam = (payLoad) => {
    return new Promise((resolve, reject) => {
      fetchData(`agents/teams`, "PUT", payLoad, appProperties).then((response) => {
        if (response) {
          const resData = JSON.parse(response);
          resolve(resData?.data);
        }
      });
    });
  };

  const deleteAgentsFromMultipleTeam = (payLoad) => {
    return new Promise((resolve, reject) => {
      fetchData(`agents/teams/remove`, "DELETE", payLoad, appProperties).then((response) => {
        if (response) {
          const resData = JSON.parse(response);
          resolve(resData?.data);
        }
      });
    });
  };

  const deleteTeamWithAgents = (teamId) => {
    return new Promise((resolve, reject) => {
      fetchData(`teams/${teamId}/agents`, "DELETE", null, appProperties).then((response) => {
        if (response) {
          const resData = JSON.parse(response);
          resolve(resData?.data);
        }
      });
    });
  };

  const updateTeam = (teamId, payload) => {
    return new Promise((resolve, reject) => {
      fetchData(`teams/${teamId}/agents`, "PUT", payload, appProperties).then((response) => {
        if (response) {
          const resData = JSON.parse(response);
          resolve(resData?.data);
        }
      });
    });
  };

  const createTeam = (payload) => {
    return new Promise((resolve, reject) => {
      fetchData("team/agents", "POST", payload, appProperties).then((response) => {
        if (response) {
          const resData = JSON.parse(response);
          resolve(resData?.data);
        }
      });
    });
  };
  return { getVoiceTeams, updateAgentsInMultipleTeam, deleteAgentsFromMultipleTeam, deleteTeamWithAgents, updateTeam, createTeam };
};
