import { List, Collapse, Divider, message, Row, Col } from "antd";
import React, { useContext, useEffect, useState } from "react";
import "../../assets/css/channelsConfig.css";
import { useNavigate, useLocation } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { urlParams } from "../../constants/AppConstants";

const { Panel } = Collapse;

const ChannelsConfiguration = () => {
  const navigate = useNavigate();
  const [appProperties] = useContext(AppContext);
  const location = useLocation();
  const [smsConnected, setSmsConnected] = useState(false);
  const [callConnected, setCallConnected] = useState(false);
  const [whatsAppConnected, setWhatsAppConnected] = useState(false);

  const path = location.pathname;
  useEffect(() => {
    appProperties?.installedApps?.forEach((app) => {
      const serviceType = app.right.serviceType;
      console.log("serviceType>>", serviceType);
      if (serviceType === "VOICE") {
        setCallConnected(true);
      }
      if (serviceType === "SMS") {
        setSmsConnected(true);
      }
      if (serviceType === "WHATSAPP") {
        setWhatsAppConnected(true);
      }
    });
  }, [appProperties]);

  const FeatureListTitle = ({ isConnected, title }) => {
    return (
      <div className="d-flex flex-row align-items-center pt-2 pb-1">
        {" "}
        <div className="onboarding-list-title pe-2">{title}</div>
        {isConnected && (
          <div className="hs-bg-light-green1 d-flex flex-row hs-border-1-25-rem p-1 px-2">
            <div className="ob-service-connected-tick-icon actionIconsSprite" />
            <div className="hs-color-light-green2 hs-fw-500">connected</div>
          </div>
        )}
      </div>
    );
  };

  const featuresList = [
    {
      key: "1",
      title: <FeatureListTitle isConnected={smsConnected} title={"SMS "} />,
      description: "Send bulk SMS campaigns and automated messages to your customers",
      icon: <div className="onboarding-sms-icon actionIconsSprite " />,
      isAccordion: true,
    },
    {
      key: "2",
      title: <FeatureListTitle isConnected={callConnected} title={"Call "} />,
      description: "Make automated calls and manage phone campaigns",
      icon: (
        <div className="d-flex align-items-center">
          <div className="onboarding-call-icon callActionIcons" />
        </div>
      ),
      onClick: () => {
        navigate("/voice/number/twilio_voice" + location.search);
      },
    },
    {
      key: "3",
      title: <FeatureListTitle isConnected={whatsAppConnected} title={"Whatsapp "} />,
      description: "Engage customers through WhatsApp business messaging",
      icon: <div className="onboarding-whatsapp-icon actionIconsSprite mt-2" />,
      onClick: () => {
        navigate("/settings/channels/whatsapp" + location.search);
      },
    },
  ];

  const smsProviders = [
    {
      name: "Twilio",
      icon: "smallSize-twilio-icon",
      onClick: () => {
        navigate("/settings/twilio" + location.search);
      },
    },
    {
      name: "Ringcentral",
      icon: "smallSize-ringcentral-icon",
      onClick: () => {
        navigate("/channels/textsms/ringcentral" + location.search);
      },
    },
    // {
    //     name: "Microsoft Teams",
    //     icon: "smallSize-microsoft-teams-icon",
    //     onClick: () => message.success("MicroSoft Teams")
    // },
    {
      name: (
        <div className="hs-fw-500 ">
          Don't have a number? Get one from HelloSend.{" "}
          <a href={"/channels/textsms/helloSend?" + urlParams} rel="noreferrer" target="_blank" className="custom-anchor">
            Buy now.
          </a>
        </div>
      ),
      icon: "",
      onClick: () => {
        navigate("/channels/textsms/helloSend" + location.search);
      },
    },
  ];

  return (
    <div className="d-flex align-items-center flex-column ">
      {!path.includes("/settings") ? (
        <>
          <div className="onBoardingTitle mt-5">Channel configuration</div>
          <div className="onboarding-description mt-4 mb-5">Configure your marketing campaign settings</div>
        </>
      ) : (
        <>
          <div className="onBoardingTitle mt-5">Marketing Channels</div>
          <div className="onboarding-description mt-4 mb-5">Configure your marketing campaign settings</div>
        </>
      )}
      <List
        itemLayout="horizontal"
        className="rounded-3 onboarding-list border-default"
        dataSource={featuresList}
        style={{ width: "", backgroundColor: "#ffffff" }}
        renderItem={(item) =>
          item.isAccordion ? (
            <Collapse
              ghost
              style={{ width: "100%" }}
              expandIconPosition="end"
              defaultActiveKey={["1"]}
              expandIcon={({ isActive }) =>
                isActive ? (
                  <div className="callActionIcons close-arrow me-lg-3 mt-lg-1" />
                ) : (
                  <div className="mt-lg-5 me-lg-4 callActionIcons expand-arrow" />
                )
              }
              className="pt-2 pb-2 onboarding-collapse"
            >
              <Panel
                onClick={(e) => e.stopPropagation()}
                header={
                  <Row className="d-flex align-items-center w-100">
                    <Col span={1}>{item.icon}</Col>
                    <Col span={23}>
                      <div className="d-flex flex-column ms-3 mb-2">
                        <span>{item.title}</span>
                        <span className="onboardingDescription">{item.description}</span>
                      </div>
                    </Col>
                  </Row>
                }
                key="1"
              >
                {smsProviders.map((provider, idx) => (
                  <div className="d-flex cursor-pointer ms-5" onClick={provider.onClick}>
                    {provider.icon && <div className={`${provider.icon ? "staticIconsSprite" : ""} ${provider.icon}`} />}

                    <div className="mb-4 ms-lg-2 hs-fw-600">{provider.name}</div>
                  </div>
                ))}
              </Panel>
              <Divider className="mb-0" />
            </Collapse>
          ) : (
            <List.Item className="cursor-pointer" onClick={item?.onClick}>
              <List.Item.Meta
                className="ps-3 pb-2 d-flex align-items-center "
                avatar={item.icon}
                title={item.title}
                description={item.description}
              />
              <div className="expand-arrow callActionIcons expandArrowPostion"> </div>
            </List.Item>
          )
        }
      />
    </div>
  );
};

export default ChannelsConfiguration;
