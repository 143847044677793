import { v4 as uuidv4 } from "uuid";

export const initialNodes = [
  { id: "1", type: "phone_number", position: { x: 249, y: 10 }, nodeId: uuidv4(), data: {}, classification: "hold" },
  { id: "2", type: "business_hour", position: { x: 249, y: 82 }, nodeId: uuidv4(), classification: "bh" },
  { id: "3", type: "handle_svg", position: { x: 312, y: 149 } },
  { id: "4", type: "open_node", position: { x: 138, y: 181 } },
  { id: "5", type: "close_node", position: { x: 419, y: 182 } },
  { id: "7", type: "welcome_message", position: { x: 109, y: 238 }, nodeId: uuidv4(), classification: "welcome" },
  { id: "8", type: "call_recording", position: { x: 109, y: 320 }, nodeId: uuidv4(), classification: "record" },
  { id: "9", type: "ring_team", position: { x: 108, y: 408 }, nodeId: uuidv4(), data: {}, classification: "teams" },
  { id: "11", type: "hang_up", position: { x: 443, y: 250 } },
  {
    id: "14",
    type: "busy",
    position: { x: -44, y: 569 },
    nodeId: uuidv4(),
    classification: "busy",
  },
  {
    id: "15",
    type: "unavailable",
    position: { x: 255, y: 570 },
    nodeId: uuidv4(),
    classification: "unavailable",
  },
  { id: "16", type: "handle_svg", position: { x: 170, y: 535 }, nodeId: uuidv4() },
  { id: "17", type: "no_answer", position: { x: 133, y: 486 }, nodeId: uuidv4() },
  { id: "18", type: "hang_up", position: { x: 308, y: 633 }, nodeId: uuidv4() },
  { id: "19", type: "hang_up", position: { x: 8, y: 634 }, nodeId: uuidv4() },
];

export const initialEdges = [
  { id: "e1-2", source: "1", target: "2", type: "CustomEdge" },
  { id: "e2-4", source: "2", target: "4", type: "CustomEdge" },
  { id: "e2-5", source: "2", target: "5", type: "CustomEdge" },
  { id: "9-10", source: "8", target: "9", type: "CustomEdge" },
  { id: "e7-8", source: "7", target: "8", type: "CustomEdge" },
  { id: "e4-6", source: "4", target: "7", type: "CustomEdge" },
  { id: "7-11", source: "5", target: "11", type: "CustomEdge" },
  { source: "9", target: "17", id: "reactflow__edge-9-17", type: "CustomEdge" },
  { source: "17", target: "14", id: "reactflow__edge-17-14", type: "CustomEdge" },
  { source: "17", target: "15", id: "reactflow__edge-17-15", type: "CustomEdge" },
  { source: "14", target: "19", id: "reactflow__edge-14-19", type: "CustomEdge" },
  { source: "15", target: "18", id: "reactflow__edge-15-18", type: "CustomEdge" },
];
