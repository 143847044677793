import dayjs from "dayjs";

function checkGivenDateIsAfterCurrentDate(date) {
  return dayjs(date).isAfter(dayjs(), "minutes");
}

function getMomentDate(targetDate) {
  return dayjs(new Date(targetDate));
}

function getHSFormattedDateString(targetDate) {
  return dayjs(targetDate).format("DD MMM, YYYY hh:mm");
}

const getIsoFormattedDateAndTime = (date, time) => {
  const dateAndTimeStringInLocal = dayjs(date).format("YYYY-MM-DD") + "T" + time.format("HH:mm:ss");
  const dateObj = new Date(dateAndTimeStringInLocal);
  return dateObj.toISOString();
};

export { checkGivenDateIsAfterCurrentDate, getHSFormattedDateString, getIsoFormattedDateAndTime, getMomentDate };
