import { ExclamationCircleOutlined, MenuOutlined } from "@ant-design/icons";
import { Alert, Avatar, Button, Col, Divider, Dropdown, Layout, Row, Space, Tag } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { urlParams, AgentStatusColours } from "./../../constants/AppConstants";
import { AppContext } from "./../../context/AppContext";

import { useLocation } from "react-router-dom";
import useHttp from "../../hooks/useHttp.jsx";
import useLicense from "../../hooks/useLicense.jsx";
import AgentStatusSelect from "../../voice/components/call-screens/AgentStatusSelect.jsx";
import { useVoiceContext } from "../../voice/context/VoiceContext.js";
import { getCurrentFeature } from "../../voice/utils/VoiceUtils.js";
import SubAccountsListing from "./../../pages/subAccount/SubAccountsListing.jsx";
const { Header } = Layout;

const titles = {
  channels: "Channels",
  phone: "Add phone",
  templates: "Templates",
  inbox: "Inbox",
  module: "Campaign Modules",
  sendsms: "Send SMS",
  messages: "Bulk SMS",
  campaigns: "Overview",
  campaign: "Campaign",
  chat: "Chat",
  users: "Users",
  workflow: "Automation",
  campaignOverView: "Detailed Overview",
  general: "Admin Settings",
  calllog: "Call Logs",
  callFlow: "Call Flow",
  blockedNumbers: "Blocked Numbers",
  number: (
    <div className="d-flex flex-row align-items-center">
      <div>Twilio Call</div>
      <div className="ps-lg-2 pb-lg-1">
        {" "}
        <Tag bordered={false} color="#EEF2FF" className="hs-border-1-25-rem ">
          <span className="hs-color-blue-1">Beta</span>
        </Tag>
      </div>
    </div>
  ),
};

function Headerbar(props) {
  const { toggleSidebar } = props;
  let topBarNeeded = urlParams.get("topBar");
  let sideBar = urlParams.get("sideBar");

  const location = useLocation();
  if (location.pathname === "/zoho-crm/webhook" || location.pathname === "/voice/dialpad") {
    sideBar = "false";
    topBarNeeded = "false";
  }
  var sideBarFalse = "block";
  if (location.pathname === "/zoho-crm/landing" || location.pathname === "/teams/landing") {
    sideBarFalse = "d-none";
  }
  const [title, setTitle] = useState(props.headerTitle);
  const [appProperties, setAppProperties] = useContext(AppContext);
  const [userLogo, setUserLogo] = useState();
  const [isTopBarNeeded] = useState(topBarNeeded === undefined || topBarNeeded !== "false");
  const [isSideBar, setIsSideBar] = useState(true);
  const [loginAreaMenuItems, setLoginAreaMenuItems] = useState([]);
  const { fetchStripeURL } = useLicense();
  let onBoardingPage = urlParams.get("onBoardingPage");
  const [callProperties] = useVoiceContext();
  const [conflictPresentInUserLicenseCount, setConflictPresentInUserLicenseCount] = useState(false);

  const { fetchData } = useHttp();

  const handleLogout = () => {
    if (appProperties.leftServiceId) {
      fetchData(`logout?leftServiceId=${appProperties?.leftServiceId}`, "DELETE", null, appProperties).then(function (response) {
        response = JSON.parse(response);
        if (response.data.isValid) {
          setAppProperties((prev) => ({ ...prev, authCRM: false }));
          appProperties?.controller?.saveApplicationToken("").then(function () {
            appProperties.setOtpModalState(true);
          });
        }
        // navigate("/teams/landing" + location.search);
      });
    }
  };

  useEffect(() => {
    if (appProperties?.controller?.pricingLink) {
      let loginAreaMenuItemList = [];
      if (appProperties?.licenseObj?.licenseDetails?.licenseStatus) {
        if (appProperties?.licenseHandledBy === "STRIPE") {
          fetchStripeURL(appProperties, menuProps).then(function (stripeLink) {
            if (stripeLink?.url) {
              loginAreaMenuItemList.push({
                label: <SubscriptionLabel href={stripeLink.url} userLogo={userLogo} />,
                key: "Manage_Subscription",
              });
              setLoginAreaMenuItems(loginAreaMenuItemList);
            }
          });
        } else {
          appProperties.controller.pricingLink(appProperties).then(function (resp) {
            loginAreaMenuItemList.push({
              label: <SubscriptionLabel href={resp} userLogo={userLogo} />,
              key: "Manage_Subscription",
            });
            setLoginAreaMenuItems(loginAreaMenuItemList);
          });
        }
      }

      // let logoutItem = {
      //   label: <div onClick={handleLogout}>Logout</div>,
      //   key: "_logout",
      //   icon: <LogoutOutlined />,
      // };
      // loginAreaMenuItemList.push(logoutItem);
      // Info :: above code will be pushed with teams launch
    }
  }, [appProperties?.controller, appProperties?.licenseHandledBy]);

  useEffect(() => {
    if (appProperties?.licenseObj?.conflictPresentInUserLicenseCount) {
      const licensedUserDetails = appProperties?.licenseObj?.allLicensedUserDetails;
      const currentUserObj = licensedUserDetails?.find((user) => user?.remoteUserId === appProperties?.userId);
      const roleId = currentUserObj?.roleId;
      const userRoleKey = appProperties?.rolesBasedObject
        ? Object.entries(appProperties.rolesBasedObject).find(([key, value]) => value === "USER")?.[0]
        : undefined;

      if (roleId && userRoleKey && roleId.toString() !== userRoleKey) {
        setConflictPresentInUserLicenseCount(appProperties?.licenseObj?.conflictPresentInUserLicenseCount === "true");
      }
    }
  }, [appProperties?.licenseObj?.conflictPresentInUserLicenseCount]);

  console.log("loginAreaMenuItemList", loginAreaMenuItems);
  useEffect(() => {
    if (appProperties?.headerTitleName) {
      setTitle(appProperties?.headerTitleName);
    }
  }, [appProperties?.headerTitleName]);

  useEffect(() => {
    if (sideBar === "false") {
      setIsSideBar(false);
    }
  }, []);
  useEffect(() => {
    appProperties.headerTitleName = "";
    setTitle(titles[props.headerTitle] || props.headerTitle);
    let userLogoText = appProperties.userLogo;
    if (userLogoText === "") {
      userLogoText = appProperties.userName.charAt(0).toUpperCase();
    }
    setUserLogo(userLogoText);
  }, [props.headerTitle, appProperties.userLogo, appProperties.userName, location.pathname, appProperties?.controller]);

  if (!isTopBarNeeded) {
    return <></>;
  }
  console.log("Title", title, props.headerTitle);

  const menuProps = {
    items: loginAreaMenuItems,
  };
  return (
    <Header
      className={`allPageContainer ${sideBarFalse}`}
      style={{
        padding: 0,
        height: conflictPresentInUserLicenseCount ? 100 : 60,
        zIndex: 3,
        lineHeight: "64px",
        backgroundColor: sideBar === "false" ? "transparent" : "#fff",
        paddingLeft: "0rem",
      }}
    >
      <Row style={{ overflow: "hidden" }}>
        <Col xs={15} md={11} xl={14} className="d-flex align-items-center">
          <Button
            className="d-md-none px-3"
            type="text"
            icon={<MenuOutlined />}
            onClick={() => {
              toggleSidebar();
            }}
          />
          <div className="ps-2 ps-md-0 w-100">
            <h1
              className="ms-4 m-3"
              style={{
                textTransform: "capitalize",
                color: "#111827",
                fontWeight: 700,
                fontSize: 24,
              }}
            >
              {title}
            </h1>
          </div>
        </Col>

        <Col span={4} className="d-none d-md-block">
          <SubAccountsListing selectComponentWidth={"90%"} selectComponentHeight={40} />
        </Col>

        <Col xs={6} md={7} xl={4} className="d-flex align-items-center ">
          {!onBoardingPage ? (
            <Button
              data-sleek
              data-badge-changelog
              className="announcementHover d-flex align-items-center justify-content-center border-0 shadow-0"
              style={{ boxShadow: "none" }}
            >
              <div className="actionIconsSprite announcement"></div>
              <span className="ms-4 d-none d-md-block"> What's New</span>
            </Button>
          ) : null}
        </Col>

        <Col xs={3} md={2}>
          <Space className="d-flex justify-content-end h-100 w-100">
            {isSideBar ? (
              <>
                <Dropdown menu={menuProps} trigger={["click"]}>
                  <Button className="border-none boxShadowRemove p-0 d-flex justify-content-center h-100 w-100">
                    <Space className="profileAvatarBg h-100 w-100">
                      <Avatar
                        style={{
                          backgroundColor: "#d5fce3",
                          color: "black",
                          verticalAlign: "middle",
                          border: "1px solid #d5fce3",
                        }}
                        size="large"
                        className="manageSubscription"
                        src={userLogo}
                        border="light"
                      >
                        {userLogo}
                      </Avatar>
                      <div
                        style={{
                          position: "absolute",
                          left: "39%",
                          transform: "translateX(-50%)",
                          width: "13px",
                          height: "13px",
                          borderRadius: "50%",
                          backgroundColor: AgentStatusColours[callProperties?.agentStatusValue],
                          border: "2px solid white",
                          top: "63%",
                        }}
                      ></div>
                      {menuProps?.items?.length > 0 ? <div className="actionIconsSprite headerDropDownIcon" /> : null}
                    </Space>
                  </Button>
                </Dropdown>
              </>
            ) : null}
          </Space>
        </Col>
      </Row>
      {conflictPresentInUserLicenseCount ? (
        <Row className="w-100">
          <Col className="w-100">
            <Alert
              message={
                <>
                  <ExclamationCircleOutlined className="pe-2" style={{ fontSize: "1rem", color: "#FF9800" }} />{" "}
                  <span className="hs-fw-500 hs-fs-12" style={{ color: "#FF9800" }}>
                    {" "}
                    Active user count exceeds the purchased limit. subscription will be updated accordingly.
                  </span>
                </>
              }
              type="warning"
              style={{
                borderRadius: 0,
                border: 0,
                background: "#FFF5E3",
              }}
            />
          </Col>
        </Row>
      ) : null}
    </Header>
  );
}

export default Headerbar;

export const SubscriptionLabel = (props) => {
  let { userLogo, href, dataCbType } = props;

  const [isVoiceUser, setIsVoiceUser] = useState(false);
  const [planName, setPlanName] = useState("Message");
  const [appProperties] = useContext(AppContext);
  const currentUserObj = appProperties?.licenseObj?.allLicensedUserDetails?.find((user) => user?.remoteUserId === appProperties?.userId);
  const licensePlanKey = appProperties?.licenseObj?.licenseDetails?.licensePlanKey;
  const roleId = currentUserObj?.roleId?.toString();
  const userRoleKey = Object.entries(appProperties?.rolesBasedObject || {}).find(([_, value]) => value === "USER")?.[0];

  useEffect(() => {
    if (licensePlanKey) {
      if (licensePlanKey === "HS_VOICE" || licensePlanKey === "HS_VOICE_PLUS_MESSAGING") {
        setIsVoiceUser(true);
      }
    }
  }, [licensePlanKey]);

  useEffect(() => {
    if (appProperties?.licenseObj?.licenseDetails?.planName) {
      let planName = getCurrentFeature(appProperties);
      setPlanName(planName);
    }
  }, [appProperties?.licenseObj?.licenseDetails?.planName]);

  return (
    <div className="p-1 pt-2 pb-2 ">
      <div className="d-flex flow-row cursor-default">
        <div className="d-flex align-items-center">
          <Avatar
            style={{
              backgroundColor: "#d5fce3",
              color: "black",
              verticalAlign: "middle",
              border: "1px solid #d5fce3",
              width: "52px",
              height: "54px",
              borderRadius: "50px",
            }}
            size="large"
            className="manageSubscription"
            src={userLogo}
            border="light"
          >
            {userLogo}
          </Avatar>
        </div>
        <div className="d-flex flex-column ms-3">
          <span className="hs-fs-15 hs-fw-600 pb-1">{appProperties?.userName}</span>
          <span className="pb-1">{decodeURIComponent(appProperties?.emailId)}</span>
          <span>User ID:{appProperties?.userId}</span>
        </div>
      </div>
      <Divider />
      {isVoiceUser ? (
        <>
          <div className="d-flex flex-row pt-1 pb-1 justify-content-between px-1">
            <div className="d-flex align-items-center cursor-default">
              <div className="callActionIcons phoneIcon header-call" />
              <span className="hs-fs-14 hs-fw-500">Phone</span>
            </div>

            <div onClick={(e) => e.stopPropagation()}>
              <AgentStatusSelect classNameProps="agentStatusForWhiteBackground" expandIcon={"statusOpenArrow mb-2"} />
            </div>
          </div>
          <Divider />
        </>
      ) : null}

      <div className="pt-1 pb-1 d-flex flex-column px-1">
        <div className="d-flex flex-row justify-content-between align-items-center pb-2 cursor-default">
          <div className="d-flex flex-row align-items-center">
            <div className="actionIconsSprite current-plan-header-icon" />
            <div className="hs-fs-13 hs-fw-500">Current Plan </div>
          </div>
          <div className="ps-4">
            <div className="hs-bg-light-violet hs-border-1-25-rem hs-fs-12 hs-color-violet p-2">{planName}</div>
          </div>
        </div>
        {roleId !== userRoleKey && (
          <a target="_blank" className="custom-anchor-blue hs-fs-13-6-px ps-4" rel="noopener noreferrer" href={href}>
            Manage Subscription
          </a>
        )}
      </div>
    </div>
  );
};
