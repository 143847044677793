import React, { useContext } from "react";
import { Col, Row, Image, Button } from "antd";
import Classes from "../../assets/css/services/leftAuth.module.css";
import HelloSendLogo from "../../assets/images/HelloSendLogo.png";
import HS_bg from "../../assets/images/HS_bg.png";

import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { AppContext } from "./../../context/AppContext";

function ZohoCRMLandingPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [appProperties] = useContext(AppContext);

  let urlParams = new URLSearchParams(window.location.search);
  const serviceOrigin = urlParams.get("serviceOrigin");
  let leftServiceId = urlParams.get("leftServiceId");
  let rightServiceId = urlParams.get("rightServiceId");

  urlParams.delete("topBar");
  useEffect(() => {
    console.log("appProperties?.authCRM >>>>>>>>>>>>>>>>>>>", appProperties?.authCRM);
    if (appProperties?.authCRM) {
      let params = new URLSearchParams(location.search);
      console.log("appProperties?.authCRM  params>>>>>>>>>>>>>>>>>>>", params);
      params.set("sideBar", "true");
      params.set("topBar", "true");
      let updatedSearch = "?" + params.toString();
      if (appProperties?.leftServiceDisplayName === "Teams") {
        navigate("/inbox" + updatedSearch);
      } else {
        navigate("/channels" + updatedSearch);
      }
    }
  }, [appProperties?.authCRM]);

  return (
    <div className="hs-bg-white h-100 overflow-hidden d-flex justify-content-center align-items-center w-100">
      <div className=" ">
        <div className="d-flex align-items-center justify-content-center flex-column hs-h-50">
          <div className="d-flex flex-column align-items-center justify-content-center hs-h-20">
            <Image src={HelloSendLogo} preview={false} draggable={false} className="" height={50} width={50} />
          </div>
          <div className="hs-h-10 d-flex align-items-center justify-content-center pt-2">
            <h5>Welcome to HelloSend</h5>
          </div>
          <div className={`${Classes["infoPara"]} hs-fs-14 w-100 ps-2 pe-2 hs-color-dark-grey hs-h-10 `}>
            Connect your Zoho CRM account to get started with HelloSend's powerful features.
          </div>
        </div>
        <div className="hs-h-15 w-100 d-flex align-items-center justify-content-center pt-2">
          <Row className="h-100 d-flex justify-content-between align-items-center w-75 pt-4 ">
            <Col
              xl={5}
              md={6}
              xs={7}
              className={` ${Classes["zoho-connect-messaging-Ml"]} hs-h-30 w-100 d-flex align-items-center justify-content-center flex-column`}
            >
              <Row className="actionIconsSpriteForSideBar cursor-default inboxIcon "></Row>
              <Row className="pt-2">SMS</Row>
            </Col>
            <Col xl={4} md={6} xs={6} className="hs-h-30 w-100 d-flex align-items-center justify-content-center flex-column ">
              <Row className="onboarding-call-icon callActionIcons cursor-default"></Row>
              <Row className="pt-2">Call</Row>
            </Col>
            <Col
              xl={4}
              md={4}
              xs={7}
              className={`${Classes["zoho-connect-automation-pl"]} hs-h-30 w-100 d-flex align-items-center justify-content-center flex-column`}
            >
              <Row className="onboarding-whatsapp-icon actionIconsSprite cursor-default"></Row>
              <Row className="pt-2">Whatsapp</Row>
            </Col>
          </Row>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center hs-h-10">
          <Button
            type=""
            className={`${Classes["zoho-btn"]} hs-bg-violet d-flex align-items-center text-decoration-none hs-button`}
            href={
              appProperties?.leftServiceClientDetails?.authorizeUrl
                ? appProperties.leftServiceClientDetails.authorizeUrl +
                  "?response_type=code&client_id=" +
                  appProperties.leftServiceClientDetails.clientId +
                  "&redirect_uri=" +
                  appProperties.leftServiceClientDetails.redirectUrl +
                  "&state=" +
                  leftServiceId +
                  ":::" +
                  rightServiceId +
                  "&scope=" +
                  appProperties.leftServiceClientDetails.authScopes +
                  "&access_type=offline&serviceOrigin=" +
                  serviceOrigin
                : "#"
            }
            target={appProperties?.leftServiceClientDetails?.authorizeUrl ? "_blank" : "_self"}
          >
            Connect with Zoho
            <span className="actionIconsSprite zoho-connect-arrow hs-fw-600"></span>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ZohoCRMLandingPage;
