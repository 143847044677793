import { Button, message, Radio } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useReactFlow } from "reactflow";
import { AppContext } from "../../../context/AppContext";
import useHttp from "../../../hooks/useHttp";
import NodesInput from "../common/NodesInput";
import VoiceSelect from "../common/VoiceSelect";
import { useVoiceTeams } from "../../hooks/useVoiceTeams";

const RingTeam = ({ id, data, number, teamData, setTeamData, integId }) => {
  const navigate = useNavigate();
  const { getVoiceTeams } = useVoiceTeams();
  const { nodes, edges, setNodes, setEdges, getNodes } = useReactFlow();
  const [selectedTeam, setSelectedTeam] = useState("");
  const [appProperties] = useContext(AppContext);
  const [teams, setTeams] = useState();
  const { fetchData } = useHttp();
  const [showAddAgents, setShowAddAgents] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (data?.teamId && teams) {
      if (Array.isArray(teams)) {
        const selectedTeamOption = teams.find((team) => team.teamId === data?.teamId);
        console.log(selectedTeamOption, "option th", teams);
        if (selectedTeamOption) {
          setSelectedTeam(selectedTeamOption?.friendlyName || "");
        } else {
          console.log(`No team found for teamId: ${data?.teamId}`);
        }
      } else {
        console.log("Teams data is not an array", teams);
      }
    }
  }, [teams]);

  console.log("Selected-team>>", selectedTeam);

  const handleCreateTeamClick = () => {
    navigate(`/voice/teams${location.search}`);
  };

  const handleApply = () => {
    if (selectedTeam) {
      message.success("Applied Successfully");
      setTeamData({
        ...teamData,
        apply: true,
      });
    } else {
      message.warning("Please provide Team");
    }
  };

  useEffect(() => {
    getVoiceTeams().then((response) => {
      if (response?.length === 0) {
        setShowAddAgents(true);
      }
      if (response) {
        setTeams(response);
      }
    });
  }, [fetchData, integId]);

  const teamOptions = Array.isArray(teams)
    ? teams.map((team) => ({
        value: team.teamId,
        label: team.friendlyName,
      }))
    : [];

  const handleOnTeamChange = (teamId) => {
    const selectedTeamObj = teamOptions.find((team) => team.value === teamId);

    if (selectedTeamObj) {
      console.log("Ring team-value >>", teamId, selectedTeamObj.label);

      setSelectedTeam(teamId);
      const nodes = getNodes();
      const currentNode = nodes?.find((node) => node.id === id);
      const updatedNode = {
        ...currentNode,
        data: {
          ...currentNode?.data,
          teamId: teamId,
          teamName: selectedTeamObj.label,
        },
      };
      setNodes((prevNodes) => prevNodes.map((node) => (node.id === id ? updatedNode : node)));
    }
  };

  return (
    <>
      {!showAddAgents ? (
        <div>
          <h6>Choose Team</h6>
          <VoiceSelect value={selectedTeam} onChange={handleOnTeamChange} options={teamOptions} optionSpecification={false} label={"Team"} />
          <label className="hs-fs-16 mt-4 mb-2">Ringing Type:</label>
          <Radio.Group
            onChange={(value) => setTeamData({ ...teamData, ringingType: value?.target?.value })}
            value={teamData?.ringingType}
            className="ps-2"
          >
            <Radio value={1} className="hs-fs-16 businessHour-radio-btn">
              Simultaneous
            </Radio>
            <Radio value={2} className="hs-fs-16 businessHour-radio-btn">
              Sequential
            </Radio>
          </Radio.Group>
          <label className="hs-fs-16 mt-4 mb-2">Ring Interval</label>
          <br />
          <NodesInput
            type="number"
            onChange={(e) => setTeamData({ ...teamData, ringInterval: parseInt(e.target.value, 10) || 0 })}
            value={teamData?.ringInterval}
          />{" "}
          <br />
          {teamData?.ringingType === 2 && (
            <>
              <label className="hs-fs-16 mt-4 mb-2">Repeat Count</label> <br />
              <NodesInput
                type="number"
                onChange={(e) => setTeamData({ ...teamData, repeatCount: parseInt(e.target.value, 10) || 0 })}
                value={teamData?.repeatCount}
              />
            </>
          )}
          <br />
          <Button className="callFlowButton mt-4" onClick={handleApply}>
            Apply
          </Button>
        </div>
      ) : (
        <div className="d-flex align-items-center flex-column pt-5 mt-5">
          <div className="mt-5 RingTeamBellIcon" />
          <div className="hs-color-light-grey-1 mt-2">Please create a team to add</div>
          <Button className="callFlowButton mt-2" onClick={handleCreateTeamClick}>
            Create Team
          </Button>
        </div>
      )}
    </>
  );
};

export default RingTeam;
