import React from "react";
import { Handle, Position } from "reactflow";
import { CustomNode } from "./CallFlowNodes";

const HangUp = ({ id }) => {

  return (
    <>
      <Handle type="target" position={Position.Top} className="handleInvisible hangUp" />
      
      <div className="d-flex flex-row" >
        <div className="callActionIcons hangUpNodeIcon" />
       
      </div>

    </>
  );
};

export default HangUp;
