import { Col, Input, Menu, Row, Tag, Select, Divider, Tooltip, Spin, Typography, Card } from "antd";
import { useState, useContext } from "react";
import { useVoiceContext } from "../../context/VoiceContext";
import { useEffect } from "react";
import { AppContext } from "./../../../context/AppContext";
import useHttp from "./../../../hooks/useHttp";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import CommonUserAvatar from "../../CommonUserAvatar";
import VoiceCallLog from "../VoiceCallLog";
import OfflineComponent from "../info-component/OfflineComponent";
import { voiceAgentStatus } from "../../utils/VoiceUtils";
import HButton from "../../../components/custom/input/button/Button";
const { Search } = Input;

const CallRecordSearch = (props) => {
  let {
    orgNumber,
    setIdleSelectedMenu,
    idleSelectedMenu,
    setOpenCallDetails,
    setCustomerNumber,
    setCallDetails,
    phoneNumbers,
    setLastName,
    setRecordId,
    setModule,
    setAvatar,
    setRecordLink,
    voiceIntegId,
    setOrgNumber,
    makeOutboundCall,
    showOfflineMessageWhileInit,
    handleStatusChange,
    setShowOfflineMessageWhileInit,
    isVoiceUser,
  } = props;
  const [appProperties] = useContext(AppContext);
  const { fetchData } = useHttp();
  const [callProperties, setCallProperties] = useVoiceContext();
  const [selectedMenu, setSelectedMenu] = useState("home");
  const [phoneNumberInDialPadForClickToCall, setPhoneNumberInDialPadForClickToCall] = useState(callProperties?.callerNumber);
  const [valueToBeSearched, setValueToBeSearched] = useState(null);
  const [phoneNumberSelectSearchValue, setPhoneNumberSelectSearchValue] = useState("");
  const [displayClickToCallIcon, setDisplayClickToCallIcon] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [contactCard, setContactCard] = useState();
  const [searchDropdownLoading, setSearchDropdownLoading] = useState(false);
  const [debouncedSearchContact] = useDebounce(phoneNumberInDialPadForClickToCall, 400);
  const [notFoundContent, setNotFoundContent] = useState(null);
  const [outboundDisabledForThisUser, setOutboundDisabledForThisUser] = useState(false);

  const callHeaderItems = [
    { label: "Home", key: "home" },
    { label: "Call History", key: "callHistory" },
  ];
  const isDisabled = callProperties?.agentStatusValue === voiceAgentStatus.OFFLINE;
  const filteredOptions = phoneNumbers?.filter((option) => option.value.includes(phoneNumberSelectSearchValue));
  const handleSelectContact = (recordId) => {
    let contactObj = contactList.filter((obj) => obj?.id === recordId)[0];
    setContactCard(contactObj);
    // setPhoneToDial(contactObj?.lastName);
  };

  useEffect(() => {
    if (debouncedSearchContact?.length > 2 && voiceIntegId) {
      setSearchDropdownLoading(<div className="actionIconsSprite search-loading"></div>);
      fetchData(
        "omessage/" + voiceIntegId + "/search?searchText=" + encodeURIComponent(debouncedSearchContact) + "&module=ALL",
        "GET",
        null,
        appProperties
      )
        .then(function (response) {
          try {
            if (response) {
              response = JSON.parse(response);
              if (response?.data?.data) {
                let data = response?.data?.data;
                setContactList(data);
                if (data.length > 0) {
                  setNotFoundContent(null);
                } else {
                  setNotFoundContent(<ContactNotFound />);
                }
              }
              setSearchDropdownLoading(null);
            }
          } catch (error) {
            console.error("Error processing response:", error);
            setSearchDropdownLoading(null);
          }
        })
        .catch((error) => {
          console.log("Error processing response:", error);
          setSearchDropdownLoading(null);
        });
    } else {
      setNotFoundContent(null);
    }
  }, [debouncedSearchContact]);

  useEffect(() => {
    if (phoneNumberInDialPadForClickToCall) {
      setDisplayClickToCallIcon(true);
    } else {
      setDisplayClickToCallIcon(false);
    }
  }, [phoneNumberInDialPadForClickToCall]);

  useEffect(() => {
    console.log("setOutboundDisabledForThisUser : isVoiceUser >>>>>>>>>", isVoiceUser);
    console.log("setOutboundDisabledForThisUser : phoneNumbers >>>>>>>>>", phoneNumbers);
    console.log("setOutboundDisabledForThisUser : phoneNumbers?.length >>>>>>>>>", phoneNumbers?.length);
    console.log("setOutboundDisabledForThisUser : callProperties?.agentStatusValue >>>>>>>>>", callProperties?.agentStatusValue);
    console.log("setOutboundDisabledForThisUser : voiceAgentStatus.ONLINE_WEB >>>>>>>>>", voiceAgentStatus.ONLINE_WEB);
    console.log(
      "setOutboundDisabledForThisUser : callProperties?.agentStatusValue === voiceAgentStatus.ONLINE_WEB >>>>>>>>>",
      callProperties?.agentStatusValue === voiceAgentStatus.ONLINE_WEB
    );

    if (isVoiceUser && phoneNumbers && phoneNumbers?.length === 0 && callProperties?.agentStatusValue === voiceAgentStatus.ONLINE_WEB) {
      setOutboundDisabledForThisUser(true);
      console.log("setOutboundDisabledForThisUser : showOfflineMessageWhileInit >>>>>>>>>", showOfflineMessageWhileInit);
      console.log("setOutboundDisabledForThisUser : outboundDisabledForThisUser >>>>>>>>>", outboundDisabledForThisUser);
    } else {
      setOutboundDisabledForThisUser(false);
    }
  }, [isVoiceUser, phoneNumbers, callProperties?.agentStatusValue]);
  console.log("callrecordSearch>>>>>>idleSelectedMenu>>>", idleSelectedMenu);
  useEffect(() => {
    if (idleSelectedMenu === "callHistory") {
      setSelectedMenu("callHistory");
    }
  }, [idleSelectedMenu]);
  const handleDialPadHistoryClickToCall = (customerNumber, phoneNumber, status) => {
    setCallProperties((prev) => ({
      ...prev,
      isDialPadVisible: true,
      callerNumber: customerNumber,
      callType: "outbound",
    }));
    setSelectedMenu("home");
    setPhoneNumberInDialPadForClickToCall(customerNumber);
  };
  return (
    <div className={`${callProperties?.callStatus} overflow-hidden h-100 hs-w-95 ps-2 voice-dialpad-select-number`}>
      <Row className="w-100 d-flex align-items-center justify-content-center hs-h-10">
        <Col className="w-100">
          <Menu
            rootClassName="call-menu"
            onClick={(e) => setSelectedMenu(e.key)}
            selectedKeys={[selectedMenu]}
            mode="horizontal"
            items={callHeaderItems}
          />
        </Col>
      </Row>

      {selectedMenu === "home" ? (
        <>
          {showOfflineMessageWhileInit ? (
            <OfflineComponent
              handleStatusChange={handleStatusChange}
              agentStatus={callProperties?.agentStatusValue}
              setShowOfflineMessageWhileInit={setShowOfflineMessageWhileInit}
              outboundDisabledForThisUser={outboundDisabledForThisUser}
            />
          ) : outboundDisabledForThisUser ? (
            <>
              <InboundAgentsScreen />
            </>
          ) : (
            <>
              <Row className="w-100 d-flex align-items-center justify-content-start hs-h-10">
                <Col className="hs-w-15">
                  <div className="callActionIcons from-number-call-icon"></div>
                </Col>
                <Col className="hs-w-85 d-flex align-items-start justify-content-center">
                  <Select
                    className="w-100 voice-search-contact-select-container text-white"
                    style={{
                      background: "none",
                    }}
                    value={orgNumber}
                    onChange={(value) => setOrgNumber(value)}
                    suffixIcon={<div className="agentStatusDropDownIcon callActionIcons" />}
                    listItemHeight={10}
                    listHeight={150}
                    dropdownStyle={{ fontSize: "14px" }}
                    options={filteredOptions}
                    dropdownRender={(menu) => (
                      <>
                        <input
                          type="text"
                          placeholder="Search"
                          // value={}
                          onChange={(e) => setPhoneNumberSelectSearchValue(e.target.value)}
                          style={{
                            border: "none",
                            width: "100%",
                            borderBottom: "1px solid #e6e7ec",
                          }}
                          disabled={isDisabled}
                        />
                        {menu}
                      </>
                    )}
                  />
                </Col>
              </Row>
              <Row className="w-100 d-flex align-items-center justify-content-center hs-h-10 ps-3">
                <Col className="w-100 d-flex align-items-center justify-content-center voice-search-contact-select-container">
                  <div className="d-flex flex-row align-items-start w-100 h-100">
                    <div className="call-record-search-icon">
                      <div className="d-flex justify-content-center align-items-center h-100">
                        <div className="callActionIcons dialpad-customer-search-icon"></div>
                      </div>
                    </div>
                    <Select
                      suffixIcon={null}
                      variant={"borderless"}
                      tabIndex={3}
                      value={
                        phoneNumberInDialPadForClickToCall ? phoneNumberInDialPadForClickToCall : valueToBeSearched ? valueToBeSearched : null
                      }
                      autoClearSearchValue={false}
                      notFoundContent={
                        searchDropdownLoading ? (
                          <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                            <Spin size="small" />
                          </div>
                        ) : notFoundContent ? (
                          notFoundContent
                        ) : null
                      }
                      showSearch
                      onSelect={handleSelectContact}
                      onSearch={(e) => {
                        setPhoneNumberInDialPadForClickToCall(e);
                      }}
                      onBlur={(e) => {
                        setPhoneNumberInDialPadForClickToCall(e.target.value);
                        setValueToBeSearched(e.target.value);
                      }}
                      filterOption={false}
                      className="w-100"
                      placeholder={`Search OR Dial number`}
                      popupMatchSelectWidth
                      dropdownStyle={{ minHeight: "2.5rem", alignItems: "center", backgroundColor: "#2A2A3C" }}
                    >
                      {contactList && (
                        <>
                          {Array.from(new Set(contactList.map((obj) => obj?.module))).map((module) => (
                            <Select.OptGroup
                              key={module}
                              label={
                                <Tag
                                  color="#F5F4FF"
                                  style={{
                                    color: "#605bff",
                                    borderRadius: 12,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {module}
                                </Tag>
                              }
                            >
                              {contactList
                                .filter((obj) => obj?.module === module)
                                .map((obj, index) => (
                                  <Select.Option key={`${module}-${index}`} value={obj?.id}>
                                    <CustomerDetails customerObj={obj} />
                                  </Select.Option>
                                ))}
                            </Select.OptGroup>
                          ))}
                        </>
                      )}
                    </Select>
                    <div>
                      {phoneNumberInDialPadForClickToCall ? (
                        <ClickToCallIcon
                          searchValue={phoneNumberInDialPadForClickToCall}
                          setCallProperties={setCallProperties}
                          makeOutboundCall={makeOutboundCall}
                          setCustomerNumber={setCustomerNumber}
                        />
                      ) : null}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="w-100 d-flex align-items-center justify-content-center">
                <Divider style={{ borderColor: "#374151" }}></Divider>
              </Row>
              {contactCard ? (
                <>
                  <Row className="w-100 d-flex align-items-center justify-content-center hs-h-10">
                    <Col className="w-100 d-flex align-items-center justify-content-center">
                      <ContactCard
                        customerObj={contactCard}
                        appProperties={appProperties}
                        setCallProperties={setCallProperties}
                        setLastName={setLastName}
                        setModule={setModule}
                        setRecordId={setRecordId}
                        setAvatar={setAvatar}
                        setRecordLink={setRecordLink}
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                <Row className="w-100 d-flex align-items-center justify-content-center hs-h-50">
                  <Col className="w-100 hs-h-50-per">
                    <SearchNumberOrNameText />
                  </Col>
                </Row>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <VoiceCallLog
            desktopView={false}
            mobileView={true}
            handleDialPadHistoryClickToCall={handleDialPadHistoryClickToCall}
            setOpenCallDetails={setOpenCallDetails}
            setCallDetails={setCallDetails}
          />
        </>
      )}
    </div>
  );
};

export const ClickToCallIcon = ({ searchValue, setCallProperties, makeOutboundCall, setCustomerNumber }) => {
  const handleClick2Call = () => {
    if (searchValue) {
      // setCallProperties((prev) => ({
      //   ...prev,
      //   callerNumber: searchValue,
      //   callType: "outbound",
      // }));
      // setCustomerNumber(searchValue);
      makeOutboundCall(searchValue);
    }
  };

  return <div onClick={handleClick2Call} className="callActionIcons cursor-pointer dialpad-customer-search-click-to-call-icon" />;
};

export const ContactNotFound = ({}) => {
  return (
    <>
      <div className="w-100" style={{ height: "20vh" }}>
        <div className="w-100 d-flex justify-content-center align-items-center hs-h-50">
          <div className="w-100 callActionIcons dialpad-customer-search-icon-for-info-message"></div>
        </div>
        <div className="w-100 d-flex justify-content-center align-items-center hs-h-20">
          <div className="w-100 search-number-info-text hs-fs-16 text-white">No contacts found</div>
        </div>
        <div className="w-100 d-flex justify-content-center align-items-center hs-h-20">
          <div className="w-100 customer-in-offline-message-secondary">Try searching with a different number</div>
        </div>
      </div>
    </>
  );
};
export const SearchNumberOrNameText = ({}) => {
  return (
    <>
      <div className="w-100 d-flex justify-content-center hs-h-20">
        <div className="w-100 callActionIcons dialpad-customer-search-icon-for-info-message"></div>
      </div>
      <div className="w-100 d-flex justify-content-center align-items-center hs-h-50">
        <div className="w-100 search-number-info-text">Search a number or name to view contact details</div>
      </div>
    </>
  );
};

export const CustomerDetails = ({ customerObj }) => {
  return (
    <div className="pt-1 pb-1 d-flex flex-column">
      <div className="d-flex flex-row justify-content-around align-items-center pb-2">
        <div className="d-flex flex-row align-items-start w-100">
          <CommonUserAvatar name={customerObj?.lastName} icon={customerObj?.avatar} size={40} />
          <div className="d-flex flex-column justify-content-start align-items-start w-100 ps-2">
            <div className="w-100 search-customer-truncate-text text-white">{customerObj?.lastName}</div>
            <div
              className="hs-fs-13 hs-fw-500 w-100"
              style={{
                color: "#9CA3AF",
              }}
            >
              {customerObj?.phone}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const openCustomerPage = (appProperties, recordId, recordModule, recordLink) => {
  if ((recordId, recordModule, recordLink)) {
    appProperties.controller.openRecord(null, recordId, recordModule, recordLink);
  }
};

const openAgentPage = (recordOwnerId) => {
  if (recordOwnerId) {
  }
};

export const ContactCard = ({
  customerObj,
  appProperties,
  setCallProperties,
  setLastName,
  setRecordId,
  setModule,
  setAvatar,
  setRecordLink,
}) => {
  const handleClickToCall = (phone) => {
    if (phone) {
      setCallProperties((prev) => ({
        ...prev,
        callerNumber: phone,
        callRecordId: customerObj?.id,
        callRecordModule: customerObj?.recordModule,
        callType: "outbound",
      }));
      setLastName(customerObj?.lastName);
      setRecordId(customerObj?.id);
      setModule(customerObj?.recordModule);
      setAvatar(customerObj?.avatar);
      setRecordLink(customerObj?.recordLink);
    }
  };

  return (
    <div className="text-white hs-w-90">
      {[
        { label: "Module:", value: customerObj?.module },
        {
          label: "Contact name:",
          value: customerObj?.lastName,
          onClick: () => openCustomerPage(appProperties, customerObj?.id, customerObj?.module, customerObj?.recordLink),
          recordLink: true,
        },
        {
          label: "Contact Owner:",
          value: customerObj?.recordOwnerName,
          onClick: () => openAgentPage(appProperties, customerObj?.recordOwnerId),
        },
        { label: "Email Address:", value: customerObj?.recordOwnerEmail },
        {
          label: "Primary phone:",
          value: customerObj?.primaryPhone,
          onClick: () => handleClickToCall(customerObj?.primaryPhone),
          click2CallIcon: customerObj?.primaryPhone ? true : false,
        },
        {
          label: "Secondary phone:",
          value: customerObj?.secondaryPhone,
          onClick: () => handleClickToCall(customerObj?.secondaryPhone),
          click2CallIcon: customerObj?.secondaryPhone ? true : false,
        },
      ].map(({ label, value, onClick, click2CallIcon, recordLink }, index) => (
        <Row key={index} className="line-height-contact-card">
          <Col span={12}>{label}</Col>
          <Col span={12}>
            <Tooltip title={value}>
              <span className={`contact-record-text d-flex flex-row ${recordLink ? "contact-record-text-link" : ""}`}>
                <div onClick={onClick}> {value} </div>
                {click2CallIcon && <div onClick={onClick} className="callActionIcons cursor-pointer grey-click-to-call-icon" />}
              </span>
            </Tooltip>
          </Col>
        </Row>
      ))}
    </div>
  );
};
export const InboundAgentsScreen = (props) => {
  let { currentPlan, planNameChargeBee, handleClick, agentStatus, buttonLoading, goOnline } = props;
  return (
    <>
      <div className="hs-h-90 overflow-hidden w-100 ps-3 pe-3">
        <div className="h-100 d-flex flex-column justify-content-center align-items-start">
          <div className="w-100 d-flex justify-content-center align-items-center hs-h-25">
            <div className="callActionIcons cursor-pointer voice-inbound-only-agents-screen-icon" />
          </div>
          <div className="w-100 d-flex flex-column justify-content-center hs-h-30">
            <div className="w-100 d-flex justify-content-center align-items-center hs-h-50">
              <span className="customer-in-offline-message text-white hs-fw-500">
                <>Inbound-Only Call Mode</>
              </span>
            </div>
            <div className="w-100 d-flex justify-content-center hs-h-50">
              <span className="customer-in-offline-message-secondary">
                {" "}
                You are now in Inbound-Only mode for calls. Stay online to catch all incoming calls.
              </span>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-center hs-h-60">
            <div className="w-75 d-flex justify-content-center align-items-start pt-3">
              {ConstructTileInfo("Inbound")}
              {ConstructTileInfo("Outbound")}
            </div>
          </div>
        </div>
      </div>
    </>
  );

  function ConstructTileInfo(direction) {
    return (
      <div className="w-100 d-flex justify-content-center hs-h-50">
        <div
          className="w-75 d-flex flex-column justify-content-center h-100"
          style={{
            border: "1px solid #374151",
            borderRadius: "8px",
          }}
        >
          <div className="w-100 d-flex justify-content-center hs-h-50">
            <div
              className={`callActionIcons cursor-pointer ${
                direction === "Inbound" ? "voice-inbound-only-agents-screen-inbound-icon" : "voice-inbound-only-agents-screen-outbound-icon"
              }`}
            />
          </div>
          <div className="w-100 d-flex justify-content-center hs-h-15">
            <span
              className="hs-fw-400 hs-fs-10"
              style={{
                color: "#9CA3AF",
              }}
            >
              {direction}
            </span>
          </div>
          <div className="w-100 d-flex justify-content-center hs-h-10">
            <span className={`hs-fw-500 hs-fs-12 ${direction === "Inbound" ? "text-white" : "text-danger"}`}>
              {direction === "Inbound" ? "Active" : "Restricted"}
            </span>
          </div>
        </div>
      </div>
    );
  }
};
export default CallRecordSearch;
