import React, { useContext, useEffect, useState } from "react";
import { useReactFlow } from "reactflow";
import { Button, message } from "antd";
import useHttp from "../../hooks/useHttp";
import { isEqual } from "lodash";
import { useNavigate, useLocation, useBlocker } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { CommonModal } from "../../pages/utils/CommonVessels";
import "../assets/css/nodes.css";
const FlowFooter = ({
  initialPhoneNumberData,
  initialBusinessHourData,
  initialWelcomeMusicData,
  initialCallWaitingMusicData,
  initialTeamData,
  initialBusyMusicData,
  initialUnavailableMusicData,
  initialCallRecordingData,
  callFlowId,
  phoneNumber,
  callFlowName,
  setCallFlowName,
  integId,
}) => {
  const [phoneNumberData, setPhoneNumberData] = useState(initialPhoneNumberData?.apply);
  const [businessHourData, setBusinessHourData] = useState(initialBusinessHourData?.apply);
  const [welcomeMusicData, setWelcomeMusicData] = useState(initialWelcomeMusicData?.apply);
  const [callWaitingMusicData, setCallWaitingMusicData] = useState(initialCallWaitingMusicData?.apply);
  const [teamData, setTeamData] = useState(initialTeamData?.apply);
  const [busyMusicData, setBusyMusicData] = useState(initialBusyMusicData?.apply);
  const [unavailableMusicData, setUnavailableMusicData] = useState(initialUnavailableMusicData?.apply);
  const [callRecordingData, setCallRecordingData] = useState(initialCallRecordingData?.apply);
  const [loading, setLoading] = useState(false);
  const [hasAppliedData, setHasAppliedData] = useState(false);
  const [flowNameProp, setFlowNameProp] = useState(callFlowName);

  useEffect(() => {
    setFlowNameProp(callFlowName);
  }, [callFlowName]);
  const checkForChanges = () => {
    return (
      !isEqual(phoneNumberData, initialPhoneNumberData?.apply) ||
      !isEqual(businessHourData, initialBusinessHourData?.apply) ||
      !isEqual(welcomeMusicData, initialWelcomeMusicData?.apply) ||
      !isEqual(callWaitingMusicData, initialCallWaitingMusicData?.apply) ||
      !isEqual(teamData, initialTeamData?.apply) ||
      !isEqual(busyMusicData, initialBusyMusicData?.apply) ||
      !isEqual(unavailableMusicData, initialUnavailableMusicData?.apply) ||
      !isEqual(callRecordingData, initialCallRecordingData?.apply) ||
      !isEqual(flowNameProp, callFlowName)
    );
  };
  // console.log(
  //   "ff ph>>>>>",
  //   phoneNumberData,
  //   businessHourData,
  //   welcomeMusicData,
  //   callWaitingMusicData,
  //   teamData,
  //   busyMusicData,
  //   unavailableMusicData,
  //   callRecordingData,
  //   flowNameProp
  // );
  // console.log(
  //   "ff iph>>>>",
  //   initialPhoneNumberData?.apply,
  //   initialBusinessHourData?.apply,
  //   initialWelcomeMusicData?.apply,
  //   initialCallWaitingMusicData?.apply,
  //   initialTeamData?.apply,
  //   initialBusyMusicData?.apply,
  //   initialUnavailableMusicData?.apply,
  //   initialCallRecordingData?.apply,
  //   callFlowName
  // );

  useEffect(() => {
    setHasAppliedData(checkForChanges());
    setHasUnsavedChanges(checkForChanges());
  }, [
    phoneNumberData,
    initialPhoneNumberData?.apply,
    businessHourData,
    initialBusinessHourData?.apply,
    welcomeMusicData,
    initialWelcomeMusicData?.apply,
    callWaitingMusicData,
    initialCallWaitingMusicData?.apply,
    teamData,
    initialTeamData?.apply,
    busyMusicData,
    initialBusyMusicData?.apply,
    unavailableMusicData,
    initialUnavailableMusicData?.apply,
    callRecordingData,
    initialCallRecordingData?.apply,
    callFlowName,
    flowNameProp,
  ]);

  const { fetchData } = useHttp();
  const navigate = useNavigate();
  const location = useLocation();
  const [appProperties] = useContext(AppContext);
  const path = window.location.pathname;
  const { getEdges, getNodes } = useReactFlow();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [stateForCancel, setStateForCancel] = useState(false);
  const [showModel, setShowModel] = useState(false);
  // const blocker = useBlocker(hasUnsavedChanges);
  const blocker = useBlocker(({ currentLocation, nextLocation }) => hasUnsavedChanges && currentLocation.pathname !== nextLocation.pathname);

  const handleCancel = () => {
    setStateForCancel(true);
    if (hasUnsavedChanges) {
      setShowModel(true);
    } else {
      navigate("/voice/number/twilio_voice" + location.search);
    }
  };

  const handleDiscardChanges = () => {
    if (blocker.state === "blocked") {
      setHasUnsavedChanges(false);
      setShowModel(false);
      blocker.proceed();
      navigate("/voice/number/twilio_voice" + location.search);
    } else {
      navigate("/voice/number/twilio_voice" + location.search);
    }
  };

  async function getFileHash(file) {
    const arrayBuffer = await file.arrayBuffer();
    const hashBuffer = await crypto.subtle.digest("SHA-256", arrayBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
    return hashHex;
  }
  const handleSAVE = () => {
    setHasUnsavedChanges(false);
    const formData1 = new FormData();
    const files = [
      initialPhoneNumberData,
      initialWelcomeMusicData,
      initialCallRecordingData,
      initialBusyMusicData,
      initialCallWaitingMusicData,
      initialUnavailableMusicData,
    ];

    const requestData = [];
    const promises = files.map((musicFile) => {
      const nodeId = musicFile?.nodeId;
      const file = musicFile?.music;

      if (file instanceof File) {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (["mp3", "wav", "ogg"].includes(fileExtension)) {
          formData1.append("files", file);
          return getFileHash(file).then((hash) => {
            requestData.push({
              nodeId: nodeId,
              hashCode: hash,
              text: null,
              url: null,
            });
          });
        }
      } else if (typeof file === "string") {
        const urlPattern = new RegExp("^(https?:\\/\\/)?");
        if (urlPattern.test(file) && file.startsWith("http")) {
          requestData.push({
            nodeId: nodeId,
            hashCode: null,
            text: null,
            url: file,
          });
        } else {
          requestData.push({
            nodeId: nodeId,
            hashCode: null,
            text: file,
            url: null,
          });
        }
      }
      return Promise.resolve();
    });

    const nodes = getNodes();
    const edges = getEdges();
    const teamNode = nodes.find((node) => node.data && node.data.teamId);

    const payload = {
      callFlowEntity: {
        nodes: nodes,
        edges: edges,
        callFlowName: flowNameProp,
        callFlowId: callFlowId,
        teamId: teamNode?.data?.teamId,
      },
      numberVoiceConfigurationEntity: {
        phoneNumber: phoneNumber,
        fallBackNumber: initialPhoneNumberData?.fallBackNumber,
        ringingType: initialTeamData?.ringingType,
        ringInterval: initialTeamData?.ringInterval,
        repeatCount: initialTeamData?.repeatCount,
        voiceMailStatus: initialPhoneNumberData?.voiceMailStatus,
        callRecordStatus: initialCallRecordingData?.recordStatus,
        businessHourId: initialBusinessHourData?.businessHourId,
        businessHourType: initialBusinessHourData?.isCalendarHours === true ? 1 : 2,
      },
      businessHourModel: {
        isCalendarHours: initialBusinessHourData?.isCalendarHours,
        timeZone: initialBusinessHourData?.timeZone,
        businessHourId: initialBusinessHourData?.businessHourId,
        businessDays: initialBusinessHourData?.businessDays,
      },
    };

    formData1.append("businessHour", JSON.stringify(payload.businessHourModel));
    formData1.append("callFlowData", JSON.stringify(payload.callFlowEntity));
    formData1.append("numberVoiceConfig", JSON.stringify(payload.numberVoiceConfigurationEntity));

    setLoading(true);

    Promise.all(promises)
      .then(() => {
        formData1.append("nodeData", JSON.stringify(requestData));
        return fetchData(`voice/${integId}/callFlow/${callFlowId}`, "PUT", formData1, appProperties, {
          "Content-Type": "multipart/form-data",
        });
      })
      .then(() => {
        message.success("Call Flow Updated Successfully");
        navigate("/voice/number/twilio_voice" + location.search);
      })
      .catch((error) => {
        console.error("Error during upload:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  const handleCloseModal = () => {
    setShowModel(false);
    if (blocker.state === "blocked") {
      blocker.reset();
    }
  };

  const modalBody = (
    <>
      <div>
        <div className="ms-2">
          <h5 className="hs-color-dark-blue">Unsaved changes</h5>
          <div className="hs-color-dark-blue">Do you want to save or discard changes?</div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end">
        <Button className="mt-2 row-reverse d-inline-block hs-btn-cancel-small w-100 h-45" onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button className="hs-btn-small ms-3 mt-2 row-reverse d-inline-block w-100 h-45" onClick={handleDiscardChanges}>
          Discard
        </Button>
      </div>
    </>
  );
  return (
    <>
      <CommonModal
        width={350}
        body={modalBody}
        open={blocker.state === "blocked" || showModel}
        doOnClose={handleCloseModal}
        cancelButtonProps={false}
        okButtonProps={false}
        modalCloseIcon={true}
        header={<div style={{ cursor: "default" }} className="actionIconsSpriteForSideBar unsaved-modal-icon" />}
      />

      <div className={`d-flex flex-row justify-content-between align-items-center w-100 h-100`}>
        <div className="d-flex justify-content-center align-items-center w-100">
          <div className="hs-fs-16 hs-fw-550 d-flex justify-content-start align-items-center w-100 ps-3">
            <input
              className="custom-disabled-input rounded w-25"
              type="text"
              value={flowNameProp != null && flowNameProp !== undefined ? flowNameProp : "Call Flow"}
              // style={{ border: "none", outline: "none" }}
              disabled={path.includes("/flow")}
              onChange={(e) => setFlowNameProp(e.target.value)}
            />
          </div>
        </div>
        <div className="d-flex">
          <>
            <Button className="row-reverse d-inline-block hs-btn-cancel-small hs-fs-14" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="saveButton hs-border-10 hs-button  hs-big-button hs-fs-16 px-3 hs-button-primary ms-3 me-3"
              onClick={handleSAVE}
              disabled={!hasAppliedData}
              loading={loading}
            >
              Save
            </Button>
          </>
        </div>
      </div>
    </>
  );
};

export default FlowFooter;
