import React, { createContext, useContext, useMemo, useState } from "react";
import { voiceAgentStatus } from "../utils/VoiceUtils";

const VoiceContext = createContext();

export const VoiceProvider = ({ children }) => {
  const [callProperties, setCallProperties] = useState({
    agentStatusValue: voiceAgentStatus.OFFLINE,
    twilioCapToken: "",
    disableAgentStatusChange: false,
    isDialPadVisible: "",
    callerNumber: "",
    selectedFromNumber: "",
    initiateOutboundCall: false,
    callRecordId: "",
    callRecordModule: "",
    loadedSDK: "",
    callStatus: "idle",
    savedNumbers: [],
    callType: "",
    incomingCall: false,
  });

  const callObject = useMemo(() => [callProperties, setCallProperties], [callProperties]);

  return <VoiceContext.Provider value={callObject}>{children}</VoiceContext.Provider>;
};

export const useVoiceContext = () => {
  return useContext(VoiceContext);
};
