export const showLicenseWarningNotification = (appProps, title, message, pauseOnHover) => {
  appProps?.notificationAPI.warning({
    message: title,
    description: message,
    showProgress: true,
    duration: 10,
    pauseOnHover,
  });
};

export const showLicenseSuccessNotification = (appProps, title, message, pauseOnHover) => {
  appProps?.notificationAPI.success({
    message: title,
    description: message,
    showProgress: true,
    duration: 10,
    pauseOnHover,
  });
};
