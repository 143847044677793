import React from "react";
import { Tag, Popover, Tooltip } from "antd";

const TagWithPopover = ({
  truncateStyle = "",
  items = [],
  displayLimit = 2,
  title = "Details",
  tagColor = "hs-bg-tag-purple",
  itemFormatter = (item) => item,
  itemValidator = (item) => true,
}) => {
  const validItems = (Array.isArray(items) ? items : [items]).map(itemFormatter).filter(itemValidator);

  const popoverContent = (
    <div className="tag-popover-content">
      {validItems.slice(displayLimit).map((item, idx) => (
        <div key={idx} className="popover-item">
          {item.trim()}
        </div>
      ))}
    </div>
  );
  return validItems.length > 0 ? (
    <div style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
      {validItems.slice(0, displayLimit).map((item, idx) => (
        <Tooltip title={item?.trim()}>
          <Tag key={idx} className={`hs-tag-item hs-fw-500 ${tagColor} ${truncateStyle}`}>
            {item?.trim()}
          </Tag>
        </Tooltip>
      ))}
      {validItems.length > displayLimit && (
        <Popover content={popoverContent} title={title} trigger="hover" placement="topLeft">
          <Tag className={`${tagColor} hs-tag-item hs-fw-500  ${truncateStyle}`}>+{validItems.length - displayLimit}</Tag>
        </Popover>
      )}
    </div>
  ) : (
    <span className="no-items">{items.length > 0 ? items.join(" ") : "-"}</span>
  );
};

export default TagWithPopover;
