import React, { useContext } from "react";
import CommonUserAvatar from "../../CommonUserAvatar";
import { AppContext } from "../../../context/AppContext";

const CallHistoryDetails = ({ callDetails, setOpenCallDetails }) => {
  console.log("callDetails", callDetails);
  const [appProperties] = useContext(AppContext);
  const handleCallDetailsClose = () => {
    setOpenCallDetails(false);
  };
  const agentAction = callDetails?.callDirection === "INBOUND" ? "Attened By" : "Initiated By";
  const timestamp = callDetails?.createdTime;
  const date = new Date(timestamp);

  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
  const recordingUrl = Array.isArray(callDetails?.recordingUrls) ? callDetails?.recordingUrls.find((url) => url !== null) : null;
  const voiceMailRecording = callDetails?.voiceMailRecording;

  const durationInSeconds = callDetails?.callDuration || 0;
  const minutes = Math.floor(durationInSeconds / 60);
  const seconds = durationInSeconds % 60;
  const formattedCallDuration = minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`;
  const participantIconUrl = appProperties?.licenseObj?.allLicensedUserDetails.find(
    (user) => user.osyncUserId === callDetails?.participant
  )?.avatar;
  return (
    <>
      {" "}
      <div className="hs-h-10 d-flex flex-row justify-content-between px-3 align-items-center border-bottom-dark-grey">
        <div className="text-white">Call Details</div>
        <div className="callActionIcons call-close-icon cursor-pointer" onClick={handleCallDetailsClose} />
      </div>
      <div className="call-history-details-container">
        <div className="  call-history-details-content">
          <div className="hs-h-20 mx-3 border-bottom-dark-grey d-flex flex-column justify-content-center gap-2">
            <div className="d-flex flex-row align-items-center">
              <div className="callActionIcons call-history-call-icon" />
              <div className="text-white  hs-fs-13 ps-2">
                {" "}
                {callDetails?.callDirection === "INBOUND" ? callDetails?.fromNumber : callDetails?.toNumber}
              </div>
            </div>
            <div className="d-flex flex-row align-items-center">
              <div className="callActionIcons call-history-date-icon" />
              <div className="hs-color-md-grey pt-2 hs-fs-12 ps-2">{formattedDate}</div>
            </div>
            <div className={`d-flex flex-row align-items-center`}>
              <div className="callActionIcons call-history-duration-icon" />
              <div className="d-flex flex-row">
                <div className="hs-color-md-grey hs-fs-12 pt-2 ps-2">{formattedTime}</div>
                {true && (
                  <div className="d-flex flex-row pt-2">
                    <div className="callActionIcons dot-icon" />
                    {formattedCallDuration && <div className="hs-color-md-grey hs-fs-12">{formattedCallDuration}</div>}
                  </div>
                )}
              </div>
            </div>
          </div>
          {callDetails?.participantName !== "null" && (
            <div className="hs-h-15 mx-3 d-flex flex-column justify-content-center border-bottom-dark-grey gap-2">
              <div className="text-white ps-2">{agentAction}</div>
              <div className="d-flex flex-row align-items-center gap-3 ps-2">
                <CommonUserAvatar
                  icon={participantIconUrl}
                  name={callDetails?.participantName === "null" ? "Unknown Caller" : callDetails?.participantName}
                />
                <div className="text-white">{callDetails?.participantName}</div>
              </div>
            </div>
          )}
          <div className="hs-h-20 mx-3 d-flex flex-column justify-content-center gap-2 border-bottom-dark-grey">
            <div className="text-white ps-2">{recordingUrl ? "Call Recording" : "Voicemail"}</div>
            <div className="ps-2">
              <audio src={recordingUrl ? recordingUrl : voiceMailRecording ? voiceMailRecording : null} controls />
            </div>
          </div>
          <div className=" mx-3 border-top-dark-grey d-flex flex-column justify-content-start gap-2">
            <div className="text-white ps-2">Notes</div>
            <div
              className="hs-color-light-grey-3 ps-2"
              style={{
                // maxHeight: "none",
                // overflowY: "visible",
                overflowX: "hidden",
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
              }}
            >
              {callDetails?.callDescription}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CallHistoryDetails;
