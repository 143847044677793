import React from "react";
import { Layout, Menu, Row, Col } from "antd";
import { Link, useLocation } from "react-router-dom";
import "../assets/css/voiceSideBar.css";

const { Sider } = Layout;

const VoiceSideBar = () => {
  const location = useLocation();
  const items = [
    {
      key: "1",
      label: (
        <Link to={`/voice${location.search}`} style={{ textDecoration: "none" }} className="d-flex flex-row">
          <div className="sideBarHomeIcon me-3" /> Home
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link to={`/voice/analytics${location.search}`} style={{ textDecoration: "none" }} className="d-flex flex-row">
          <div className="analyticsSideBarIcon me-3" /> Analytics
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link to={`/voice/teams${location.search}`} style={{ textDecoration: "none" }} className="d-flex flex-row">
          <div className="teamSideBarIcon me-3" /> Teams
        </Link>
      ),
    },
    {
      key: "4",
      label: (
        <Link to={`/voice/callflow${location.search}`} style={{ textDecoration: "none" }} className="d-flex flex-row">
          <div className="callflowSideBarIcon me-3" /> Call Flow
        </Link>
      ),
    },
    {
      key: "5",
      label: (
        <Link to={`/voice/callLogs${location.search}`} style={{ textDecoration: "none" }} className="d-flex flex-row">
          <div className="calllogSidebarIcon me-3" /> Call Logs
        </Link>
      ),
    },
    {
      key: "6",
      label: (
        <Link to={`/settings/twilio${location.search}`} style={{ textDecoration: "none" }} className="d-flex flex-row">
          <div className="settingSidebarIcon me-3" /> Settings
        </Link>
      ),
    },
  ];

  return (
    <Sider className="voiceSider voice" width={218} theme="light">
      <div className="helloSendLogoDiv">
        <div className="helloSendLogo" />
        <div className="helloSendText">
          <span className="helloSendTitle">Hellosend</span>
          <span className="helloSendSubTitle">OApps.xyz</span>
        </div>
      </div>
      <Menu theme="light" mode="inline" style={{ border: "none" }} className="voiceSideBar" defaultSelectedKeys={["1"]}>
        {items.map((item) => (
          <Menu.Item className="voiceMenuItem" key={item.key}>{item.label}</Menu.Item>
        ))}
      </Menu>
    </Sider>
  );
};

export default VoiceSideBar;
