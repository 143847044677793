import { Button, message, Radio, Switch, TimePicker } from "antd";
import dayjs from "dayjs";
import React from "react";
import TimezoneSelect from "react-timezone-select";
import "../../assets/css/businessHours.css";
import HButton from "../../../components/custom/input/button/Button";

const BusinessHour = ({ id, data, number, businessHourData, setBusinessHourData, integId }) => {
  const format = "HH:mm";

  const handleDayToggle = (day) => {
    setBusinessHourData((prevState) => ({
      ...prevState,
      businessDays: prevState?.businessDays?.map((item, index) =>
        index.toString() === day
          ? {
              ...item,
              isOpen: item?.isOpen ? 0 : 1,
            }
          : item
      ),
    }));
  };

  const handleStartChange = (day, time) => {
    setBusinessHourData((prevState) => ({
      ...prevState,
      businessDays: prevState?.businessDays.map((item, index) =>
        index?.toString() === day
          ? {
              ...item,
              hourStartTime: time.format("HH"),
              hourStartMinute: time.format("mm"),
            }
          : { ...item }
      ),
    }));
  };
  console.log("TimeZone check>>>>", businessHourData?.timeZone);

  const handleEndChange = (day, time) => {
    console.log(time, "time>>>>>", day);
    setBusinessHourData((prevState) => ({
      ...prevState,
      businessDays: prevState?.businessDays.map((item, index) =>
        index?.toString() === day
          ? {
              ...item,
              hourEndTime: time.format("HH"),
              minuteEndTime: time.format("mm"),
            }
          : item
      ),
    }));
  };

  const handleSubmit = async () => {
    const invalidDay = Object.keys(businessHourData?.businessDays || {}).find((day) => {
      const { hourStartTime, hourEndTime, minuteStartTime, minuteEndTime, isOpen } = businessHourData?.businessDays[day];
      console.log("Start>>>", hourStartTime, hourEndTime, minuteStartTime, minuteEndTime, isOpen);
      if (isOpen && (!hourStartTime || !hourEndTime || !minuteStartTime || !minuteEndTime || hourStartTime === "00" || hourEndTime === "00")) {
        return true;
      }
      const startMinutes = parseInt(hourStartTime, 10) * 60 + parseInt(minuteStartTime, 10);
      const endMinutes = parseInt(hourEndTime, 10) * 60 + parseInt(minuteEndTime, 10);
      if (startMinutes >= endMinutes) {
        console.log("Start-time", startMinutes, "end-time", endMinutes);
        return true;
      }
      return false;
    });

    if (invalidDay) {
      message.warning("Please provide valid start and end times for all open business days.");
      return;
    }

    if (!businessHourData?.timeZone) {
      message.warning("Please select a TimeZone.");
      return;
    }

    if (!businessHourData?.businessDays || Object.keys(businessHourData?.businessDays).length === 0) {
      message.warning("Please provide Business Days Timing.");
      return;
    }
    // const businessHourModel = {
    //   isCalendarHours: businessHourData?.isCalendarHours,
    //   timeZone: businessHourData?.timeZone,
    //   businessHourId: businessHourData?.businessHourId,
    //   businessDays: Object.keys(businessHourData?.businessDays)?.map((day, index) => ({
    //     businessDay: index.toString(),
    //     hourStartTime: businessHourData?.businessDays[day].hourStartTime || '00',
    //     hourEndTime: businessHourData?.businessDays[day].hourEndTime || '00',
    //     minuteStartTime: businessHourData?.businessDays[day].minuteStartTime || '00',
    //     minuteEndTime: businessHourData?.businessDays[day].minuteEndTime || '00',
    //     isOpen: businessHourData?.businessDays[day].isOpen
    //   }))
    // };
    // console.log(businessHourModel, "payload");
    // if (!businessHourData?.businessHourId) {
    setBusinessHourData((prevState) => ({
      ...prevState,

      apply: true,
    }));
    //   message.success("Applied Successfully")
    // }

    message.success("Applied successfully");
  };

  const onChange = (e) => {
    setBusinessHourData((prevState) => ({
      ...prevState,
      isCalendarHours: e.target.value === "calendar",
      apply: true,
    }));
    console.log("Calendar Hours>>>", e.target.value === "calendar");
    if (e.target.value === "calendar") {
      message.success("Applied to calendar hours");
    } else {
      console.log("ELSE OF Bh");
    }
  };

  console.log("Business-Data-Check>>>", businessHourData);
  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  return (
    <>
      <div>
        <Radio.Group value={businessHourData?.isCalendarHours ? "calendar" : "custom"} onChange={(e) => onChange(e)}>
          <Radio value="calendar" className="me-5 businessHour-radio-btn" checked={businessHourData?.isCalendarHours}>
            Calendar Hours
          </Radio>
          <Radio value="custom" className="businessHour-radio-btn" checked={businessHourData?.isCalendarHours}>
            Custom
          </Radio>
        </Radio.Group>

        {!businessHourData?.isCalendarHours && (
          <>
            <div style={{ marginTop: 20 }}>
              <label className="mb-2 mt-3">Time Zone</label>
              <TimezoneSelect
                value={businessHourData?.timeZone}
                onChange={(value) =>
                  setBusinessHourData((prevState) => ({
                    ...prevState,
                    timeZone: value.value,
                  }))
                }
              />
            </div>
            <div className="mt-5">
              {businessHourData?.businessDays &&
                Object.keys(businessHourData?.businessDays).map((dayIndex) => {
                  const day = dayNames[dayIndex];
                  return (
                    <div
                      key={day}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 10,
                      }}
                    >
                      <span style={{ width: 50 }}>{day}</span>
                      <Switch
                        className="addPhoneSwitch-chatView"
                        size="small"
                        checked={businessHourData?.businessDays[dayIndex].isOpen === 1}
                        onChange={() => handleDayToggle(dayIndex)}
                      />
                      {console.log(businessHourData?.businessDays[dayIndex].hourStartTime, "check222")}
                      {businessHourData?.businessDays[dayIndex].isOpen === 1 && (
                        <>
                          <TimePicker
                            value={
                              businessHourData?.businessDays[dayIndex].hourStartTime && businessHourData?.businessDays[dayIndex].minuteStartTime
                                ? dayjs(
                                    `${businessHourData?.businessDays[dayIndex].hourStartTime}:${businessHourData?.businessDays[dayIndex].minuteStartTime}`,
                                    format
                                  )
                                : dayjs("09:00 ", format)
                            }
                            format={format}
                            style={{ marginLeft: 10, width: 110 }}
                            onChange={(time) => handleStartChange(dayIndex, time)}
                          />
                          <TimePicker
                            value={
                              businessHourData?.businessDays[dayIndex]?.hourEndTime && businessHourData?.businessDays[dayIndex]?.minuteEndTime
                                ? dayjs(
                                    `${businessHourData?.businessDays[dayIndex]?.hourEndTime || "12"}:${
                                      businessHourData?.businessDays[dayIndex]?.minuteEndTime || "00"
                                    }`,
                                    format
                                  )
                                : dayjs("17:00", format)
                            }
                            format={format}
                            style={{ marginLeft: 10, width: 110 }}
                            onChange={(time) => handleEndChange(dayIndex, time)}
                          />
                        </>
                      )}
                    </div>
                  );
                })}
            </div>
            <HButton buttonClassName="hs-fs-16 px-3 mt-2" onClick={handleSubmit} size="s">
              Apply
            </HButton>
          </>
        )}
      </div>
    </>
  );
};

export default BusinessHour;
