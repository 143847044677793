import { Device } from "@twilio/voice-sdk";
import { Avatar, Button, Col, Divider, Dropdown, Form, Row, Skeleton, Space, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import useHttp from "../../hooks/useHttp";
import { requestBrowserNotificationPermission, showIncomingCallNotification } from "../../pages/utils/CommonNotifications";
import { checkWhetherServiceIsAuthorized } from "../../pages/utils/commonUtils";
import "../assets/css/dialpad.css";
import { useVoiceContext } from "../context/VoiceContext";
import { useVoiceNumbers } from "../hooks/useVoiceNumbers";
import { voiceAgentStatus } from "../utils/VoiceUtils";
import { initPusher } from "./../../pages/utils/CommonVessels";
import AgentStatusSelect from "./call-screens/AgentStatusSelect";
import CallAnswered from "./call-screens/CallAnswered";
import CallRecordSearch from "./call-screens/CallRecordSearch";
import CallSaveAndClose from "./call-screens/CallSaveAndClose";
import ActionDropdown from "./common/ActionDropdown";
import IncomingPopUp from "./IncomingPopUp";
import NoAccess from "./info-component/NoAccess";
import VoiceCallLog from "./VoiceCallLog";
import { useVoiceAgentStatus } from "../hooks/useVoiceAgentStatus";
import CallHistoryDetails from "./call-screens/CallHistoryDetails";
import CreateLeadOrContact from "./call-screens/CreateLeadOrContact";
import { AgentStatusColours, COMPANY_NAME } from "../../constants/AppConstants";

const Dialpad = (props) => {
  const { getVoiceSavedNumbers } = useVoiceNumbers();
  let { showDialPad } = props;
  const [callNotesForm] = Form.useForm();
  const [searchValue, setSearchValue] = useState();
  const [fetchCallLogs, setFetchCallLogs] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [openCreateLeadOrContactForm, setOpenCreateLeadOrContactForm] = useState(false);
  const [twilioDevice, setTwilioDevice] = useState(null);
  const [twilioDeviceIsReady, setTwilioDeviceIsReady] = useState(false);
  const [callConnection, setCallConnection] = useState({});
  const [isStatic, setIsStatic] = useState(false);
  const [callParams, setCallParams] = useState();
  const [createModuleName, setCreateModuleName] = useState("");
  const [module, setModule] = useState("");
  const [answered, setAnswered] = useState(false);
  const [openCallDetails, setOpenCallDetails] = useState(false);
  const [callDetails, setCallDetails] = useState();
  const { fetchData } = useHttp();
  const [appProperties] = useContext(AppContext);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [callType, setCallType] = useState(null);
  const [showOfflineMessageWhileInit, setShowOfflineMessageWhileInit] = useState(false);
  const [callConnectionStatus, setCallConnectionStatus] = useState();
  const [orgNumber, setOrgNumber] = useState("");
  const [customerNumber, setCustomerNumber] = useState("");
  const [incomingCallTo, setIncomingCallTo] = useState();
  const [lastName, setLastName] = useState("");
  const [avatar, setAvatar] = useState();
  const [recordId, setRecordId] = useState("");
  const [recordLink, setRecordLink] = useState("");
  const [callNotes, setCallNotes] = useState("");
  const [totalCallDuration, setTotalCallDuration] = useState(0);
  const [isCallAnswered, setIsCallAnswered] = useState(false);
  const [callSid, setCallSid] = useState("");
  const [voiceIntegId, setVoiceIntegId] = useState("");
  const [isVoiceUser, setIsVoiceUser] = useState();
  const [restrictAccess, setRestrictAccess] = useState(false);
  const [saveAndCloseClicked, setSaveAndCloseClicked] = useState(false);
  const [callProperties, setCallProperties] = useVoiceContext();
  const [pusherChannel, setPusherChannel] = useState();

  const { getCurrentVoiceAgentStatus } = useVoiceAgentStatus();

  let leftServiceId = appProperties?.leftServiceId;
  let path = window.location.pathname;

  const avatarDropDownMenuItems = [
    {
      key: "1",
      label: (
        <>
          <div className="d-flex justify-content-between w-100">
            <div className="w-50 d-flex flex-row align-items-center cursor-default">
              <div className="callActionIcons from-number-call-icon-dialpad-avatar-menu"></div>
              <div>Phone</div>
            </div>
            <div className="w-50 d-flex flex-row" onClick={(e) => e.stopPropagation()}>
              <AgentStatusSelect
                classNameProps={"dialPadSelectors"}
                expandIcon={"agentStatusDropDownIcon"}
                setShowOfflineMessageWhileInit={setShowOfflineMessageWhileInit}
                restrictAccess={restrictAccess}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            appProperties?.controller?.getUrlParamsForRedirection(true, appProperties).then(function (params) {
              var redirectLink = "/voice/calllog?" + params;
              window.open(redirectLink);
            });
          }}
        >
          Go to {COMPANY_NAME}
        </div>
      ),
    },
  ];

  const resetCallPopup = () => {
    console.log("listenEvents :: callConnection >>>>>> reset >>>>>>>>");
    setCallType("");
    setRecordId("");
    setRecordLink("");
    setCallNotes("");
    callNotesForm.setFieldValue("callDescription", " ");
    setModule("");
    setLastName("");
    setAvatar("");
    setCallSid("");
    setCustomerNumber("");
    setTotalCallDuration(0);
    if (twilioDevice) {
      twilioDevice.destroy();
    }
    setCallConnection({});

    setCallProperties((prev) => {
      return {
        ...prev,
        callerNumber: "",
        callRecordId: "",
        callRecordModule: "",
        callType: "",
        callStatus: "idle",
      };
    });
  };

  const handleDialPadClick = () => {
    setCallProperties((prev) => ({
      ...prev,
      integId: voiceIntegId,
      isDialPadVisible: true,
    }));
  };

  const handleLastNameClick = () => {
    console.log("Inside last-name CLICK>>>>");
    appProperties?.controller?.openRecord(callProperties?.loadedSDK, recordId, module);
  };

  const initPusherCallForThisUser = (e) => {
    console.log("dedec NOTIFY_VOICE_CLIENTS handlePusherCall>>>>>>>>>>>>>>>", e);
    console.log("dedec NOTIFY_VOICE_CLIENTS handlePusherCall>>>>>> pusherChannel >>>>>>>>>", pusherChannel);
    console.log("dedec NOTIFY_VOICE_CLIENTS handlePusherCall>>>>>> !pusherChannel >>>>>>>>>", !pusherChannel);
    if (!pusherChannel) {
      console.log("dedec NOTIFY_VOICE_CLIENTS handlePusherCall>>>>>> fetchData >>>>>>>>>", fetchData);
      console.log("dedec NOTIFY_VOICE_CLIENTS handlePusherCall>>>>>> appProperties >>>>>>>>>", appProperties);

      if (appProperties?.currentUser?.osyncUserId) {
        initPusher(fetchData, appProperties, appProperties?.currentUser?.osyncUserId).then((res) => {
          console.log("dedec NOTIFY_VOICE_CLIENTS handlePusherCall>>>>>> res >>>>>>>>>", res);
          setPusherChannel(res);
        });
      }
    }
  };
  console.log("dedec NOTIFY_VOICE_CLIENTS handlePusherCall>>>>>> appProperties >>>>>>>>>", appProperties);

  useEffect(() => {
    if (appProperties?.installedApps) {
      const voiceIntegrations = appProperties?.installedApps?.filter((integration) => integration.right.serviceType === "VOICE");
      if (voiceIntegrations?.[0]?.integProps?.integId) {
        setVoiceIntegId(voiceIntegrations?.[0]?.integProps?.integId);
      }
    }
  }, [appProperties?.installedApps]);
  useEffect(() => {
    if (pusherChannel) {
      console.log("dedec >> pusherChannel DATA-RECEIVED >>>>>", pusherChannel);
      pusherChannel?.channel?.bind("NOTIFY_VOICE_CLIENTS", (data) => {
        console.log("dedec >> DATA-RECEIVED >>>>>", data);
        if (data) {
          try {
            let response = JSON.parse(data?.data);
            console.log("dedec_pusherChannel >> NOTIFY_VOICE_CLIENTS  >>>>>", response);
            if (response) {
              let contactInfo = response;
              console.log("dedec_pusherChannel >> contactInfo?.lastName ffff typeof >>>>>", typeof contactInfo);
              if (typeof contactInfo === "string") {
                contactInfo = JSON.parse(contactInfo);
              }
              console.log("dedec_pusherChannel >> contactInfo?.lastName ffff typeof >>>>>", typeof contactInfo);
              if (contactInfo) {
                console.log("dedec_pusherChannel >> contactInfo?.lastName ffff >>>>>", contactInfo.lastName);
                console.log("dedec_pusherChannel >> contactInfo?.lastName  true >>>>>", !contactInfo?.lastName);
                if (contactInfo.lastName === undefined) {
                  contactInfo = response?.[0];
                }
              }
              console.log("dedec_pusherChannel >> contactInfo  >>>>>", contactInfo);
              if (contactInfo) {
                setLastName(contactInfo?.lastName);
                setRecordId(contactInfo?.id);
                setModule(contactInfo.module);
                setAvatar(contactInfo?.avatar);
                setRecordLink(contactInfo?.recordLink);
              } else {
                setLastName("Unknown caller");
                setRecordId("");
                setModule(null);
                setAvatar(null);
                setRecordLink(null);
              }
            }
          } catch (e) {
            console.error("ERROR::::", e);
          }
        }
      });
      // return () => {
      //   pusherChannel?.channel?.unbind("NOTIFY_VOICE_CLIENTS");
      //   if (pusherChannel.dispose) {
      //     pusherChannel?.dispose();
      //   }
      // };
    }
  }, [pusherChannel]);

  useEffect(() => {
    setCallProperties((prev) => ({
      ...prev,
      integId: voiceIntegId,
      isDialPadVisible: showDialPad ?? "",
    }));
  }, [showDialPad]);

  useEffect(() => {
    if (callProperties?.callRecordId) {
      setRecordId(callProperties?.callRecordId);
    }
  }, [callProperties?.callRecordId]);
  useEffect(() => {
    if (isVoiceUser != undefined && !isVoiceUser) {
      setRestrictAccess(true);
      // setCallProperties((prev) => ({
      //   ...prev,
      //   disableAgentStatusChange: true,
      // }))
    }
  }, [isVoiceUser]);
  console.log("restrict-access", restrictAccess);
  console.log("Customer Number", customerNumber);
  useEffect(() => {
    if (callProperties?.callerNumber) {
      setCustomerNumber(callProperties?.callerNumber);
      console.log("CustomerNumbersetting success", customerNumber);
    }
  }, [callProperties?.callerNumber]);

  useEffect(() => {
    if (callProperties?.callRecordModule) {
      setModule(callProperties?.callRecordModule);
    }
  }, [callProperties?.callRecordModule]);

  useEffect(() => {
    if (
      callProperties?.agentStatusValue &&
      (callProperties?.agentStatusValue === voiceAgentStatus.OFFLINE || callProperties?.agentStatusValue === voiceAgentStatus.BUSY)
    ) {
      setShowOfflineMessageWhileInit(true);
    } else {
      setShowOfflineMessageWhileInit(false);
    }
  }, [callProperties?.agentStatusValue]);

  useEffect(() => {
    // setLastName(callProperties?.callMockData?.lastName);
    // setRecordId(callProperties?.callMockData?.recordId);
    // setRecordLink(callProperties?.callMockData?.recordLink);
    // setModule(callProperties?.callMockData?.module);
    // setAvatar(callProperties?.callMockData?.avatar);
    setCustomerNumber(callProperties?.callMockData?.phone);
    setIncomingCallTo(callProperties?.callMockData?.incomingCallTo);
  }, [callProperties?.callMockData]);

  useEffect(() => {
    const fetchSavedNumbers = async () => {
      try {
        if (!voiceIntegId) return;
        const responseData = await getVoiceSavedNumbers("private");
        const numbers = responseData?.map((item) => ({
          value: item.phoneNumber,
          label: item.phoneNumber,
        }));
        setPhoneNumbers(numbers);
        if (Array.isArray(responseData) && responseData.length > 0) {
          setOrgNumber(responseData[0].phoneNumber);
        }
      } catch (error) {
        console.error("Error fetching saved numbers:", error);
      }
    };

    if (path === "/voice/dialpad") {
      setIsStatic(true);
    }

    fetchSavedNumbers();
  }, [appProperties, voiceIntegId, fetchData]);

  useEffect(() => {
    requestBrowserNotificationPermission();
  }, []);

  const deviceOptions = {
    closeProtection: "true",
  };

  const endCall = () => {
    setCallEnded(true);
    setCallNotes(callNotesForm.getFieldValue("callDescription"));
    try {
      if (twilioDevice && typeof twilioDevice.disconnectAll === "function") {
        twilioDevice.disconnectAll();
        handleStatusChange(voiceAgentStatus.AFTER_CALL_PROCESS);
      }
      if (callConnection) {
        callConnection.disconnect();
      }
    } catch (error) {
      console.error("Error disconnecting call:", error);
    }
  };

  const hangUpCall = () => {
    try {
      if (twilioDevice && typeof twilioDevice.disconnectAll === "function") {
        twilioDevice.disconnectAll();
        // handleStatusChange(voiceAgentStatus.ONLINE_WEB);
        resetCallPopup();
      }
      if (callConnection) {
        callConnection.disconnect();
      }
    } catch (error) {}
  };
  console.log("Call-Properties>>", callProperties);

  useEffect(() => {
    console.log("isDialPadVisible appProperties.userId >>>>>>>>>>>>>>>>>>", appProperties.userId);
    if (appProperties.userId && appProperties.userId !== undefined) {
      getCurrentVoiceAgentStatus().then((response) => {
        const isVoiceUser = response?.isVoiceUser;
        setIsVoiceUser(isVoiceUser === "true");
        if (isVoiceUser) {
          const statusValue = response?.agentStatus?.voiceAgentStatus;
          if (statusValue && statusValue !== undefined) {
            console.log("tukzer_unit Value--check>>>", statusValue, voiceAgentStatus.BUSY);
            if (statusValue === 4 || statusValue === 5 || statusValue === 3) {
              setCallProperties((prev) => ({
                ...prev,
                agentStatusValue: voiceAgentStatus.BUSY,
              }));
              console.log("agents-status-value-util");
            } else {
              setCallProperties((prev) => ({
                ...prev,
                agentStatusValue: statusValue,
              }));
            }

            if (callProperties?.serviceInitialized && statusValue === voiceAgentStatus.OFFLINE) {
              if (callProperties?.loadedSDK) {
                appProperties.controller.showCallNotification(callProperties?.loadedSDK, "offline");
                setCallProperties((prev) => ({
                  ...prev,
                  serviceInitialized: false,
                }));
              }
            } else {
              if (
                callProperties?.agentStatusValue &&
                (callProperties?.agentStatusValue === voiceAgentStatus.OFFLINE || callProperties?.agentStatusValue === voiceAgentStatus.BUSY)
              ) {
                setShowOfflineMessageWhileInit(true);
              }
            }
          }
        }
      });
    }
  }, [appProperties.userId, callProperties?.isDialPadVisible]);

  useEffect(() => {
    if (callProperties?.isDialPadVisible) {
      appProperties?.controller?.showCallNotification(callProperties?.loadedSDK, "offline");
    } else {
      appProperties?.controller?.hideCallNotification(callProperties?.loadedSDK);
    }
  }, [callProperties?.isDialPadVisible]);

  // console.log("listenEvents :: makeOutboundCall_auto callType dkdkd>>>>>>>", callType);
  // console.log("listenEvents :: makeOutboundCall_auto callProperties?.callType dkdkd>>>>>>>", callProperties?.callType);
  // useEffect(() => {
  //   if (callProperties?.agentStatusValue === voiceAgentStatus.ONLINE_WEB) {
  //     if (customerNumber && phoneNumbers.length === 1 && callType === "outbound" && callProperties?.callStatus === "idle") {
  //       console.log("Make-outbound-call>>>>>");
  //       setCallProperties((prev) => ({
  //         ...prev,
  //         callStatus: "ringing",
  //         recordId: recordId,
  //       }));
  //       makeOutboundCall();
  //     } else if (customerNumber && phoneNumbers.length > 1 && callType === "outbound") {
  //       setCallProperties((prev) => ({
  //         ...prev,
  //         callStatus: "idle",
  //       }));
  //     }
  //   }
  // }, [callType, callProperties?.callType]);

  useEffect(() => {
    console.log("listenEvents :: makeOutboundCall_auto customerNumber dkdkd>>>>>>>", customerNumber);
    console.log("listenEvents :: makeOutboundCall_auto phoneNumbers.length dkdkd>>>>>>>", phoneNumbers.length);
    console.log("listenEvents :: makeOutboundCall_auto callType11 dkdkd>>>>>>>", callType);
    console.log("listenEvents :: makeOutboundCall_auto callProperties?.callStatus dkdkd>>>>>>>", callProperties?.callStatus);
    console.log("listenEvents :: makeOutboundCall_auto callProperties?.agentStatusValue dkdkd>>>>>>>", callProperties?.agentStatusValue);
    console.log("listenEvents :: makeOutboundCall_auto callProperties dkdkd>>>>>>>", callProperties);
    console.log("listenEvents :: makeOutboundCall_auto recordId dkdkd>>>>>>>", recordId);

    const isOnlineAndReady =
      callProperties?.agentStatusValue === voiceAgentStatus.ONLINE_WEB &&
      callProperties?.callStatus === "idle" &&
      callType === "outbound" &&
      customerNumber;

    console.log("listenEvents :: makeOutboundCall_auto isOnlineAndReady dkdkd>>>>>>>", isOnlineAndReady);
    if (isOnlineAndReady) {
      if (phoneNumbers.length === 1) {
        console.log("Make-outbound-call>>>>>");
        setCallProperties((prev) => ({
          ...prev,
          callStatus: "ringing",
          recordId: recordId,
        }));
        makeOutboundCall();
      } else {
        setCallProperties((prev) => ({ ...prev, callStatus: "idle" }));
      }
    }
  }, [customerNumber]);

  useEffect(() => {
    console.log("listenEvents :: makeOutboundCall_auto setCallType before>>>>>>>", callProperties.callType);
    if (callProperties?.callType) {
      console.log("listenEvents :: makeOutboundCall_auto setCallType >>>>>>>", callProperties?.callType);
      setCallType(callProperties?.callType);
    }
  }, [callProperties.callType]);

  const registerTwilio = () => {
    return new Promise((resolve, reject) => {
      try {
        console.log("registerTwilio ------ voiceIntegId", voiceIntegId);

        const isServiceAuthorized = checkWhetherServiceIsAuthorized(appProperties, voiceIntegId, null, false);

        console.log("registerTwilio ------ isServiceAuthorized", isServiceAuthorized);

        if (!voiceIntegId || !isServiceAuthorized) {
          // reject(new Error("Service not authorized or missing voice integration ID"));
          return;
        }

        fetchData(`voice/${voiceIntegId}/register`, "POST", {}, appProperties)
          .then((response) => {
            console.log("registerTwilio ------ response", response);

            const parsedResponse = typeof response === "string" ? JSON.parse(response) : response;

            const token = parsedResponse?.data?.token;

            if (!token || typeof token !== "string") {
              handleStatusChange(voiceAgentStatus.OFFLINE);
              throw new Error("Invalid or missing token");
            }

            // Update state
            setCallProperties((prev) => ({
              ...prev,
              twilioCapToken: token,
            }));

            initPusherCallForThisUser();

            resolve(token);
          })
          .catch((error) => {
            console.error("Twilio registration failed:", error);
            handleStatusChange(voiceAgentStatus.OFFLINE);
            reject(error);
          });
      } catch (error) {
        console.error("Unexpected error in registerTwilio:", error);
        reject(error);
      }
    });
  };

  // useEffect(() => {
  //   try {
  //     console.log("Token-----UseEffect---");
  //     if (callProperties?.twilioCapToken) {
  //       const deviceInstance = new Device(callProperties?.twilioCapToken, deviceOptions);
  //       setTwilioDevice(deviceInstance);

  //       const handleSuccessfulRegistration = () => {
  //         console.log("The device is ready to receive incoming calls.");
  //         setTwilioDeviceIsReady(true);
  //       };

  //       deviceInstance.on("registered", handleSuccessfulRegistration);
  //       deviceInstance.register();

  //       deviceInstance.on("incoming", (call) => {
  //         console.log("Incoming call >>>>>>>>>>>>>>>>>", call);
  //         setCallConnection(call);
  //         setCallParams(call);
  //         const from = call.parameters.From;
  //         showIncomingCallNotification("Incoming Call", `You have an incoming call from ${from}`);

  //         call.on("accept", (e) => {
  //           setIsCallAnswered(true);
  //           console.log("Twilio-Event ::: Call answered.");
  //           setCallProperties((prev) => ({
  //             ...prev,
  //             disableAgentStatusChange: true,
  //           }));
  //         });

  //         call.on("disconnect", (e) => {
  //           console.log("Twilio-Event ::: Call has ended", e);
  //           setCallConnection({});
  //           setCallProperties((prev) => ({
  //             ...prev,
  //             disableAgentStatusChange: false,
  //           }));
  //         });

  //         call.on("cancel", (e) => {
  //           console.log("Twilio-Event ::: The incoming call was canceled.", call.status());
  //           if (!isCallAnswered && callProperties?.loadedSDK) {
  //             appProperties?.controller?.disableFocusMode(callProperties.loadedSDK);
  //             appProperties?.controller?.hideCallNotification(callProperties.loadedSDK);
  //             console.log("Inside cancel");
  //             setCallProperties((prev) => ({
  //               ...prev,
  //               disableAgentStatusChange: false,
  //               isDialPadVisible: false,
  //               callStatus: "idle",
  //               incomingCall: false,
  //             }));

  //             setCustomerNumber("");
  //           }
  //         });
  //       });

  //       let isRefreshing = false;
  //       let lastRefreshAttempt = 0;
  //       const REFRESH_COOLDOWN = 5000; // 5 seconds between refresh attempts

  //       deviceInstance.on("error", (error) => {
  //         console.log("Twilio-Event ::: Call Error>>>>>>", error);
  //         setTwilioDeviceIsReady(false);
  //         handleStatusChange(voiceAgentStatus.OFFLINE);
  //         if (error.message.includes("AccessTokenExpired")) {
  //           const currentTime = Date.now();

  //           // Check if we're already refreshing or if cooldown period hasn't passed
  //           if (!isRefreshing && currentTime - lastRefreshAttempt > REFRESH_COOLDOWN) {
  //             console.warn("Access Token expired. Attempting to re-register...");

  //             isRefreshing = true;
  //             lastRefreshAttempt = currentTime;

  //             registerTwilio()
  //               .then(() => {
  //                 isRefreshing = false;
  //               })
  //               .catch((refreshError) => {
  //                 console.error("Token refresh failed", refreshError);
  //                 isRefreshing = false;
  //               });
  //           }
  //         } else {
  //           console.error("Device error:", error);
  //         }
  //       });

  //       // deviceInstance.on("error", (error) => {
  //       //   console.log("Twilio-Event ::: Call Error>>>>>>", error);
  //       //   setTwilioDeviceIsReady(false);
  //       //   if (error.message.includes("AccessTokenExpired")) {
  //       //     console.warn("Access Token expired. Attempting to re-register...");
  //       //     registerTwilio();
  //       //   } else {
  //       //     console.error("Device error:", error);
  //       //   }
  //       // });

  //       deviceInstance.on("tokenWillExpire", async () => {
  //         console.log("Twilio-Event ::: Token will expire. Re-registering...");
  //         setTwilioDeviceIsReady(false);
  //         await registerTwilio();
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error in Twilio device setup:", error);
  //   }
  // }, [callProperties?.twilioCapToken]);

  useEffect(() => {
    let deviceInstance = null;

    const setupTwilioDevice = async () => {
      try {
        if (!callProperties?.twilioCapToken) return;

        // Create Device Instance
        deviceInstance = new Device(callProperties.twilioCapToken, {
          // Your device options
        });

        console.log("device-instance------yhahhhh", deviceInstance);
        setTwilioDevice(deviceInstance);

        // Device Registration Handler
        const handleDeviceRegistration = () => {
          console.log("Twilio-Event ::: Device ready to receive calls >>>>>>");
          setTwilioDeviceIsReady(true);
        };

        // Incoming Call Handler
        const handleIncomingCall = (call) => {
          console.log("Twilio-Event ::: Incoming Call >>>>>>", call);
          setCallConnection(call);
          setOpenCallDetails(false);

          const from = call.parameters.From;
          showIncomingCallNotification("Incoming Call", `Call from ${from}`);

          // Call Event Listeners
          setupCallEventListeners(call);
        };

        // Error Handling
        const handleDeviceError = async (error) => {
          console.log("Twilio-Event ::: Call Error>>>>>>", error);
          message.error("Something went wrong while connecting to Twilio. Check your network connection and try again.");
          setTwilioDeviceIsReady(false);
          handleStatusChange(voiceAgentStatus.OFFLINE);
          if (error?.message.includes("AccessTokenExpired")) {
            await handleTokenExpiration();
          }
        };

        // Token Expiration Handler
        const handleTokenExpiration = async (error) => {
          try {
            console.log("Twilio-Event ::: Token will expire. Re-registering...", error);
            await registerTwilio();
          } catch (refreshError) {
            console.error("Token refresh failed", refreshError);
          }
        };

        // Event Listeners
        deviceInstance.on("registered", handleDeviceRegistration);
        deviceInstance.on("incoming", handleIncomingCall);
        deviceInstance.on("error", handleDeviceError);
        deviceInstance.on("tokenWillExpire", handleTokenExpiration);

        // Register Device
        deviceInstance
          .register()
          .then(() => {
            console.log("Twilio-Event ::: Device successfully registered");
          })
          .catch((error) => {
            // handleDeviceError(error);
          });
      } catch (error) {
        console.error("Twilio device setup error:", error);
      }
    };

    // Call Setup Function
    const setupCallEventListeners = (call) => {
      call.on("accept", () => {
        console.log("Twilio-Event ::: Call accepted successfully");
        setIsCallAnswered(true);
        setCallProperties((prev) => ({
          ...prev,
          disableAgentStatusChange: true,
        }));
      });

      call.on("disconnect", (e) => {
        console.log("Twilio-Event ::: Call has ended", e);
        setCallConnection({});
        setCallProperties((prev) => ({
          ...prev,
          disableAgentStatusChange: false,
        }));
        setCallEnded(true);
      });

      call.on("cancel", () => {
        console.log("Twilio-Event ::: The incoming call was canceled.", call.status());
        handleCallCancellation(call);
        setCallEnded(true);
      });
    };

    // Call Cancellation Handler
    const handleCallCancellation = (call) => {
      if (!isCallAnswered && callProperties?.loadedSDK) {
        appProperties?.controller?.disableFocusMode(callProperties.loadedSDK);
        appProperties?.controller?.hideCallNotification(callProperties.loadedSDK);

        setCallProperties((prev) => ({
          ...prev,
          disableAgentStatusChange: false,
          isDialPadVisible: false,
          callStatus: "idle",
          incomingCall: false,
        }));

        setCustomerNumber("");
      }
    };

    // Trigger Device Setup
    setupTwilioDevice();

    // Cleanup Function
    return () => {
      if (deviceInstance) {
        deviceInstance.destroy();
      }
    };
  }, [callProperties?.twilioCapToken]);

  console.log("Notification", Notification.permission);

  useEffect(() => {
    console.log("callConnection >>>>>>>>>>>>>>", callConnection);
    if (callConnection && Object.keys(callConnection).length > 0) {
      console.log("listenEvents :: callConnection >>>>>> inbound >>>>>>>>", callConnection);
      setCallType("inbound");
      if (callConnection?._status) {
        setCallConnectionStatus(callConnection?._status);
      }
      let phone = callConnection?.parameters?.From;
      let incomingCallTo = callConnection?.parameters?.toNumber;
      setCallProperties((prev) => ({
        ...prev,
        isDialPadVisible: true,
        disableAgentStatusChange: true,
        incomingCall: true,
      }));
      setCustomerNumber(phone);
      if (incomingCallTo) {
        setIncomingCallTo(incomingCallTo);
      }
      let customParameters = callConnection?.customParameters;

      if (callProperties?.loadedSDK) {
        appProperties.controller.showCallNotification(callProperties?.loadedSDK, "inbound");
      }

      const dataArray = Array.from(customParameters);

      for (var i = 0; i < dataArray.length; i++) {
        let paramsArray = dataArray[i];

        let paramKey = paramsArray[0];
        let paramValue = paramsArray[1];
        console.log("Param>>>", paramValue);
        if (paramKey === "name") {
          setLastName(paramValue);
        } else if (paramKey === "module") {
          setModule(paramValue);
        } else if (paramKey === "recordId") {
          setRecordId(paramValue);
        } else if (paramKey === "avatar") {
          setAvatar(paramValue);
        } else if (paramKey === "incomingCallTo") {
          setIncomingCallTo(paramValue);
        } else if (paramKey === "callSid") {
          setCallSid(paramValue);
        }
      }
    }
  }, [callConnection]);

  useEffect(() => {
    if (callConnection?._status === "closed") {
      setCallProperties((prev) => ({
        ...prev,
        disableAgentStatusChange: false,
        isDialPadVisible: false,
        callStatus: "idle",
        callType: "",
        incomingCall: false,
      }));
      resetCallPopup();
      setCustomerNumber("");
    }
  }, [callConnection?._status]);

  useEffect(() => {
    if (!twilioDevice && callProperties?.agentStatusValue === voiceAgentStatus.ONLINE_WEB && !twilioDeviceIsReady) {
      registerTwilio();
      console.log("Register-browser-->>>");
    }
  }, [voiceIntegId, twilioDevice, callProperties?.agentStatusValue]);

  console.log("twilioDevice", twilioDevice);

  const makeOutboundCall = async (searchValue) => {
    console.log("Outbound call>>>", customerNumber, ">>", orgNumber);
    if (!twilioDevice) {
      console.error("Twilio device is not available.");
      return;
    }
    setSearchValue(searchValue);
    const targetNumber = customerNumber || searchValue;

    if (!targetNumber || !orgNumber) {
      console.error("Missing required parameters: targetNumber or orgNumber.");
      return;
    }
    if (targetNumber && orgNumber) {
      console.log("listenEvents :: callConnection >>>>>> outbound >>>>>>>>", customerNumber);
      setCallType("outbound");
      setIncomingCallTo(orgNumber);
      const params = {
        To: targetNumber,
        From: orgNumber,
        leftServiceId: appProperties?.leftServiceId,
        callRecordId: recordId,
        callRecordModule: module,
        calledBy: appProperties?.currentUser?.osyncUserId,
      };
      console.log("Call parameters: ", params);

      const deviceParams = {
        params: params,
      };
      try {
        const call = await twilioDevice.connect(deviceParams);
        setCallParams(call);
        setCallSid(call.parameters);

        call.on("ringing", () => {
          console.log("Twilio-Event-Call ::: Call is ringing...", call.status());
          setCallProperties((prev) => ({
            ...prev,
            disableAgentStatusChange: true,
          }));
          if (callProperties?.loadedSDK) {
            appProperties?.controller?.enableFocusMode(callProperties?.loadedSDK);
          }
          console.log("The call is ringing...", call.status());

          setCallProperties((prev) => ({
            ...prev,
            callStatus: "ringing",
          }));
        });

        call.on("accept", (e) => {
          console.log("Twilio-Event-Call ::: Call accpeted...", call.status(), "::: call sid >>>>>>", call.parameters.CallSid);
          handleStatusChange(voiceAgentStatus.ON_CALL);
          setCallProperties((prev) => ({
            ...prev,
            callStatus: "answered",
          }));
        });

        call.on("rejected", () => {
          console.log("Twilio-Event-Call ::: Call rejected...", call.status(), "::: call params >>", call.parameters);
          setCallProperties((prev) => ({
            ...prev,
            callStatus: "save-close",
          }));
          handleStatusChange(voiceAgentStatus.AFTER_CALL_PROCESS);
        });

        call.on("disconnect", () => {
          console.log("Twilio-Event-Call ::: Call disconnected...", call.status(), "::: call params >>", call.parameters);
          // setCallProperties((prev) => ({
          //   ...prev,
          //   callStatus: "save-close",
          // }));
          // handleStatusChange(voiceAgentStatus.AFTER_CALL_PROCESS);
          setCallEnded(true);
        });

        call.on("cancel", () => {
          console.log("Twilio-Event-Call ::: Call canceled...", call.status(), "::: call params >>", call.parameters);
          setCallProperties((prev) => ({
            ...prev,
            callStatus: "save-close",
          }));
        });

        call.on("ignore", () => {
          console.log("Twilio-Event-Call ::: Call ignored...", call.status(), "::: call params >>", call.parameters);
          setCallProperties((prev) => ({
            ...prev,
            callStatus: "save-close",
          }));
        });
      } catch (error) {
        console.error("Error connecting the call:", error);
      }
    } else {
      console.log("Phone number is not entered.");
      message.warning("Please Enter valid Mobile Number!");
    }
  };

  const handleStatusChange = (value) => {
    return new Promise((resolve, reject) => {
      if (leftServiceId && appProperties?.userId) {
        fetchData(
          `voice/${voiceIntegId}/agents/${appProperties.userId}?status=${value}&leftServiceId=${leftServiceId}`,
          "PUT",
          { payload: "" },
          appProperties
        )
          .then((response) => {
            const statusValueResponse = JSON.parse(response);
            const statusValue = statusValueResponse?.data?.voiceAgentStatusEntity?.voiceAgentStatus || voiceAgentStatus.OFFLINE;

            if (statusValue === voiceAgentStatus.ON_CALL || statusValue === voiceAgentStatus.AFTER_CALL_PROCESS) {
              setCallProperties((prev) => ({
                ...prev,
                agentStatusValue: voiceAgentStatus.BUSY,
              }));
            } else {
              setCallProperties((prev) => ({
                ...prev,
                agentStatusValue: statusValue,
              }));
            }
            console.log("current-status >>>>>>>>>>>>  ", statusValue);

            if (statusValue === voiceAgentStatus.ONLINE_WEB) {
              setShowOfflineMessageWhileInit(false);
            }
            const token = statusValueResponse?.data?.capToken;
            if (token) {
              console.log(token, "CAPTOKEN>>>");
              setCallProperties((prev) => ({
                ...prev,
                twilioCapToken: token,
              }));
            }
            resolve(statusValueResponse);
          })
          .catch((error) => {
            console.error("Error updating status:", error);
            reject(error);
          });
      } else {
        reject(new Error("Missing leftServiceId or userId."));
      }
    });
  };

  const rejectCall = () => {
    if (callProperties?.loadedSDK) {
      appProperties.controller.disableFocusMode(callProperties?.loadedSDK);
    }
    if (callConnection) {
      if (callConnection?.status() === "pending") {
        setCallProperties((prev) => ({
          ...prev,
          isDialPadVisible: false,
          initiateOutboundCall: false,
          disableAgentStatusChange: false,
          callStatus: "idle",
          incomingCall: false,
        }));
        setCustomerNumber("");
        setCallConnection({});
        setCallConnection({});
        callConnection?.reject();
        callConnection.disconnect();
      } else {
        callConnection.disconnect();
      }
    } else {
      setCallProperties((prev) => ({
        ...prev,
        callStatus: "idle",
        incomingCall: false,
      }));
    }
  };
  const answerCall = () => {
    console.log("Attempting to answer the call...");
    if (callConnection && Object.keys(callConnection).length > 0) {
      if (callConnection.status() === "pending") {
        handleStatusChange(voiceAgentStatus.ON_CALL)
          .then((response) => {
            console.log("Call status changed to ON_CALL.");
            setCallProperties((prev) => ({
              ...prev,
              callStatus: "answered",
              incomingCall: false,
            }));
            callConnection.accept();
            appProperties?.controller?.enableFocusMode(callProperties?.loadedSDK);
            console.log("Call answered successfully.");
            setAnswered(true);
          })
          .catch((error) => {
            console.error("Error changing call status:", error);
          });
      } else {
        console.log("Call is not in pending state. Cannot answer.");
      }
    } else {
      console.warn("No valid call connection found.");
    }
  };

  console.log("Call-Notes-Parent>>>", callNotes);
  console.log("openCallDetails>>>>", openCallDetails);
  const handleDialPadClose = () => {
    setCallProperties((prev) => ({
      ...prev,
      isDialPadVisible: false,
    }));
  };
  const handleSaveAndCloseClick = (notes) => {
    try {
      setSaveAndCloseClicked(true);
      if (callSid || callSid?.CallSid) {
        console.log("dedec Call-Sid-state>>", callSid, callSid?.CallSid);
        const payload = {
          callSid: callSid?.CallSid ?? callSid,
          notes: callNotesForm.getFieldValue("callDescription"),
          action: "saveAndClose",
        };

        fetchData(`voice/log?integId=${voiceIntegId}`, "POST", payload, appProperties)
          .then((response) => {
            console.log("dedec Logs>>>", response);

            if (response) {
              setFetchCallLogs(true);
              setCallProperties((prev) => ({
                ...prev,
                isDialPadVisible: false,
                disableAgentStatusChange: false,
                callStatus: "idle",
              }));

              resetCallPopup();

              if (callProperties?.loadedSDK) {
                appProperties.controller.disableFocusMode(callProperties.loadedSDK);
                appProperties.controller.hideCallNotification(callProperties.loadedSDK);
              }
              setSaveAndCloseClicked(false);
            }

            return handleStatusChange(voiceAgentStatus.ONLINE_WEB);
          })
          .catch((error) => {
            console.error("Error during fetch or status change:", error);
            setSaveAndCloseClicked(false);
          });
      } else {
        console.warn("No Call SID provided.");
        setSaveAndCloseClicked(false);
      }
    } catch (error) {
      console.error("Error Updating Log:", error);
      setSaveAndCloseClicked(false);
    }
  };

  console.log("Call--Status>>>>>", callProperties?.callStatus);

  const navigationDropdown = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            appProperties?.controller?.getUrlParamsForRedirection(true, appProperties).then(function (params) {
              var redirectLink = "/voice/calllog?" + params;
              window.open(redirectLink);
            });
          }}
        >
          HelloSend
        </div>
      ),
    },
  ];

  return (
    <>
      {!isStatic && !path.includes("inbox") && (
        <div className="hsLandlineIcon" onClick={handleDialPadClick} style={{ position: "absolute", left: "92%", top: "85%", zIndex: 60 }} />
      )}

      <div
        className={`${isStatic ? "staticDialpad" : `${callProperties?.isDialPadVisible ? "visible" : ""} dialPad`} ${
          openCallDetails ? "" : "overflow-hidden"
        } `}
      >
        <div className="statusBar hs-h-10 d-flex align-items-middle overflow-hidden">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <div className=" actionIconsSprite dial-pad-hello-send-logo w-100 h-100" alt="HelloSend"></div>

            <>
              <div className="d-flex align-items-center justify-content-between w-50">
                <div className="d-flex align-items-center w-100 justify-content-end">
                  <div className="d-flex justify-content-end"></div>
                </div>
                <div className="d-flex align-items-center w-100 justify-content-end">
                  <Row className="d-flex align-items-center w-100 justify-content-between">
                    <Col span={8} className="cursor-pointer" onClick={handleDialPadClose}>
                      <div className={`${callProperties?.disableAgentStatusChange ? "disable-minimize dialCloseIcon" : "dialCloseIcon"}`}></div>
                    </Col>
                    <Col span={16} className="d-flex align-items-center w-100 justify-content-end cursor-pointer">
                      <Dropdown
                        menu={{ items: avatarDropDownMenuItems }}
                        dropdownRender={(menu) => <div className="create-record-link-parent">{menu}</div>}
                        trigger={["click"]}
                      >
                        <Button
                          className="border-none boxShadowRemove  d-flex justify-content-center h-100 p-0"
                          style={{
                            background: "none",
                          }}
                        >
                          <Space className="profileAvatarBg h-100 w-100">
                            <Avatar
                              style={{
                                backgroundColor: "#d5fce3",
                                color: "black",
                                verticalAlign: "middle",
                                border: "1px solid #d5fce3",
                                width: "35px",
                                height: "35px",
                              }}
                              size="large"
                              className="manageSubscription"
                              src={appProperties?.userLogo}
                              border="light"
                            >
                              {appProperties?.userLogo}
                            </Avatar>
                            <div
                              style={{
                                position: "absolute",
                                left: "75%",
                                transform: "translateX(-50%)",
                                width: "13px",
                                height: "13px",
                                borderRadius: "50%",
                                backgroundColor: AgentStatusColours[callProperties?.agentStatusValue],
                                border: "1px solid white",
                                top: "67%",
                              }}
                            ></div>
                          </Space>
                        </Button>
                      </Dropdown>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
            {/* <div className="profilePic ms-3 mt-1">
              <Avatar
                style={{
                  backgroundColor: "#d5fce3",
                  color: "black",
                  verticalAlign: "middle",
                }}
                size="large"
                className="manageSubscription"
                src={appProperties?.userLogo}
                border="light"
              >
                {appProperties?.userLogo}
              </Avatar>
            </div> */}
          </div>
        </div>
        {console.log(
          "Twilio-Event - Dialpad - let { customerNumber, module, recordId, appProperties, recordLink, skeletonField } = props;?.callStatus >>>>>>>>>>>>>>>>",
          callProperties?.callStatus
        )}
        {openCreateLeadOrContactForm ? (
          <CreateLeadOrContact
            module={module}
            recordId={recordId}
            setOpenCreateLeadOrContactForm={setOpenCreateLeadOrContactForm}
            createModuleName={createModuleName}
            customerNumber={customerNumber}
            searchValue={searchValue}
            setRecordId={setRecordId}
            setCustomerNumber={setCustomerNumber}
            setCreateModuleName={setCreateModuleName}
            setModule={setModule}
            setRecordLink={setRecordLink}
            setLastName={setLastName}
            callSid={callSid?.CallSid ?? callSid}
          />
        ) : (
          // : openCallDetails ? (
          //   <CallHistoryDetails callDetails={callDetails} setOpenCallDetails={setOpenCallDetails} setIdleSelectedMenu={setIdleSelectedMenu} />
          // )
          <>
            {callProperties?.incomingCall ? (
              <IncomingPopUp
                callerImageComponent={
                  <CallPulseImage pulse={true} image={avatar} lastName={lastName} customerNumber={customerNumber} searchValue={searchValue} />
                }
                customerNumber={customerNumber}
                lastName={lastName}
                avatar={avatar}
                hangUpCall={hangUpCall}
                module={module}
                recordId={recordId}
                searchValue={searchValue}
                answered={answered}
                answerCall={answerCall}
                rejectCall={rejectCall}
                handleLastNameClick={handleLastNameClick}
                appProperties={appProperties}
                recordLink={recordLink}
                incomingCallTo={incomingCallTo}
                incomingCall={true}
                skeletonImage={<SkeletonImage />}
                skeletonField={<SkeletonField />}
                callProperties={callProperties}
                customerInfoDetails={
                  <CustomerDetails
                    setRecordId={setRecordId}
                    customerNumber={customerNumber}
                    setCreateModuleName={setCreateModuleName}
                    module={module}
                    recordId={recordId}
                    appProperties={appProperties}
                    recordLink={recordLink}
                    skeletonField={<SkeletonField />}
                    openCreateLeadOrContactForm={openCreateLeadOrContactForm}
                    setOpenCreateLeadOrContactForm={setOpenCreateLeadOrContactForm}
                    searchValue={searchValue}
                  />
                }
              />
            ) : callProperties?.callStatus === "idle" ? (
              // <CallDefaultPage
              //   callStatus={callProperties?.callStatus}
              //   makeOutboundCall={makeOutboundCall}
              //   phoneNumbers={phoneNumbers}
              //   orgNumber = {orgNumber}
              // />

              <>
                {restrictAccess ? (
                  <>
                    <NoAccess handleStatusChange={handleStatusChange} setShowOfflineMessageWhileInit={setShowOfflineMessageWhileInit} />
                  </>
                ) : (
                  <CallRecordSearch
                    setFetchCallLogs={setFetchCallLogs}
                    fetchCallLogs={fetchCallLogs}
                    setOpenCallDetails={setOpenCallDetails}
                    openCallDetails={openCallDetails}
                    callDetails={callDetails}
                    setCallDetails={setCallDetails}
                    orgNumber={orgNumber}
                    setCustomerNumber={setCustomerNumber}
                    makeOutboundCall={makeOutboundCall}
                    phoneNumbers={phoneNumbers}
                    appProperties={appProperties}
                    setLastName={setLastName}
                    setModule={setModule}
                    setRecordId={setRecordId}
                    setAvatar={setAvatar}
                    setRecordLink={setRecordLink}
                    voiceIntegId={voiceIntegId}
                    setOrgNumber={setOrgNumber}
                    showOfflineMessageWhileInit={showOfflineMessageWhileInit}
                    setShowOfflineMessageWhileInit={setShowOfflineMessageWhileInit}
                    handleStatusChange={handleStatusChange}
                    isVoiceUser={isVoiceUser}
                  />
                )}
              </>
            ) : callProperties?.callStatus === "ringing" ? (
              <IncomingPopUp
                callerImageComponent={
                  <CallPulseImage pulse={true} image={avatar} lastName={lastName} customerNumber={customerNumber} searchValue={searchValue} />
                }
                customerNumber={customerNumber}
                avatar={avatar}
                endCall={endCall}
                searchValue={searchValue}
                hangUpCall={hangUpCall}
                module={module}
                appProperties={appProperties}
                recordLink={recordLink}
                lastName={lastName}
                recordId={recordId}
                incomingCallTo={incomingCallTo}
                incomingCall={false}
                skeletonImage={<SkeletonImage />}
                skeletonField={<SkeletonField />}
                callProperties={callProperties}
                customerInfoDetails={
                  <CustomerDetails
                    setRecordId={setRecordId}
                    customerNumber={customerNumber}
                    setCreateModuleName={setCreateModuleName}
                    module={module}
                    recordId={recordId}
                    appProperties={appProperties}
                    recordLink={recordLink}
                    skeletonField={<SkeletonField />}
                    openCreateLeadOrContactForm={openCreateLeadOrContactForm}
                    setOpenCreateLeadOrContactForm={setOpenCreateLeadOrContactForm}
                    searchValue={searchValue}
                  />
                }
              />
            ) : callProperties?.callStatus === "answered" ? (
              <CallAnswered
                callNotesFormName={callNotesForm}
                callerImageComponent={
                  <CallPulseImage pulse={false} image={avatar} lastName={lastName} customerNumber={customerNumber} searchValue={searchValue} />
                }
                lastName={lastName}
                avatar={avatar}
                recordId={recordId}
                recordLink={recordLink}
                setTotalCallDuration={setTotalCallDuration}
                totalCallDuration={totalCallDuration}
                searchValue={searchValue}
                endCall={endCall}
                module={module}
                setCallEnded={setCallEnded}
                callEnded={callEnded}
                callNotes={callNotes}
                setCallNotes={setCallNotes}
                callObject={callParams}
                device={twilioDevice}
                callStatus={callProperties?.callStatus}
                appProperties={appProperties}
                customerNumber={customerNumber}
                skeletonImage={<SkeletonImage />}
                skeletonField={<SkeletonField />}
                callProperties={callProperties}
                customerInfoDetails={
                  <CustomerDetails
                    setRecordId={setRecordId}
                    customerNumber={customerNumber}
                    setCreateModuleName={setCreateModuleName}
                    module={module}
                    recordId={recordId}
                    appProperties={appProperties}
                    recordLink={recordLink}
                    skeletonField={<SkeletonField />}
                    openCreateLeadOrContactForm={openCreateLeadOrContactForm}
                    setOpenCreateLeadOrContactForm={setOpenCreateLeadOrContactForm}
                    searchValue={searchValue}
                  />
                }
              />
            ) : callProperties?.callStatus === "save-close" ? (
              <CallSaveAndClose
                callerImageComponent={
                  <CallPulseImage
                    pulse={false}
                    image={avatar}
                    lastName={lastName}
                    customerNumber={customerNumber}
                    SkeletonImage={SkeletonImage}
                    SkeletonField={SkeletonField}
                    searchValue={searchValue}
                  />
                }
                callNotesFormName={callNotesForm}
                searchValue={searchValue}
                customerNumber={customerNumber}
                avatar={avatar}
                module={module}
                callNotes={callNotes}
                setCallNotes={setCallNotes}
                saveAndCloseClicked={saveAndCloseClicked}
                handleSaveAndCloseClick={handleSaveAndCloseClick}
                totalCallDuration={totalCallDuration}
                lastName={lastName}
                recordId={recordId}
                recordLink={recordLink}
                appProperties={appProperties}
                skeletonImage={<SkeletonImage />}
                skeletonField={<SkeletonField />}
                callProperties={callProperties}
                customerInfoDetails={
                  <CustomerDetails
                    setRecordId={setRecordId}
                    customerNumber={customerNumber}
                    setCreateModuleName={setCreateModuleName}
                    module={module}
                    recordId={recordId}
                    appProperties={appProperties}
                    recordLink={recordLink}
                    skeletonField={<SkeletonField />}
                    openCreateLeadOrContactForm={openCreateLeadOrContactForm}
                    setOpenCreateLeadOrContactForm={setOpenCreateLeadOrContactForm}
                    searchValue={searchValue}
                  />
                }
              />
            ) : null}
          </>
        )}
      </div>
    </>
  );
};
export default Dialpad;

export const CustomerDetails = (props) => {
  let {
    customerNumber,
    setRecordId,
    module,
    setCreateModuleName,
    recordId,
    appProperties,
    recordLink,
    skeletonField,
    openCreateLeadOrContactForm,
    setOpenCreateLeadOrContactForm,
    searchValue,
  } = props;
  const [callProperties, setCallProperties] = useVoiceContext();

  const openCustomerPage = () => {
    if ((recordId, module)) {
      appProperties.controller.openRecord(callProperties?.loadedSDK, recordId, module, recordLink);
    }
  };
  const createRecordPage = (recordModule) => {
    setCreateModuleName(recordModule);
    if ((recordId, recordModule)) {
      appProperties.controller
        .createRecord(callProperties?.loadedSDK, recordModule, openCreateLeadOrContactForm, setOpenCreateLeadOrContactForm)
        .then(function () {
          appProperties.controller.populateRecord(callProperties?.loadedSDK, recordId, recordModule, customerNumber);
        });
    }
  };

  const createLeadOrContactActionMenu = [
    {
      key: "1",
      label: (
        <div
          className="hs-fs-12"
          onClick={() => {
            createRecordPage("leads");
          }}
        >
          Create new lead
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          className="hs-fs-12"
          onClick={() => {
            createRecordPage("contacts");
          }}
        >
          Create new contact
        </div>
      ),
    },
  ];
  return (
    <>
      {recordId || recordId === "" ? (
        <>
          {recordId !== "" ? (
            <div className="view-record-link-md" onClick={() => openCustomerPage(appProperties, recordId, module, recordLink)}>
              <Row>
                <Col>
                  <div className="d-flex flex-row hs-fs-11 hs-fw-400">
                    <>View {module} details</>
                    <div className="callActionIcons view-contact-details-forward-icon"></div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="hs-fs-14 text-white hs-fw-400 hs-border-16 create-record-link">
              <Row className="hs-fs-12">
                <Col>
                  <Dropdown
                    menu={{
                      items: createLeadOrContactActionMenu,
                    }}
                    dropdownRender={(menu) => <div className="create-record-link-parent">{menu}</div>}
                    trigger={["click"]}
                  >
                    <div className="d-flex flex-row justify-content-center align-items-middle">
                      <div className="create-record-plus-icon callActionIcons cursor-pointer" />
                      <div className="hs-fw-400 hs-fs-12 d-flex align-items-center">Create lead or contact</div>
                      <div className="create-record-dropdown-icon callActionIcons cursor-pointer" />
                    </div>
                  </Dropdown>
                </Col>
              </Row>
            </div>
          )}
        </>
      ) : (
        <>
          {" "}
          <div className="d-flex justify-content-center">{skeletonField}</div>{" "}
        </>
      )}
    </>
  );
};
export const SkeletonField = () => {
  const [callProperties, setCallProperties] = useVoiceContext();
  return (
    <div
      className={`w-50 d-flex justify-content-center  align-items-center ${
        callProperties?.callStatus === "save-close" || callProperties?.callStatus === "answered" ? "ms-4" : ""
      }`}
    >
      <Skeleton.Input
        active={true}
        size={"small"}
        block={true}
        style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
        rootClassName="d-flex align-items-center justify-content-center"
      />
    </div>
  );
};
export const SkeletonImage = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <Skeleton.Image active={true} size={"small"} block={true} style={{ backgroundColor: "rgba(255, 255, 255, 0.1)", borderRadius: "50%" }} />
    </div>
  );
};

export const CallPulseImage = (props) => {
  let { pulse, imgStyle, imgWrapperStyle, image, lastName, customerNumber, searchValue } = props;

  const [callerImageObj, setCallerImageObj] = useState({});
  useEffect(() => {
    if (lastName || customerNumber || searchValue) {
      let imageObj = getDisplayNameOfCustomer(lastName, customerNumber, searchValue);
      if (imageObj) {
        setCallerImageObj(imageObj);
      }
    }
  }, [lastName, customerNumber]);

  const getDisplayNameOfCustomer = (customerName, customerNumber, searchValue) => {
    let isPhoneNumber = false;
    if (customerNumber) {
      isPhoneNumber = true;
    }
    let iconLetter = customerName
      ? customerName?.slice(0, 1)?.toUpperCase()
      : isPhoneNumber || searchValue
      ? "#"
      : customerName?.slice(0, 1)?.toUpperCase();

    return {
      displayName: customerName,
      iconLetter: iconLetter,
      isPhoneNumber: isPhoneNumber,
    };
  };

  return (
    <>
      <div className="wrapper d-flex align-items-center justify-content-center">
        <div className={`${pulse ? "pulse" : ""} circle`}></div>
        <div className={`${pulse ? "" : "avatar-big-round"} circle d-flex align-items-center justify-content-center`}>
          {image ? (
            <Avatar rootClassName="w-100 h-100" src={image} />
          ) : callerImageObj?.iconLetter ? (
            <span className="hs-color-dark-orange hs-fw-600 hs-fs-18">{callerImageObj?.iconLetter}</span>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
