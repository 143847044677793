import { Col, Drawer, Row } from 'antd'
import React, { useEffect, useState } from 'react'

const NodesDrawer = ({ title, titleStyle, description, onClose, descriptionStyle, open, data
}) => {
    const path = window.location.pathname;
    console.log("debug_t data NodesDrawer", data);
    const [showDrawer, setShowDrawer] = useState("");
    useEffect(() => {
        if (path.includes("/edit") || path.includes("/createflow")) {

            setShowDrawer(open);
        } else {
            setShowDrawer("");
        }
    }, [open, path]);


    return (
        <div className='w-100 h-100'>
            <Row className='w-100 hs-border-bottom-light'>
                <Col span={24} className='p-3'>
                    <Row className='w-100'>
                        <div className="hs-fs-18 hs-fw-500  d-flex flex-row mt-3 ">
                            <div className={titleStyle} />
                            {title}
                        </div>
                    </Row>
                    <Row className='w-100'>
                        <div className={descriptionStyle}>{description}</div>
                    </Row>
                </Col>
            </Row>
            <Row className='w-100'>
                <Col span={24} className='p-3'>
                    <div>
                        {data}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default NodesDrawer