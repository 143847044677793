import Link from "../components/custom/info/Link.jsx";
import AccountPhone from "../pages/OnboardingComponents/AccountPhone.jsx";
import AddPhone from "../pages/OnboardingComponents/AddPhone.jsx";
import AuthorizePage from "../pages/OnboardingComponents/AuthorizePage";
import GuidetoSendSms from "../pages/OnboardingComponents/GuideToSendSms.jsx";
import OnboardingFinishModalContent from "../pages/OnboardingComponents/OnboardingFinishModalContent.jsx";
import { FB_API_VERSION, FB_SDK_URL } from "../pages/utils/commonUtils.js";
import { voiceAgentStatus } from "../voice/utils/VoiceUtils.js";
import { WHATSAPP_DISPLAY_NAME } from "./AppConstants.js";

export const ProviderCommonObj = {
  isMMSSupported: false,
  isAlphaSenderSupported: false,
  category: "SMS",
  isMessagingServiceSenderSupported: false,
  isEnableInboundSms: false,
  app: "",
  title: "",
  AuthorizationType: "",
  loginContent: [],
  steps: [
    {
      title: "account",
      description: "Account",
      content: <AccountPhone />,
    },
    {
      title: "installation",
      description: "Installation",
      content: <AuthorizePage />,
    },
    {
      title: "addPhone",
      description: "Add Phone",
      content: <AddPhone />,
    },
    {
      title: "guide",
      description: "Guide to send SMS",
      content: <GuidetoSendSms />,
    },
  ],
  menu: [],
  supportedAttachmentTypes: {
    image: "image/jpg, image/jpeg, image/png, image/bmp, image/gif, image/tiff, text/vcard, video/mp4, video/mpeg, audio/mpeg",
  },
  maximumAttachmentSizeInBytes: 5242880,
  maximumAttachmentLimit: 5,
};

export const ProviderExtendedCommonObj = {
  hello_send: {
    howToAuthLink: null,
    app: "hello_send",
    isEnableInboundSms: false,
    supportedAttachmentTypes: {
      image: "image/png, image/jpeg, image/jpg, image/gif",
    },
    maximumAttachmentSizeInBytes: 5242880,
    maximumAttachmentLimit: 5,
    isMessagingServiceSenderSupported: false,
    onBoardingConnected: true,
  },
  twilio: {
    AuthorizationType: "auth",
    AuthorizeReference: [
      <>
        Go to{" "}
        <a href="https://www.twilio.com/" target="_blank" rel="noopener noreferrer">
          https://www.twilio.com/
        </a>{" "}
        and click on the 'Sign up' button to create a new account. If you already have an account, log in to your account.
      </>,
      "On the dashboard, you will see your Account SID and Auth token.",
      "Copy your Account SID and Auth token and use them to authenticate Twilio service.",
    ],
    AuthorizeReferenceText: "That's it! You now have your Twilio Account SID and Auth token.",
    AuthorizeReferenceURL: "https://www.youtube.com/embed/hv-P_qDN2-Y",
    app: "twilio",
    category: "sms",
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_Twilio_",
    title: "Authorize Twilio to connect SMS numbers.",
    supportedAttachmentTypes: {
      image: "image/png, image/jpeg, image/jpg, image/gif",
      video:
        "video/mpeg,video/mp4,video/quicktime,video/webm,video/3gpp,video/3gpp2,video/3gpp-tt,video/H261,video/H263,video/H263-1998,video/H263-2000,video/H264",

      file: "application/pdf, application/vcard, application/vnd.apple.pkpass, text/vcard, text/x-vcard, text/csv, text/rtf, text/richtext, text/calendar, text/directory",
    },
    maximumAttachmentSizeInBytes: 5242880,
    maximumAttachmentLimit: 5,
    isAlphaSenderSupported: true,
    isEnableInboundSms: false,
    isMessagingServiceSenderSupported: true,
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
      {
        label: "Messaging Service",
        key: "MessagingService",
      },
    ],
    onBoardingFlow: {
      getFinishModalComponent: (appProperties) => {
        return {
          render: <OnboardingFinishModalContent serviceName="twilio" />,
          steps: [
            {
              title: (
                <>
                  Authorize SMS channel.
                  <Link externalLink baseUrl={"https://youtu.be/FgJ_OhCa0UA"}>
                    Learn more
                  </Link>
                </>
              ),
            },
            {
              title: (
                <>
                  Enable SMS-enabled numbers.
                  <Link externalLink baseUrl={"https://help.oapps.xyz/portal/en/kb/articles/hellosend-whatsapp-integration"}>
                    Learn more
                  </Link>
                </>
              ),
            },
            {
              title: <>Send 1-1 messages, campaigns, and automations.</>,
            },
          ],
          calculateStep: async () => {
            try {
              let step = 0;
              const twilioProp = appProperties.installedApps.find((installedApp) => installedApp?.right?.service_name === "twilio");
              console.log("tennis appProperties?.savedNumbers", step, appProperties?.savedNumbers.length);

              if (twilioProp?.right?.auth?.authorized) {
                step += 1;
              }
              if (appProperties?.savedNumbers.length > 0) {
                step += 2;
              }
              return step;
            } catch (error) {
              console.error("Error in calculateStep:", error);
              return 0;
            }
          },

          getGallery: (appName) => {
            switch (appName?.toLowerCase()) {
              case "pipedrive":
                return [
                  {
                    headline: "Single and Bulk Messaging",
                    sourceUrl: "https://www.youtube.com/embed/aB6BTkcRBaM?si=7evfv3ukmLAh9Tul",
                    steps: ["Send WhatsApp messages to Pipedrive prospects", `Track all conversation history within Pipedrive`],
                  },
                  {
                    headline: `Automate SMS/WhatsApp message for Appointment Scheduling in Pipedrive`,
                    sourceUrl: "https://www.youtube.com/embed/ojia6Q7VXYM?si=72NEoKwJYlOw8ais",
                    steps: ["Create a Webhook URL", `Set up automation in Pipedrive`],
                  },
                  {
                    headline: `Automate SMS/WhatsApp message When Deal Stages Change in Pipedrive`,
                    sourceUrl: "https://www.youtube.com/embed/QMEwqIIxyI8?si=xYp5UV_UlaQL6ZZb",
                    steps: ["Send templated messages for each deal stage", `Boost conversions with timely engagement`],
                  },
                ];
              case "zoho crm":
                return [
                  {
                    headline: "Boost Sales with Direct WhatsApp Messaging in Zoho CRM",
                    sourceUrl: "https://www.youtube.com/embed/Wtvb0a4FCis?si=wbH3DxkMm5IlZOFE",
                    steps: ["Send WhatsApp messages to Zoho CRM prospects ", `Track all conversation history within Zoho CRM`],
                  },
                  {
                    headline: `Automate SMS and WhatsApp Reminders from Zoho CRM with HelloSend`,
                    sourceUrl: "https://www.youtube.com/embed/BVLCy3R6C6E?si=CWHOgwAKK7eKaxpY",
                    steps: ["Save WhatsApp templates and turn on automation", `Drive more sales through engagement`],
                  },
                  {
                    headline: `Automate SMS / WhatsApp message When Deal Stages Change in Zoho CRM`,
                    sourceUrl: "https://www.youtube.com/embed/41GWpzW8jXw?si=8Yn0cLu8HMVIy_jS",
                    steps: ["Send templated messages based on deal stage", `Engage at the right time and boost conversions`],
                  },
                ];
              default:
                return [];
            }
          },
        };
      },
    },
  },
  twilio_voice: {
    AuthorizationType: "auth",
    AuthorizeReference: [
      <>
        Go to{" "}
        <a href="https://www.twilio.com/" target="_blank" rel="noopener noreferrer">
          https://www.twilio.com/
        </a>{" "}
        and click on the 'Sign up' button to create a new account. If you already have an account, log in to your account.
      </>,
      "On the dashboard, you will see your Account SID and Auth token.",
      "Copy your Account SID and Auth token and use them to authenticate Twilio service.",
    ],
    AuthorizeReferenceText: "That's it! You now have your Twilio Account SID and Auth token.",
    AuthorizeReferenceURL: "https://www.youtube.com/embed/hv-P_qDN2-Y",
    app: "twilio",
    category: "call",
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_Twilio_",
    title: "Authorize Twilio to connect Call numbers.",
    supportedAttachmentTypes: {
      image: "image/png, image/jpeg, image/jpg, image/gif",
      file: "application/pdf, application/vcard, application/vnd.apple.pkpass, text/vcard, text/x-vcard, text/csv, text/rtf, text/richtext, text/calendar, text/directory",
    },
    maximumAttachmentSizeInBytes: 5242880,
    maximumAttachmentLimit: 5,
    isAlphaSenderSupported: true,
    isEnableInboundSms: false,
    isMessagingServiceSenderSupported: true,
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
    ],
    onBoardingFlow: {
      getFinishModalComponent: (appProperties) => {
        return {
          render: <OnboardingFinishModalContent serviceName="twilio_voice" />,
          steps: [
            {
              title: (
                <>
                  Authorize your Twilio account.
                  <Link externalLink baseUrl={"https://help.oapps.xyz/portal/en/kb/articles/set-up-call-channel-crm-guide"}>
                    Learn more
                  </Link>
                </>
              ),
            },
            {
              title: (
                <>
                  Enable call-enabled numbers.
                  <Link externalLink baseUrl={"https://help.oapps.xyz/portal/en/kb/articles/set-up-call-channel-crm-guide"}>
                    Learn more
                  </Link>
                </>
              ),
            },
            {
              title: <>Assign numbers to your team & stay online.</>,
            },
          ],
          calculateStep: async (getVoiceTeams, getCurrentVoiceAgentStatus) => {
            try {
              let step = 0;
              const twilio_voiceProp = appProperties.installedApps.find((installedApp) => installedApp?.right?.service_name === "twilio_voice");
              if (twilio_voiceProp?.right?.auth?.authorized) {
                step += 1;
              }
              const voiceTeamsRes = await getVoiceTeams?.(appProperties);
              if (voiceTeamsRes) {
                const hasVoiceTeam = voiceTeamsRes.some((team) => team.teamId);
                if (hasVoiceTeam) {
                  step += 1;
                } else {
                  console.log("No voice teams found.");
                }
              } else {
                console.error("No data in response.");
              }

              const agentStatusRes = await getCurrentVoiceAgentStatus();
              if (agentStatusRes) {
                if (agentStatusRes?.isVoiceUser) {
                  const isUserOnline = agentStatusRes?.agentStatus?.voiceAgentStatus === voiceAgentStatus.ONLINE_WEB;
                  if (isUserOnline) {
                    step += 1;
                  }
                }
              }

              return step;
            } catch (error) {
              console.error("Error in calculateStep:", error);
              return 0;
            }
          },

          getGallery: (appName) => {
            switch (appName?.toLowerCase()) {
              case "pipedrive":
                return [
                  {
                    headline: "Single and Bulk Messaging",
                    sourceUrl: "https://www.youtube.com/embed/aB6BTkcRBaM?si=7evfv3ukmLAh9Tul",
                    steps: ["Send WhatsApp messages to Pipedrive prospects", `Track all conversation history within Pipedrive`],
                  },
                  {
                    headline: `Automate SMS/WhatsApp message for Appointment Scheduling in Pipedrive`,
                    sourceUrl: "https://www.youtube.com/embed/ojia6Q7VXYM?si=72NEoKwJYlOw8ais",
                    steps: ["Create a Webhook URL", `Set up automation in Pipedrive`],
                  },
                  {
                    headline: `Automate SMS/WhatsApp message When Deal Stages Change in Pipedrive`,
                    sourceUrl: "https://www.youtube.com/embed/QMEwqIIxyI8?si=xYp5UV_UlaQL6ZZb",
                    steps: ["Send templated messages for each deal stage", `Boost conversions with timely engagement`],
                  },
                ];
              case "zoho crm":
                return [
                  {
                    headline: "Boost Sales with Direct WhatsApp Messaging in Zoho CRM",
                    sourceUrl: "https://www.youtube.com/embed/Wtvb0a4FCis?si=wbH3DxkMm5IlZOFE",
                    steps: ["Send WhatsApp messages to Zoho CRM prospects ", `Track all conversation history within Zoho CRM`],
                  },
                  {
                    headline: `Automate SMS and WhatsApp Reminders from Zoho CRM with HelloSend`,
                    sourceUrl: "https://www.youtube.com/embed/BVLCy3R6C6E?si=CWHOgwAKK7eKaxpY",
                    steps: ["Save WhatsApp templates and turn on automation", `Drive more sales through engagement`],
                  },
                  {
                    headline: `Automate SMS / WhatsApp message When Deal Stages Change in Zoho CRM`,
                    sourceUrl: "https://www.youtube.com/embed/41GWpzW8jXw?si=8Yn0cLu8HMVIy_jS",
                    steps: ["Send templated messages based on deal stage", `Engage at the right time and boost conversions`],
                  },
                ];
              default:
                return [];
            }
          },
        };
      },
    },
  },
  twilio_whatsapp: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/whatsapp-for-pipedrive",
    AuthorizationType: "auth",
    app: "twilio_whatsapp",
    category: "sms",
    title: "Authorize Whatsapp-Twilio and configure all your SMS numbers.",
    supportedAttachmentTypes: {
      image: "image/png,image/jpg,image/gif,image/jpeg,image/bmp,image/tiff",
      file: "application/pdf,application/vcard,application/vnd.apple.pkpass,text/vcard,text/x-vcard,text/csv,text/rtf,text/richtext,text/calendar,text/directory",
      audio:
        "audio/basic,audio/L24,audio/mp4,audio/mpeg,audio/ogg,audio/vnd.rn-realaudio,audio/vnd.wave,audio/3gpp,audio/3gpp2,audio/ac3,audio/webm,audio/amr-nb,audio/amr",
      video:
        "video/mpeg,video/mp4,video/quicktime,video/webm,video/3gpp,video/3gpp2,video/3gpp-tt,video/H261,video/H263,video/H263-1998,video/H263-2000,video/H264",
    },

    maximumAttachmentSizeInBytes: 5242880,
    maximumAttachmentLimit: 5,
    isAlphaSenderSupported: true,
    isEnableInboundSms: false,
    isMessagingServiceSenderSupported: true,
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
    ],
    onBoardingFlow: {
      getFinishModalComponent: (appProperties) => {
        return {
          render: <OnboardingFinishModalContent serviceName="twilio_whatsapp" />,
          steps: [
            {
              title: (
                <>
                  Authorize your Twilio account.
                  <Link externalLink baseUrl={"https://youtu.be/FgJ_OhCa0UA"}>
                    Learn more
                  </Link>
                </>
              ),
            },
            {
              title: (
                <>
                  {/* Enable voice-enabled numbers. */}
                  <Link externalLink baseUrl={"https://help.oapps.xyz/portal/en/kb/articles/hellosend-whatsapp-integration"}>
                    Learn more
                  </Link>
                </>
              ),
            },
            {
              title: <>Assign numbers to your team & stay online.</>,
            },
          ],
          calculateStep: async (getVoiceTeams, getCurrentVoiceAgentStatus) => {
            try {
              let step = 0;
              const twilio_voiceProp = appProperties.installedApps.find(
                (installedApp) => installedApp?.right?.service_name === "twilio_whatsapp"
              );
              if (twilio_voiceProp?.right?.auth?.authorized) {
                step += 1;
              }
              const voiceTeamsRes = await getVoiceTeams?.(appProperties);
              if (voiceTeamsRes) {
                const hasVoiceTeam = voiceTeamsRes.some((team) => team.teamId);
                if (hasVoiceTeam) {
                  step += 1;
                } else {
                  console.log("No voice teams found.");
                }
              } else {
                console.error("No data in response.");
              }

              const agentStatusRes = await getCurrentVoiceAgentStatus();
              if (agentStatusRes) {
                if (agentStatusRes?.isVoiceUser) {
                  const isUserOnline = agentStatusRes?.agentStatus?.voiceAgentStatus === voiceAgentStatus.ONLINE_WEB;
                  if (isUserOnline) {
                    step += 1;
                  }
                }
              }

              return step;
            } catch (error) {
              console.error("Error in calculateStep:", error);
              return 0;
            }
          },

          getGallery: (appName) => {
            switch (appName?.toLowerCase()) {
              case "pipedrive":
                return [
                  {
                    headline: "Single and Bulk Messaging",
                    sourceUrl: "https://www.youtube.com/embed/aB6BTkcRBaM?si=7evfv3ukmLAh9Tul",
                    steps: ["Send WhatsApp messages to Pipedrive prospects", `Track all conversation history within Pipedrive`],
                  },
                  {
                    headline: `Automate SMS/WhatsApp message for Appointment Scheduling in Pipedrive`,
                    sourceUrl: "https://www.youtube.com/embed/ojia6Q7VXYM?si=72NEoKwJYlOw8ais",
                    steps: ["Create a Webhook URL", `Set up automation in Pipedrive`],
                  },
                  {
                    headline: `Automate SMS/WhatsApp message When Deal Stages Change in Pipedrive`,
                    sourceUrl: "https://www.youtube.com/embed/QMEwqIIxyI8?si=xYp5UV_UlaQL6ZZb",
                    steps: ["Send templated messages for each deal stage", `Boost conversions with timely engagement`],
                  },
                ];
              case "zoho crm":
                return [
                  {
                    headline: "Boost Sales with Direct WhatsApp Messaging in Zoho CRM",
                    sourceUrl: "https://www.youtube.com/embed/Wtvb0a4FCis?si=wbH3DxkMm5IlZOFE",
                    steps: ["Send WhatsApp messages to Zoho CRM prospects ", `Track all conversation history within Zoho CRM`],
                  },
                  {
                    headline: `Automate SMS and WhatsApp Reminders from Zoho CRM with HelloSend`,
                    sourceUrl: "https://www.youtube.com/embed/BVLCy3R6C6E?si=CWHOgwAKK7eKaxpY",
                    steps: ["Save WhatsApp templates and turn on automation", `Drive more sales through engagement`],
                  },
                  {
                    headline: `Automate SMS / WhatsApp message When Deal Stages Change in Zoho CRM`,
                    sourceUrl: "https://www.youtube.com/embed/41GWpzW8jXw?si=8Yn0cLu8HMVIy_jS",
                    steps: ["Send templated messages based on deal stage", `Engage at the right time and boost conversions`],
                  },
                ];
              default:
                return [];
            }
          },
        };
      },
    },
  },
  clicksend: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_ClickSend",
    AuthorizationType: "auth",
    app: "clicksend",
    isEnableInboundSms: false,
    category: "sms",
    title: "Authorize Clicksend and configure all your SMS numbers.",
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
    ],
    onBoardingFlow: {
      getFinishModalComponent: (appProperties) => {
        return {
          render: <OnboardingFinishModalContent serviceName="clicksend" />,
          steps: [
            {
              title: (
                <>
                  Authorize SMS channel.
                  <Link externalLink baseUrl={"https://youtu.be/FgJ_OhCa0UA"}>
                    Learn more
                  </Link>
                </>
              ),
            },
            {
              title: (
                <>
                  Enable SMS-enabled numbers.
                  <Link externalLink baseUrl={"https://help.oapps.xyz/portal/en/kb/articles/hellosend-whatsapp-integration"}>
                    Learn more
                  </Link>
                </>
              ),
            },
            {
              title: <>Send 1-1 messages, campaigns, and automations.</>,
            },
          ],
          calculateStep: async () => {
            try {
              let step = 0;
              const twilioProp = appProperties.installedApps.find((installedApp) => installedApp?.right?.service_name === "clicksend");
              console.log("tennis appProperties?.savedNumbers", step, appProperties?.savedNumbers.length);

              if (twilioProp?.right?.auth?.authorized) {
                step += 1;
              }
              if (appProperties?.savedNumbers.length > 0) {
                step += 2;
              }
              return step;
            } catch (error) {
              console.error("Error in calculateStep:", error);
              return 0;
            }
          },
          getGallery: (appName) => {
            switch (appName?.toLowerCase()) {
              case "pipedrive":
                return [
                  {
                    headline: "Single and Bulk Messaging",
                    sourceUrl: "https://www.youtube.com/embed/aB6BTkcRBaM?si=7evfv3ukmLAh9Tul",
                    steps: ["Send WhatsApp messages to Pipedrive prospects", `Track all conversation history within Pipedrive`],
                  },
                  {
                    headline: `Automate SMS/WhatsApp message for Appointment Scheduling in Pipedrive`,
                    sourceUrl: "https://www.youtube.com/embed/ojia6Q7VXYM?si=72NEoKwJYlOw8ais",
                    steps: ["Create a Webhook URL", `Set up automation in Pipedrive`],
                  },
                  {
                    headline: `Automate SMS/WhatsApp message When Deal Stages Change in Pipedrive`,
                    sourceUrl: "https://www.youtube.com/embed/QMEwqIIxyI8?si=xYp5UV_UlaQL6ZZb",
                    steps: ["Send templated messages for each deal stage", `Boost conversions with timely engagement`],
                  },
                ];
              case "zoho crm":
                return [
                  {
                    headline: "Boost Sales with Direct WhatsApp Messaging in Zoho CRM",
                    sourceUrl: "https://www.youtube.com/embed/Wtvb0a4FCis?si=wbH3DxkMm5IlZOFE",
                    steps: ["Send WhatsApp messages to Zoho CRM prospects ", `Track all conversation history within Zoho CRM`],
                  },
                  {
                    headline: `Automate SMS and WhatsApp Reminders from Zoho CRM with HelloSend`,
                    sourceUrl: "https://www.youtube.com/embed/BVLCy3R6C6E?si=CWHOgwAKK7eKaxpY",
                    steps: ["Save WhatsApp templates and turn on automation", `Drive more sales through engagement`],
                  },
                  {
                    headline: `Automate SMS / WhatsApp message When Deal Stages Change in Zoho CRM`,
                    sourceUrl: "https://www.youtube.com/embed/41GWpzW8jXw?si=8Yn0cLu8HMVIy_jS",
                    steps: ["Send templated messages based on deal stage", `Engage at the right time and boost conversions`],
                  },
                ];
              default:
                return [];
            }
          },
        };
      },
    },
  },
  plivo: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_Plivo",
    AuthorizationType: "auth",
    app: "plivo",
    isEnableInboundSms: false,
    category: "sms",
    title: "Authorize Plivo and configure all your SMS numbers.",
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
    ],
    onBoardingFlow: {
      getFinishModalComponent: (appProperties) => {
        return {
          render: <OnboardingFinishModalContent serviceName="plivo" />,
          steps: [
            {
              title: (
                <>
                  Authorize SMS channel.
                  <Link externalLink baseUrl={"https://youtu.be/FgJ_OhCa0UA"}>
                    Learn more
                  </Link>
                </>
              ),
            },
            {
              title: (
                <>
                  Enable SMS-enabled numbers.
                  <Link externalLink baseUrl={"https://help.oapps.xyz/portal/en/kb/articles/hellosend-whatsapp-integration"}>
                    Learn more
                  </Link>
                </>
              ),
            },
            {
              title: <>Send 1-1 messages, campaigns, and automations.</>,
            },
          ],
          calculateStep: async () => {
            try {
              let step = 0;
              const twilioProp = appProperties.installedApps.find((installedApp) => installedApp?.right?.service_name === "plivo");
              console.log("tennis appProperties?.savedNumbers", step, appProperties?.savedNumbers.length);

              if (twilioProp?.right?.auth?.authorized) {
                step += 1;
              }
              if (appProperties?.savedNumbers.length > 0) {
                step += 2;
              }
              return step;
            } catch (error) {
              console.error("Error in calculateStep:", error);
              return 0;
            }
          },

          getGallery: (appName) => {
            switch (appName?.toLowerCase()) {
              case "pipedrive":
                return [
                  {
                    headline: "Single and Bulk Messaging",
                    sourceUrl: "https://www.youtube.com/embed/aB6BTkcRBaM?si=7evfv3ukmLAh9Tul",
                    steps: ["Send WhatsApp messages to Pipedrive prospects", `Track all conversation history within Pipedrive`],
                  },
                  {
                    headline: `Automate SMS/WhatsApp message for Appointment Scheduling in Pipedrive`,
                    sourceUrl: "https://www.youtube.com/embed/ojia6Q7VXYM?si=72NEoKwJYlOw8ais",
                    steps: ["Create a Webhook URL", `Set up automation in Pipedrive`],
                  },
                  {
                    headline: `Automate SMS/WhatsApp message When Deal Stages Change in Pipedrive`,
                    sourceUrl: "https://www.youtube.com/embed/QMEwqIIxyI8?si=xYp5UV_UlaQL6ZZb",
                    steps: ["Send templated messages for each deal stage", `Boost conversions with timely engagement`],
                  },
                ];
              case "zoho crm":
                return [
                  {
                    headline: "Boost Sales with Direct WhatsApp Messaging in Zoho CRM",
                    sourceUrl: "https://www.youtube.com/embed/Wtvb0a4FCis?si=wbH3DxkMm5IlZOFE",
                    steps: ["Send WhatsApp messages to Zoho CRM prospects ", `Track all conversation history within Zoho CRM`],
                  },
                  {
                    headline: `Automate SMS and WhatsApp Reminders from Zoho CRM with HelloSend`,
                    sourceUrl: "https://www.youtube.com/embed/BVLCy3R6C6E?si=CWHOgwAKK7eKaxpY",
                    steps: ["Save WhatsApp templates and turn on automation", `Drive more sales through engagement`],
                  },
                  {
                    headline: `Automate SMS / WhatsApp message When Deal Stages Change in Zoho CRM`,
                    sourceUrl: "https://www.youtube.com/embed/41GWpzW8jXw?si=8Yn0cLu8HMVIy_jS",
                    steps: ["Send templated messages based on deal stage", `Engage at the right time and boost conversions`],
                  },
                ];
              default:
                return [];
            }
          },
        };
      },
    },
  },
  vonage: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_Vonage",
    AuthorizationType: "auth",
    app: "vonage",
    isEnableInboundSms: false,
    category: "sms",
    title: "Authorize Vonage and configure all your SMS numbers.",
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
    ],
    onBoardingFlow: {
      getFinishModalComponent: (appProperties) => {
        return {
          render: <OnboardingFinishModalContent serviceName="vonage" />,
          steps: [
            {
              title: (
                <>
                  Authorize SMS channel.
                  <Link externalLink baseUrl={"https://youtu.be/FgJ_OhCa0UA"}>
                    Learn more
                  </Link>
                </>
              ),
            },
            {
              title: (
                <>
                  Enable SMS-enabled numbers.
                  <Link externalLink baseUrl={"https://help.oapps.xyz/portal/en/kb/articles/hellosend-whatsapp-integration"}>
                    Learn more
                  </Link>
                </>
              ),
            },
            {
              title: <>Send 1-1 messages, campaigns, and automations.</>,
            },
          ],
          calculateStep: async () => {
            try {
              let step = 0;
              const twilioProp = appProperties.installedApps.find((installedApp) => installedApp?.right?.service_name === "vonage");
              console.log("tennis appProperties?.savedNumbers", step, appProperties?.savedNumbers.length);

              if (twilioProp?.right?.auth?.authorized) {
                step += 1;
              }
              if (appProperties?.savedNumbers.length > 0) {
                step += 2;
              }
              return step;
            } catch (error) {
              console.error("Error in calculateStep:", error);
              return 0;
            }
          },

          getGallery: (appName) => {
            switch (appName?.toLowerCase()) {
              case "pipedrive":
                return [
                  {
                    headline: "Single and Bulk Messaging",
                    sourceUrl: "https://www.youtube.com/embed/aB6BTkcRBaM?si=7evfv3ukmLAh9Tul",
                    steps: ["Send WhatsApp messages to Pipedrive prospects", `Track all conversation history within Pipedrive`],
                  },
                  {
                    headline: `Automate SMS/WhatsApp message for Appointment Scheduling in Pipedrive`,
                    sourceUrl: "https://www.youtube.com/embed/ojia6Q7VXYM?si=72NEoKwJYlOw8ais",
                    steps: ["Create a Webhook URL", `Set up automation in Pipedrive`],
                  },
                  {
                    headline: `Automate SMS/WhatsApp message When Deal Stages Change in Pipedrive`,
                    sourceUrl: "https://www.youtube.com/embed/QMEwqIIxyI8?si=xYp5UV_UlaQL6ZZb",
                    steps: ["Send templated messages for each deal stage", `Boost conversions with timely engagement`],
                  },
                ];
              case "zoho crm":
                return [
                  {
                    headline: "Boost Sales with Direct WhatsApp Messaging in Zoho CRM",
                    sourceUrl: "https://www.youtube.com/embed/Wtvb0a4FCis?si=wbH3DxkMm5IlZOFE",
                    steps: ["Send WhatsApp messages to Zoho CRM prospects ", `Track all conversation history within Zoho CRM`],
                  },
                  {
                    headline: `Automate SMS and WhatsApp Reminders from Zoho CRM with HelloSend`,
                    sourceUrl: "https://www.youtube.com/embed/BVLCy3R6C6E?si=CWHOgwAKK7eKaxpY",
                    steps: ["Save WhatsApp templates and turn on automation", `Drive more sales through engagement`],
                  },
                  {
                    headline: `Automate SMS / WhatsApp message When Deal Stages Change in Zoho CRM`,
                    sourceUrl: "https://www.youtube.com/embed/41GWpzW8jXw?si=8Yn0cLu8HMVIy_jS",
                    steps: ["Send templated messages based on deal stage", `Engage at the right time and boost conversions`],
                  },
                ];
              default:
                return [];
            }
          },
        };
      },
    },
  },
  burstsms: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_BurstSMS",
    AuthorizationType: "auth",
    isEnableInboundSms: false,
    category: "sms",
    title: "Authorize Burstsms and configure all your SMS numbers.",
    app: "burstsms",
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
    ],
    onBoardingFlow: {
      getFinishModalComponent: (appProperties) => {
        return {
          render: <OnboardingFinishModalContent serviceName="burstsms" />,
          steps: [
            {
              title: (
                <>
                  Authorize SMS channel.
                  <Link externalLink baseUrl={"https://youtu.be/FgJ_OhCa0UA"}>
                    Learn more
                  </Link>
                </>
              ),
            },
            {
              title: (
                <>
                  Enable SMS-enabled numbers.
                  <Link externalLink baseUrl={"https://help.oapps.xyz/portal/en/kb/articles/hellosend-whatsapp-integration"}>
                    Learn more
                  </Link>
                </>
              ),
            },
            {
              title: <>Send 1-1 messages, campaigns, and automations.</>,
            },
          ],
          calculateStep: async () => {
            try {
              let step = 0;
              const twilioProp = appProperties.installedApps.find((installedApp) => installedApp?.right?.service_name === "burstsms");
              console.log("tennis appProperties?.savedNumbers", step, appProperties?.savedNumbers.length);

              if (twilioProp?.right?.auth?.authorized) {
                step += 1;
              }
              if (appProperties?.savedNumbers.length > 0) {
                step += 2;
              }
              return step;
            } catch (error) {
              console.error("Error in calculateStep:", error);
              return 0;
            }
          },

          getGallery: (appName) => {
            switch (appName?.toLowerCase()) {
              case "pipedrive":
                return [
                  {
                    headline: "Single and Bulk Messaging",
                    sourceUrl: "https://www.youtube.com/embed/aB6BTkcRBaM?si=7evfv3ukmLAh9Tul",
                    steps: ["Send WhatsApp messages to Pipedrive prospects", `Track all conversation history within Pipedrive`],
                  },
                  {
                    headline: `Automate SMS/WhatsApp message for Appointment Scheduling in Pipedrive`,
                    sourceUrl: "https://www.youtube.com/embed/ojia6Q7VXYM?si=72NEoKwJYlOw8ais",
                    steps: ["Create a Webhook URL", `Set up automation in Pipedrive`],
                  },
                  {
                    headline: `Automate SMS/WhatsApp message When Deal Stages Change in Pipedrive`,
                    sourceUrl: "https://www.youtube.com/embed/QMEwqIIxyI8?si=xYp5UV_UlaQL6ZZb",
                    steps: ["Send templated messages for each deal stage", `Boost conversions with timely engagement`],
                  },
                ];
              case "zoho crm":
                return [
                  {
                    headline: "Boost Sales with Direct WhatsApp Messaging in Zoho CRM",
                    sourceUrl: "https://www.youtube.com/embed/Wtvb0a4FCis?si=wbH3DxkMm5IlZOFE",
                    steps: ["Send WhatsApp messages to Zoho CRM prospects ", `Track all conversation history within Zoho CRM`],
                  },
                  {
                    headline: `Automate SMS and WhatsApp Reminders from Zoho CRM with HelloSend`,
                    sourceUrl: "https://www.youtube.com/embed/BVLCy3R6C6E?si=CWHOgwAKK7eKaxpY",
                    steps: ["Save WhatsApp templates and turn on automation", `Drive more sales through engagement`],
                  },
                  {
                    headline: `Automate SMS / WhatsApp message When Deal Stages Change in Zoho CRM`,
                    sourceUrl: "https://www.youtube.com/embed/41GWpzW8jXw?si=8Yn0cLu8HMVIy_jS",
                    steps: ["Send templated messages based on deal stage", `Engage at the right time and boost conversions`],
                  },
                ];
              default:
                return [];
            }
          },
        };
      },
    },
  },
  telnyx: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_Telnyx",
    category: "sms",
    app: "telnyx",
    title: "Authorize Telnyx and configure all your SMS numbers.",
    AuthorizationType: "auth",
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
    ],
    onBoardingFlow: {
      getFinishModalComponent: (appProperties) => {
        return {
          render: <OnboardingFinishModalContent serviceName="telnyx" />,
          steps: [
            {
              title: (
                <>
                  Authorize SMS channel.
                  <Link externalLink baseUrl={"https://youtu.be/FgJ_OhCa0UA"}>
                    Learn more
                  </Link>
                </>
              ),
            },
            {
              title: (
                <>
                  Enable SMS-enabled numbers.
                  <Link externalLink baseUrl={"https://help.oapps.xyz/portal/en/kb/articles/hellosend-whatsapp-integration"}>
                    Learn more
                  </Link>
                </>
              ),
            },
            {
              title: <>Send 1-1 messages, campaigns, and automations.</>,
            },
          ],
          calculateStep: async () => {
            try {
              let step = 0;
              const twilioProp = appProperties.installedApps.find((installedApp) => installedApp?.right?.service_name === "telnyx");
              console.log("tennis appProperties?.savedNumbers", step, appProperties?.savedNumbers.length);

              if (twilioProp?.right?.auth?.authorized) {
                step += 1;
              }
              if (appProperties?.savedNumbers.length > 0) {
                step += 2;
              }
              return step;
            } catch (error) {
              console.error("Error in calculateStep:", error);
              return 0;
            }
          },

          getGallery: (appName) => {
            switch (appName?.toLowerCase()) {
              case "pipedrive":
                return [
                  {
                    headline: "Single and Bulk Messaging",
                    sourceUrl: "https://www.youtube.com/embed/aB6BTkcRBaM?si=7evfv3ukmLAh9Tul",
                    steps: ["Send WhatsApp messages to Pipedrive prospects", `Track all conversation history within Pipedrive`],
                  },
                  {
                    headline: `Automate SMS/WhatsApp message for Appointment Scheduling in Pipedrive`,
                    sourceUrl: "https://www.youtube.com/embed/ojia6Q7VXYM?si=72NEoKwJYlOw8ais",
                    steps: ["Create a Webhook URL", `Set up automation in Pipedrive`],
                  },
                  {
                    headline: `Automate SMS/WhatsApp message When Deal Stages Change in Pipedrive`,
                    sourceUrl: "https://www.youtube.com/embed/QMEwqIIxyI8?si=xYp5UV_UlaQL6ZZb",
                    steps: ["Send templated messages for each deal stage", `Boost conversions with timely engagement`],
                  },
                ];
              case "zoho crm":
                return [
                  {
                    headline: "Boost Sales with Direct WhatsApp Messaging in Zoho CRM",
                    sourceUrl: "https://www.youtube.com/embed/Wtvb0a4FCis?si=wbH3DxkMm5IlZOFE",
                    steps: ["Send WhatsApp messages to Zoho CRM prospects ", `Track all conversation history within Zoho CRM`],
                  },
                  {
                    headline: `Automate SMS and WhatsApp Reminders from Zoho CRM with HelloSend`,
                    sourceUrl: "https://www.youtube.com/embed/BVLCy3R6C6E?si=CWHOgwAKK7eKaxpY",
                    steps: ["Save WhatsApp templates and turn on automation", `Drive more sales through engagement`],
                  },
                  {
                    headline: `Automate SMS / WhatsApp message When Deal Stages Change in Zoho CRM`,
                    sourceUrl: "https://www.youtube.com/embed/41GWpzW8jXw?si=8Yn0cLu8HMVIy_jS",
                    steps: ["Send templated messages based on deal stage", `Engage at the right time and boost conversions`],
                  },
                ];
              default:
                return [];
            }
          },
        };
      },
    },
  },
  facebook: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_Facebook",
    category: "messenger",
    AuthorizationType: "outh",
    supportedAttachmentTypes: {
      image: "image/png, image/jpeg, image/jpg, image/gif",
    },
    maximumAttachmentLimit: 5,
    maximumAttachmentSizeInBytes: 5242880,
    isEnableInboundSms: false,
    app: "facebook",
    title: "Connect facebook and configure all pages",
    menu: [
      {
        label: "Select Pages",
        key: "Select Pages",
      },
    ],
    onBoardingFlow: {
      getFinishModalComponent: (appProperties) => {
        return {
          render: <OnboardingFinishModalContent serviceName="facebook" />,
          steps: [
            {
              title: (
                <>
                  Authorize SMS channel.
                  <Link externalLink baseUrl={"https://youtu.be/FgJ_OhCa0UA"}>
                    Learn more
                  </Link>
                </>
              ),
            },
            {
              title: (
                <>
                  Enable SMS-enabled numbers.
                  <Link externalLink baseUrl={"https://help.oapps.xyz/portal/en/kb/articles/hellosend-whatsapp-integration"}>
                    Learn more
                  </Link>
                </>
              ),
            },
            {
              title: <>Send 1-1 messages, campaigns, and automations.</>,
            },
          ],
          calculateStep: async () => {
            try {
              let step = 0;
              const twilioProp = appProperties.installedApps.find((installedApp) => installedApp?.right?.service_name === "facebook");
              console.log("tennis appProperties?.savedNumbers", step, appProperties?.savedNumbers.length);

              if (twilioProp?.right?.auth?.authorized) {
                step += 1;
              }
              if (appProperties?.savedNumbers.length > 0) {
                step += 2;
              }
              return step;
            } catch (error) {
              console.error("Error in calculateStep:", error);
              return 0;
            }
          },

          getGallery: (appName) => {
            switch (appName?.toLowerCase()) {
              case "pipedrive":
                return [
                  {
                    headline: "Single and Bulk Messaging",
                    sourceUrl: "https://www.youtube.com/embed/aB6BTkcRBaM?si=7evfv3ukmLAh9Tul",
                    steps: ["Send WhatsApp messages to Pipedrive prospects", `Track all conversation history within Pipedrive`],
                  },
                  {
                    headline: `Automate SMS/WhatsApp message for Appointment Scheduling in Pipedrive`,
                    sourceUrl: "https://www.youtube.com/embed/ojia6Q7VXYM?si=72NEoKwJYlOw8ais",
                    steps: ["Create a Webhook URL", `Set up automation in Pipedrive`],
                  },
                  {
                    headline: `Automate SMS/WhatsApp message When Deal Stages Change in Pipedrive`,
                    sourceUrl: "https://www.youtube.com/embed/QMEwqIIxyI8?si=xYp5UV_UlaQL6ZZb",
                    steps: ["Send templated messages for each deal stage", `Boost conversions with timely engagement`],
                  },
                ];
              case "zoho crm":
                return [
                  {
                    headline: "Boost Sales with Direct WhatsApp Messaging in Zoho CRM",
                    sourceUrl: "https://www.youtube.com/embed/Wtvb0a4FCis?si=wbH3DxkMm5IlZOFE",
                    steps: ["Send WhatsApp messages to Zoho CRM prospects ", `Track all conversation history within Zoho CRM`],
                  },
                  {
                    headline: `Automate SMS and WhatsApp Reminders from Zoho CRM with HelloSend`,
                    sourceUrl: "https://www.youtube.com/embed/BVLCy3R6C6E?si=CWHOgwAKK7eKaxpY",
                    steps: ["Save WhatsApp templates and turn on automation", `Drive more sales through engagement`],
                  },
                  {
                    headline: `Automate SMS / WhatsApp message When Deal Stages Change in Zoho CRM`,
                    sourceUrl: "https://www.youtube.com/embed/41GWpzW8jXw?si=8Yn0cLu8HMVIy_jS",
                    steps: ["Send templated messages based on deal stage", `Engage at the right time and boost conversions`],
                  },
                ];
              default:
                return [];
            }
          },
        };
      },
    },
  },
  ringcentral: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_RingCentral",
    AuthorizationType: "oauth",
    AuthorizeReference: [
      "Begin by clicking on the 'Authorize' button.",
      "Enter your RingCentral username and password.",
      "Click on the 'Authorize' button to grant permission.",
      "Once completed, your RingCentral account is connected.",
      "In the right panel, you'll find all SMS-enabled numbers available for configurations.",
    ],
    AuthorizeReferenceURL: "https://www.youtube.com/embed/U_HI1fQe8MM?si=oGTp9XcgM-lCXQY0",
    category: "sms",
    isEnableInboundSms: true,
    app: "ringcentral",
    title: "Authorize Ringcentral to connect SMS numbers.",
    supportedAttachmentTypes: {
      image: "image/png, image/jpeg, image/jpg, image/gif",
    },
    maximumAttachmentLimit: 5,
    maximumAttachmentSizeInBytes: 5242880,
    onBoardingFlow: {
      getFinishModalComponent: (appProperties) => {
        return {
          render: <OnboardingFinishModalContent serviceName="ringcentral" />,
          steps: [
            {
              title: (
                <>
                  Authorize SMS channel.
                  <Link externalLink baseUrl={"https://youtu.be/FgJ_OhCa0UA"}>
                    Learn more
                  </Link>
                </>
              ),
            },
            {
              title: (
                <>
                  Enable SMS-enabled numbers.
                  <Link externalLink baseUrl={"https://help.oapps.xyz/portal/en/kb/articles/hellosend-whatsapp-integration"}>
                    Learn more
                  </Link>
                </>
              ),
            },
            {
              title: <>Send 1-1 messages, campaigns, and automations.</>,
            },
          ],
          calculateStep: async () => {
            try {
              let step = 0;
              const twilioProp = appProperties.installedApps.find((installedApp) => installedApp?.right?.service_name === "ringcentral");
              console.log("tennis appProperties?.savedNumbers", step, appProperties?.savedNumbers.length);

              if (twilioProp?.right?.auth?.authorized) {
                step += 1;
              }
              if (appProperties?.savedNumbers.length > 0) {
                step += 2;
              }
              return step;
            } catch (error) {
              console.error("Error in calculateStep:", error);
              return 0;
            }
          },

          getGallery: (appName) => {
            switch (appName?.toLowerCase()) {
              case "pipedrive":
                return [
                  {
                    headline: "Single and Bulk Messaging",
                    sourceUrl: "https://www.youtube.com/embed/aB6BTkcRBaM?si=7evfv3ukmLAh9Tul",
                    steps: ["Send WhatsApp messages to Pipedrive prospects", `Track all conversation history within Pipedrive`],
                  },
                  {
                    headline: `Automate SMS/WhatsApp message for Appointment Scheduling in Pipedrive`,
                    sourceUrl: "https://www.youtube.com/embed/ojia6Q7VXYM?si=72NEoKwJYlOw8ais",
                    steps: ["Create a Webhook URL", `Set up automation in Pipedrive`],
                  },
                  {
                    headline: `Automate SMS/WhatsApp message When Deal Stages Change in Pipedrive`,
                    sourceUrl: "https://www.youtube.com/embed/QMEwqIIxyI8?si=xYp5UV_UlaQL6ZZb",
                    steps: ["Send templated messages for each deal stage", `Boost conversions with timely engagement`],
                  },
                ];
              case "zoho crm":
                return [
                  {
                    headline: "Boost Sales with Direct WhatsApp Messaging in Zoho CRM",
                    sourceUrl: "https://www.youtube.com/embed/Wtvb0a4FCis?si=wbH3DxkMm5IlZOFE",
                    steps: ["Send WhatsApp messages to Zoho CRM prospects ", `Track all conversation history within Zoho CRM`],
                  },
                  {
                    headline: `Automate SMS and WhatsApp Reminders from Zoho CRM with HelloSend`,
                    sourceUrl: "https://www.youtube.com/embed/BVLCy3R6C6E?si=CWHOgwAKK7eKaxpY",
                    steps: ["Save WhatsApp templates and turn on automation", `Drive more sales through engagement`],
                  },
                  {
                    headline: `Automate SMS / WhatsApp message When Deal Stages Change in Zoho CRM`,
                    sourceUrl: "https://www.youtube.com/embed/41GWpzW8jXw?si=8Yn0cLu8HMVIy_jS",
                    steps: ["Send templated messages based on deal stage", `Engage at the right time and boost conversions`],
                  },
                ];
              default:
                return [];
            }
          },
        };
      },
    },
  },
  teams_helloSend: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/hellosend-microsoftteams",
    AuthorizationType: "no_auth",
    category: "sms",
    isEnableInboundSms: false,
    app: "teams_hellosend",
    title: "Authorize Teams and port all your numbers.",
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
    ],
    supportedAttachmentTypes: {
      image: "image/png, image/jpeg, image/jpg, image/gif",
      file: "application/pdf, application/vcard, application/vnd.apple.pkpass, text/vcard, text/x-vcard, text/csv, text/rtf, text/richtext, text/calendar, text/directory",
    },
    maximumAttachmentLimit: 5,
    maximumAttachmentSizeInBytes: 5242880,
    isMMSSupported: true,
    AuthorizeReference: [
      "Begin by clicking the 'Authorize' button.",
      "Enter your Microsoft Teams username and password.",
      "Verify your account by following the prompts.",
      "Once your account is verified, all your phone numbers will be listed for configuration.",
    ],
    AuthorizeReferenceURL: "https://www.youtube.com/embed/CWnXcFlWm4A",
  },
  whatsapp: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_Facebook",
    category: "whatsapp",
    AuthorizationType: "oauth_sdk",
    supportedAttachmentTypes: {
      image: "image/png, image/jpeg, image/jpg",
      audio: "audio/aac, audio/amr, audio/mpeg, audio/mp4, audio/ogg",
      video: "video/3gp, video/mp4",
      document:
        "text/plain, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf",
    },
    maximumAttachmentLimit: 5,
    maximumAttachmentSizeInBytes: 5242880,
    onAuthorizeOpenSuccessModal: true,
    isMMSSupported: true,
    isEnableInboundSms: false,
    baseUrlForTheScript: FB_SDK_URL,
    loadScripts: () => {
      return new Promise((resolve) => {
        try {
          window.fbAsyncInit = function () {
            window.FB.init({
              appId: process.env.REACT_APP_FB_APP_ID,
              autoLogAppEvents: true,
              xfbml: true,
              version: FB_API_VERSION,
            });
          };
          (function (d, s, id) {
            var js,
              fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = FB_SDK_URL;
            fjs.parentNode.insertBefore(js, fjs);
          })(document, "script", "facebook-jssdk");
        } catch (error) {
        } finally {
          resolve(FB_SDK_URL);
        }
      });
    },
    doUnloadAuthorizationProcess: () => {},
    getHandleMessageAuthEvent: (wabaId) => {
      const handleAuthMessageEvent = (event) => {
        if (event.origin !== "https://www.facebook.com" && event.origin !== "https://web.facebook.com") return;
        try {
          const data = JSON.parse(event.data);
          if (data.type === "WA_EMBEDDED_SIGNUP" && data.event === "FINISH") {
            wabaId.current = data.data.waba_id;
          }
        } catch {}
      };
      return handleAuthMessageEvent;
    },
    doUnloadAuthorizationProcess: (listenerFunctionToRemove) => {
      window.removeEventListener("message", listenerFunctionToRemove);
    },
    getAuthorizeFunction: (callBackFunction, handleAuthMessageEvent) => {
      return new Promise((resolve) => {
        if (handleAuthMessageEvent) {
          window.addEventListener("message", handleAuthMessageEvent);
        }

        // Launch method and callback registration
        const launchWhatsAppSignup = () => {
          window.FB.login(callBackFunction, {
            config_id: process.env.REACT_APP_FB_CONFIG_ID,
            response_type: "code",
            override_default_response_type: true,
            extras: {
              setup: {},
              featureType: "",
              sessionInfoVersion: "3",
            },
          });
        };
        resolve(launchWhatsAppSignup);
      });
    },
    app: "whatsapp",
    title: `Connect ${WHATSAPP_DISPLAY_NAME} and configure all numbers`,
    description: (
      <>
        Connect {WHATSAPP_DISPLAY_NAME} business numbers and bring all your conversations to one place. To know more
        <Link externalLink baseUrl="https://help.oapps.xyz/portal/en/kb/articles/hellosend-whatsapp-integration">
          Click here
        </Link>
      </>
    ),
    note: <></>,
    helper: [
      <>
        Click <b>'Connect'</b> to log into your Facebook Business account.
      </>,
      <>Follow the steps to link or register your {WHATSAPP_DISPLAY_NAME} number and back up your data if using a personal number.</>,
    ],
    successAuthModal: {
      title: "Congratulations !",
      content: `Your account has been successfully connected your ${WHATSAPP_DISPLAY_NAME} account. Please add Templates to continue.`,
      button: {
        ok: {
          text: "Add Templates",
        },
        cancel: {
          text: "Cancel",
        },
      },
    },
    onBoardingFlow: {
      getFinishModalComponent: (appProperties) => {
        return {
          render: <OnboardingFinishModalContent serviceName="whatsapp" />,
          steps: [
            {
              title: (
                <>
                  Authorization of {WHATSAPP_DISPLAY_NAME} Service -
                  <Link externalLink baseUrl={"https://youtu.be/FgJ_OhCa0UA"}>
                    How to Authorize
                  </Link>
                </>
              ),
            },
            {
              title: (
                <>
                  Configuration of {WHATSAPP_DISPLAY_NAME}-Enabled Number -
                  <Link externalLink baseUrl={"https://help.oapps.xyz/portal/en/kb/articles/hellosend-whatsapp-integration"}>
                    Setup Guide
                  </Link>
                </>
              ),
            },
            {
              title: (
                <>
                  Importing of {WHATSAPP_DISPLAY_NAME} Templates -
                  <Link appProperties={appProperties} baseUrl={"/settings/templates?openTemplate=true&ttype=wp&"}>
                    Add Templates
                  </Link>
                </>
              ),
            },
          ],
          calculateStep: async (fetchSavedTemplates) => {
            return new Promise((resolve, reject) => {
              var step = 0;
              const whatsappProp = appProperties.installedApps.find((installedApp) => installedApp?.right?.service_name === "whatsapp");
              if (whatsappProp?.right?.auth?.authorized) {
                step = step + 2;
              }

              fetchSavedTemplates?.(appProperties)
                .then((res) => {
                  if (res?.success) {
                    const hasWhatsappTemplate = res.data?.some((template) => template.integId === whatsappProp?.integProps?.integId);
                    if (hasWhatsappTemplate) {
                      step = step + 1;
                    }
                  }
                })
                .finally(() => {
                  resolve(step);
                });
            });
          },
          getGallery: (appName) => {
            appName = appName.toLowerCase();
            if (appName?.includes("pipedrive")) {
              return [
                {
                  headline: "Boost Sales with Direct WhatsApp Messaging in Pipedrive",
                  sourceUrl: "https://www.youtube.com/embed/aB6BTkcRBaM?si=7evfv3ukmLAh9Tul",
                  steps: ["Send WhatsApp messages to Pipedrive prospects", `Track all conversation history within Pipedrive`],
                },
                {
                  headline: `Automate SMS/WhatsApp message for Appointment Scheduling in Pipedrive`,
                  sourceUrl: "https://www.youtube.com/embed/ojia6Q7VXYM?si=72NEoKwJYlOw8ais",
                  steps: ["Create a Webhook URL", `Set up automation in Pipedrive`],
                },
                {
                  headline: `Automate SMS/WhatsApp message When Deal Stages Change in Pipedrive`,
                  sourceUrl: "https://www.youtube.com/embed/QMEwqIIxyI8?si=xYp5UV_UlaQL6ZZb",
                  steps: ["Send templated messages for each deal stage", `Boost conversions with timely engagement`],
                },
              ];
            } else if (appName?.includes("zohocrm")) {
              return [
                {
                  headline: "Boost Sales with Direct WhatsApp Messaging in Zoho CRM",
                  sourceUrl: "https://www.youtube.com/embed/Wtvb0a4FCis?si=wbH3DxkMm5IlZOFE",
                  steps: ["Send WhatsApp messages to Zoho CRM prospects ", `Track all conversation history within Zoho CRM`],
                },
                {
                  headline: `Automate SMS and WhatsApp Reminders from Zoho CRM with HelloSend`,
                  sourceUrl: "https://www.youtube.com/embed/BVLCy3R6C6E?si=CWHOgwAKK7eKaxpY",
                  steps: ["Save WhatsApp templates and turn on automation", `Drive more sales through engagement`],
                },
                {
                  headline: `Automate SMS / WhatsApp message When Deal Stages Change in Zoho CRM`,
                  sourceUrl: "https://www.youtube.com/embed/41GWpzW8jXw?si=8Yn0cLu8HMVIy_jS",
                  steps: ["Send templated messages based on deal stage", `Engage at the right time and boost conversions`],
                },
              ];
            }
          },
        };
      },
    },
  },
};
