import React from "react";
import { Modal, Row, Col, Card, List } from "antd";
import CommonUserAvatar from "../../CommonUserAvatar";
import { EmptyListView } from "../../../pages/utils/CommonVessels";

const VoiceTeamAgentsModal = ({ viewAgentsModalOpen, setViewAgentsModalOpen, handleModalClose, selectedTeamObj }) => {
  const AgentsCard = ({ title, agents, status, emptyListIcon, emptyListDescription }) => (
    <Col>
      <Card className="team-view-agents-modal-card" title={title} extra={agents?.length}>
        <List
          itemLayout="horizontal"
          dataSource={agents}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<CommonUserAvatar name={item?.firstName} icon={item?.avatar} size={40} profileWithStatus={true} status={status} />}
                title={<div className="mt-lg-2">{item?.firstName}</div>}
              />
            </List.Item>
          )}
          locale={{
            emptyText: (
              <EmptyListView
                icon={`callActionIcons ${emptyListIcon} display-inline-block`}
                tagLineTwo={`${emptyListDescription}`}
                tagHeight={"43vh"}
              />
            ),
          }}
        />
      </Card>
    </Col>
  );

  return (
    <Modal rootClassName="teamsModalBox w-100" open={viewAgentsModalOpen} closable={false} onCancel={handleModalClose} footer={null}>
      <Row justify="space-between" className="pb-3">
        <div className="hs-fs-17 hs-fw-600">Agents</div>
        <div className="actionIconsSprite modalCloseIcon" onClick={() => setViewAgentsModalOpen(false)} />
      </Row>

      <Row gutter={[20, 20]} className="w-100">
        <AgentsCard
          title={
            <div className="p-1 hs-fs-14">
              <span className="onlineIndicatorDot"></span>
              Online
            </div>
          }
          agents={selectedTeamObj?.onlineAgents}
          status="online"
          emptyListIcon="voice-teams-empty-online-agents-icon"
          emptyListDescription="No Users currently online"
        />
        <AgentsCard
          title={
            <div className="p-1 hs-fs-14">
              <span className="busyIndicatorDot"></span>
              Busy
            </div>
          }
          agents={selectedTeamObj?.busyAgents}
          status="busy"
          emptyListIcon="voice-teams-empty-busy-agents-icon"
          emptyListDescription="No Users currently busy"
        />
        <AgentsCard
          title={
            <div className="p-1 hs-fs-14">
              <span className="offlineIndicatorDot"></span>
              Offline
            </div>
          }
          agents={selectedTeamObj?.offlineAgents}
          status="offline"
          emptyListIcon="voice-teams-empty-offline-agents-icon"
          emptyListDescription="No Users currently offline"
        />
      </Row>
    </Modal>
  );
};

export default VoiceTeamAgentsModal;
