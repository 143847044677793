import { Col, Drawer, List, Menu, Modal, Row, Spin, Table, Tooltip } from "antd";
import React, { useEffect, useState, useContext, useRef } from "react";
import { AppContext } from "../../context/AppContext";
import useHttp from "../../hooks/useHttp";
import VoiceSelect from "./common/VoiceSelect";
import { EmptyListView } from "../../pages/utils/CommonVessels";
import { LoadingOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useVoiceContext } from "../context/VoiceContext";
import { useVoiceNumbers } from "../hooks/useVoiceNumbers";
import { featureUpgradeAlertPopup } from "../../pages/utils/CommonNotifications";
import { getCurrentFeature } from "../utils/VoiceUtils";

const VoiceCallLog = ({ mobileView, handleDialPadHistoryClickToCall, setCallDetails, setOpenCallDetails }) => {
  const [appProperties] = useContext(AppContext);
  const [upgradeAlertPopup, setUpgradeAlertPopup] = useState(false);
  const [callProperties, setCallProperties] = useVoiceContext();
  const [logTableDataSource, setLogTableDataSource] = useState();
  const [callLogs, setCallLogs] = useState([]);
  const { fetchData } = useHttp();
  const [selectedMenuItem, setSelectedMenuItem] = useState("all");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentRecordingUrl, setCurrentRecordingUrl] = useState(null);
  const [phoneNumberList, setPhoneNumberList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("all");
  const [voiceIntegId, setVoiceIntegId] = useState();
  const { getVoiceSavedNumbers } = useVoiceNumbers();
  const audioRef = useRef(null);

  const getSavedNumber = async () => {
    if (voiceIntegId) {
      setLoading(true);
      getVoiceSavedNumbers("all")
        .then((response) => {
          const phoneNumbers = response.map((item) => item.phoneNumber) || [];
          setPhoneNumberList(phoneNumbers);
        })
        .catch((error) => console.error("Error fetching or parsing data:", error))
        .finally(() => setLoading(false));
    }
  };

  const openAudioPlayerModal = (url) => {
    setCurrentRecordingUrl(url);
    setIsModalOpen(true);
  };

  const onAudioPlayerModalClose = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setIsModalOpen(false);
    setCurrentRecordingUrl(null);
  };

  const handleClickCall = (customerNumber, phoneNumber, status) => {
    setCallProperties((prev) => ({
      ...prev,
      isDialPadVisible: true,
      callerNumber: customerNumber,
      callType: "outbound",
    }));
  };
  useEffect(() => {
    if (appProperties?.installedApps) {
      const voiceIntegrations = appProperties?.installedApps?.filter((integration) => integration.right.serviceType === "VOICE");
      if (voiceIntegrations?.[0]?.integProps?.integId) {
        setVoiceIntegId(voiceIntegrations?.[0]?.integProps?.integId);
      }
    }
  }, [appProperties?.installedApps]);

  useEffect(() => {
    const fetchCallLogs = async () => {
      try {
        setLoading(true);
        const response = await fetchData("voice/log", "GET", null, appProperties);
        const responseData = JSON.parse(response);
        setCallLogs(responseData?.data || []);
      } catch (error) {
        console.error("Error fetching Call Logs", error);
      } finally {
        setLoading(false);
      }
    };
    if (voiceIntegId) {
      fetchCallLogs();
      getSavedNumber();
    }
  }, [voiceIntegId]);

  useEffect(() => {
    const featureName = getCurrentFeature(appProperties);
    if (featureName === "Message") {
      setUpgradeAlertPopup(true);
    }
  }, [appProperties]);

  const callHeaderItems = [
    { label: "All", key: "all" },
    { label: "Inbound", key: "inbound" },
    { label: "Outbound", key: "outbound" },
    { label: "Missed Calls", key: "missedCalls" },
    { label: "Voice Mail", key: "voicemail" },
  ];

  const callHeaderColumns = [
    {
      title: (
        <>
          <div className="d-flex flex-column justify-content-start"> Contacts </div>
        </>
      ),
      dataIndex: "contacts",
      key: "contacts",
      ellipsis: true,
      width: 350,
    },
    { title: "Phone Number", dataIndex: "phoneNumber", key: "phoneNumber", ellipsis: true },
    { title: "Date & Time", dataIndex: "dateAndTime", key: "dateAndTime" },
    { title: "Duration", dataIndex: "duration", key: "duration" },
    { title: "Notes", dataIndex: "notes", key: "notes" },
    { title: "Recordings", dataIndex: "recordings", key: "recordings" },
    { title: "Voice Mail", dataIndex: "voiceMail", key: "voiceMail" },
  ];

  const phoneNumberOptions = [
    { label: "All", value: "all" },
    ...phoneNumberList.map((number) => ({
      label: number,
      value: number,
    })),
  ];

  const onPhoneNumberChange = (value) => {
    setSelectedPhoneNumber(value);
  };

  const callIconHandler = (callDirection, callStatus) => {
    if (callDirection === "INBOUND" && callStatus === "missed") {
      return "callActionIcons missedCallIcon";
    } else if (callDirection === "INBOUND") {
      return "callDirection-inbound callActionIcons";
    } else if (callDirection === "OUTBOUND") {
      return "callDirection-outbound callActionIcons";
    }
  };

  const callStatusHandler = (callDirection, callStatus, participantName) => {
    if (callDirection === "INBOUND" && callStatus === "ringing") {
      return "Rejected";
    } else if (callDirection === "INBOUND" && callStatus === "missed") {
      return "Missed";
    } else if (callDirection === "INBOUND") {
      return "Attended by " + participantName;
    } else if (callDirection === "OUTBOUND" && callStatus === "missed") {
      return "Unanswered";
    } else if (callDirection === "OUTBOUND") {
      return "Initiated by " + participantName;
    }
  };

  const handleCallHistoryInfoClick = (item) => {
    console.log("Inside last-name CLICK>>>>");
    appProperties?.controller?.openRecord(callProperties?.loadedSDK, item?.associatedObjectId, item?.associatedObjectType);
  };
  const formatCallLog = (call, index) => {
    const formattedDate = dayjs(call?.createdTime).format("D MMM YYYY h:mm A");
    const formattedDuration = dayjs.utc(call?.callDuration * 1000).format("HH:mm:ss");
    const recordingUrl = Array.isArray(call?.recordingUrls) ? call?.recordingUrls.find((url) => url !== null) : null;
    const isMissed = call?.callStatus === "missed" || call?.callStatus === "ringing";
    const isCompleted = call?.callStatus === "completed";
    const voiceMailRecording = call?.voiceMailRecording;
    const callDirectionClass = call?.callDirection === "INBOUND" ? "callDirection-inbound" : "callDirection-outbound";
    const customerNumber = call?.callDirection === "INBOUND" ? call?.fromNumber : call?.toNumber;
    const phoneNumber = call?.callDirection === "INBOUND" ? call?.toNumber : call?.fromNumber;
    return {
      key: index + 1,
      contacts: (
        <div className="d-flex flex-row align-items-center contact-item">
          <div className="col-1">
            <div className={`${callIconHandler(call?.callDirection, call?.callStatus)}`} />
          </div>
          <div className="d-flex flex-column justify-content-start ms-3">
            <div>
              {customerNumber}
              <div className="call-icon ms-0">
                <div className="callActionIcons logs-clickToCall-Icon" onClick={() => handleClickCall(customerNumber, phoneNumber)} />
              </div>
            </div>
            <div className="ms-1">
              {/* {isCompleted
                ? call?.callDirection === "INBOUND"
                  ? `Attended by ${call?.participantName}`
                  : `Initiated by ${call?.participantName}`
                : call?.callDirection === "INBOUND"
                ? "missed"
                : "unanswered"} */}
              {callStatusHandler(call?.callDirection, call?.callStatus, call?.participantName)}
            </div>
          </div>
        </div>
      ),
      phoneNumber: phoneNumber,
      dateAndTime: formattedDate,
      duration: call.callDuration ? formattedDuration : "00:00:00",
      notes:
        call?.callDescription && call?.callDescription.trim() ? (
          <Tooltip
            placement="rightTop"
            title={<div style={{ maxHeight: "150px", overflowY: "auto", whiteSpace: "pre-wrap" }}>{call?.callDescription}</div>}
          >
            <div className="callDescriptionIcon callActionIcons" />
          </Tooltip>
        ) : (
          <div className="ms-2">-</div>
        ),

      recordings:
        isCompleted && recordingUrl ? (
          <div className="callActionIcons recordingsPlayIcon cursor-pointer" onClick={() => openAudioPlayerModal(recordingUrl)}></div>
        ) : (
          "-"
        ),
      voiceMail:
        isMissed && voiceMailRecording ? (
          <div className="callActionIcons recordingsPlayIcon cursor-pointer" onClick={() => openAudioPlayerModal(voiceMailRecording)}></div>
        ) : (
          "-"
        ),
    };
  };
  console.log("setOpenCallDetails >>>>>>>>>>>>>>>", setOpenCallDetails);
  const handleOpenCallDetails = (item) => {
    setOpenCallDetails(true);
    setCallDetails(item);
  };
  useEffect(() => {
    let filteredLogs = [];
    const filterByDirectionAndStatus = (direction, status) => {
      return callLogs.filter(
        (call) =>
          (direction === "ALL" || call.callDirection === direction) &&
          (status === "ALL" || call.callStatus === status) &&
          (selectedPhoneNumber !== "all" ? call.toNumber === selectedPhoneNumber || call.fromNumber === selectedPhoneNumber : true)
      );
    };
    switch (selectedMenuItem) {
      case "all":
        filteredLogs = filterByDirectionAndStatus("ALL", "ALL");
        break;

      case "inbound":
        filteredLogs = filterByDirectionAndStatus("INBOUND", "completed");
        break;

      case "outbound":
        filteredLogs = filterByDirectionAndStatus("OUTBOUND", "completed");
        break;

      case "missedCalls":
        filteredLogs = filterByDirectionAndStatus("ALL", "missed");
        break;

      case "voicemail":
        filteredLogs = callLogs.filter(
          (call) => call.callStatus === "missed" && call?.voiceMailRecording
          // &&
          // call.voiceMailRecording.some((url) => url !== null) &&
          // (selectedPhoneNumber !== "all" ? call.toNumber === selectedPhoneNumber || call.fromNumber === selectedPhoneNumber : true)
        );
        console.log("filteredLogs >>>>>>>>>>>>>>>", filteredLogs);
        break;

      default:
        filteredLogs = [];
        break;
    }

    setLogTableDataSource(filteredLogs.map((call, index) => formatCallLog(call, index)));
  }, [selectedMenuItem, selectedPhoneNumber, callLogs]);

  function formatTime(createdTime) {
    return dayjs(createdTime).format("hh:mm A");
  }
  const formatDate = (date) => {
    return dayjs(date).format("DD MMM");
  };
  const formatTimeDisplay = (createdTime) => {
    const now = new Date();
    const createdDate = new Date(createdTime);

    const nowDateOnly = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const createdDateOnly = new Date(createdDate.getFullYear(), createdDate.getMonth(), createdDate.getDate());

    const differenceInDays = Math.floor((nowDateOnly - createdDateOnly) / (1000 * 60 * 60 * 24));

    if (differenceInDays === 0) {
      return formatTime(createdDate);
    } else if (differenceInDays === 1) {
      return "Yesterday";
    } else {
      return formatDate(createdDate);
    }
  };

  return (
    <>
      {!mobileView ? (
        <div className="d-flex flex-column w-100 hs-h-90vh overflow-hidden">
          <div className="d-flex hs-h-7 align-items-center">
            <Row className="d-flex hs-100 align-items-center">
              <Col className="callActionIcons info-icon" />
              <Col>
                To learn more about the Call Logs Page{" "}
                <a href="#" target="_blank" rel="noreferrer" className="custom-anchor">
                  Click here.
                </a>
              </Col>
            </Row>
          </div>
          <div className="bg-white h-100 hs-h-92">
            <Menu onClick={(e) => setSelectedMenuItem(e.key)} selectedKeys={[selectedMenuItem]} mode="horizontal" items={callHeaderItems} />
            {loading ? (
              <Row className="w-100 h-100 d-flex justify-content-center align-items-center">
                <Spin indicator={<LoadingOutlined spin className="mt-5" />} />
              </Row>
            ) : (
              <>
                <div className="ps-3 d-flex justify-content-start align-items-center hs-max-w-20-rem hs-h-7 mb-4">
                  <VoiceSelect
                    optionSpecification={false}
                    options={phoneNumberOptions}
                    value={selectedPhoneNumber}
                    onChange={(value) => onPhoneNumberChange(value)}
                  />
                </div>
                <div className="hs-h-85">
                  <Table
                    className="m-0 voiceNumberTable h-100 phoneAddTable"
                    dataSource={logTableDataSource}
                    columns={callHeaderColumns}
                    locale={{
                      emptyText: (
                        <EmptyListView
                          icon={"callCardIcons emptyVoiceCallListView display-inline-block"}
                          tagLineOne={"No Call Record"}
                          tagLineTwo={"Your Recent Record will appear here"}
                          tagHeight={"50vh"}
                        />
                      ),
                    }}
                    pagination={false}
                    scroll={{
                      y: 70 * 10,
                    }}
                  />
                </div>
              </>
            )}
          </div>
          <Modal className="audio-player" open={isModalOpen} onCancel={onAudioPlayerModalClose} footer={null}>
            <audio ref={audioRef} src={currentRecordingUrl} controls />
          </Modal>

          {upgradeAlertPopup && featureUpgradeAlertPopup(appProperties, upgradeAlertPopup, setUpgradeAlertPopup)}
        </div>
      ) : (
        <div>
          <div
            className="p-3"
            style={{
              maxHeight: "620px",
              overflowY: "auto",
            }}
          >
            <List
              rootClassName="callHistoryList"
              itemLayout="horizontal"
              loading={callLogs.length > 0 ? false : true}
              dataSource={callLogs}
              renderItem={(item, index) => {
                const isCompleted = item?.callStatus === "completed";
                const customerNumber = item?.callDirection === "INBOUND" ? item?.fromNumber : item?.toNumber;
                const phoneNumber = item?.callDirection === "INBOUND" ? item?.toNumber : item?.fromNumber;
                const status = true;
                const durationInSeconds = item?.callDuration || 0;
                const hours = Math.floor(durationInSeconds / 3600);
                const recordingUrl = Array.isArray(item?.recordingUrls) ? item?.recordingUrls.find((url) => url !== null) : null;
                const callNotes = item?.callDescription ? item?.callDescription.trim() : null;
                const minutes = Math.floor((durationInSeconds % 3600) / 60);
                const seconds = durationInSeconds % 60;
                let formattedDuration = "";
                if (seconds > 0) {
                  formattedDuration = ` ${seconds}s`;
                }

                if (minutes > 0) {
                  formattedDuration = `${minutes}m ${seconds}s`;
                }

                if (hours > 0) {
                  formattedDuration = `${hours}h ${formattedDuration}`;
                }
                return (
                  <List.Item className="text-white call-history-item cursor-pointer" onClick={() => handleOpenCallDetails(item)}>
                    <List.Item.Meta
                      className="text-white d-flex align-items-center"
                      avatar={
                        item?.callStatus === "missed" || item?.callStatus === "ringing" ? (
                          <div className="callActionIcons missedCallIcon" />
                        ) : item?.callStatus === "completed" ? (
                          item?.callDirection === "INBOUND" ? (
                            <div className={`callActionIcons callDirection-inbound`} />
                          ) : (
                            <div className={`callActionIcons callDirection-outbound`} />
                          )
                        ) : (
                          <div className="ms-4" />
                        )
                      }
                      title={
                        <div className="call-title-container">
                          <span className="text-white hs-fs-13 hs-fw-500">
                            {item?.callDirection === "INBOUND" ? item?.fromNumber : item?.toNumber}
                          </span>
                          <span className="call-icon">
                            <div
                              className="callActionIcons logs-clickToCall-Icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDialPadHistoryClickToCall(customerNumber, phoneNumber, status);
                              }}
                            />
                          </span>
                        </div>
                      }
                      description={
                        <div
                          className={` hs-fw-400 hs-fs-12 ${
                            item?.callStatus === ("missed" || "ringing") && item?.callDirection === "INBOUND"
                              ? "hs-color-red"
                              : "hs-color-md-grey"
                          }`}
                        >
                          {isCompleted
                            ? item?.callDirection === "INBOUND"
                              ? `Attended by ${item?.participantName}`
                              : `Initiated by ${item?.participantName}`
                            : item?.callDirection === "INBOUND" && item?.callStatus === "missed"
                            ? "missed"
                            : item?.callDirection === "INBOUND" && item?.callStatus === "ringing"
                            ? "Rejected"
                            : "Unanswered"}
                          <div className="d-flex flex-row">
                            <div className="hs-fs-11">{formatTimeDisplay(item?.createdTime)}</div>
                            {formattedDuration && (
                              <div className="d-flex flex-row">
                                <div className="callActionIcons dot-icon" />
                                <div>{formattedDuration}</div>
                              </div>
                            )}
                            {recordingUrl && <div className="callActionIcons call-history-callRecord-icon" />}
                            {callNotes && <div className="callActionIcons call-history-callNote-icon " />}
                          </div>
                        </div>
                      }
                    />
                    <div
                      className="call-details-icon callActionIcons cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCallHistoryInfoClick(item);
                      }}
                    />
                  </List.Item>
                );
              }}
              locale={{
                emptyText: (
                  <EmptyListView
                    tagLineOne={<div className="text-white">No call history available</div>}
                    icon={`callActionIcons empty-call-history-icon  display-inline-block`}
                    tagLineTwo={`Your recent calls will appear here`}
                    tagHeight={"43vh"}
                  />
                ),
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default VoiceCallLog;
