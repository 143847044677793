// import { Select } from 'antd'
import HSelect from "../../../components/custom/input/select/Select";
import React from "react";
import UploadDragger from "./UploadDragger";

const VoiceSelect = ({
  value,
  onChange,
  options,
  props,
  loading,
  fileName,
  textToSpeech,
  setTextToSpeech,
  url,
  setUrl,
  optionSpecification,
  label,
}) => {
  const handleTextToSpeech = (e) => {
    setTextToSpeech(e.target.value);
  };
  const handleUrlUpdate = (e) => {
    setUrl(e.target.value);
    setTextToSpeech("");
  };
  return (
    <div className="voiceSelect w-100">
      <label className="hs-fs-16 mb-1">{label}</label>
      <br />
      <div className="w-75">
        <HSelect
          customProps={{
            onSelect: onChange,
            value: value,
            className: "music-type-select typesOfPlayAudio",
          }}
          defaultValue={value}
          options={options}
          enableSearch={false}
          // customProps={{
          //     className: "music-type-select typesOfPlayAudio",
          // }}
          suffixIcon={<div className="callActionIcons callDropdownIcon" />}
          onChange={onChange}
        />
      </div>
      {optionSpecification &&
        (value === "textToSpeech" ? (
          <div className="mt-4">
            <label className="hs-fs-16">Text</label>
            <br />
            <textarea className="speechTextArea" placeholder="Text goes here" onChange={handleTextToSpeech} value={textToSpeech}></textarea>
          </div>
        ) : value === "playAudio" ? (
          <div className="mt-4">
            <label className="hs-fs-16">Upload Music</label>
            <UploadDragger props={props} loading={loading} fileName={fileName} />
          </div>
        ) : value === "url" ? (
          <div className="mt-4">
            <label className="hs-fs-16">Link</label>
            <br />
            <textarea className="speechTextArea" placeholder="Paste the link" onChange={handleUrlUpdate} value={url}></textarea>
          </div>
        ) : (
          ""
        ))}
    </div>
  );
};

export default VoiceSelect;
