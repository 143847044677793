import { Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import CustomerInformationComponent from "../common/CustomerInformationComponent";
import Timer from "../timer/Timer";
import DialPadKeys from "./DialPadKeys";

const CallAnswered = (props) => {
  let {
    lastName,
    endCall,
    callEnded,
    setCallEnded,
    module,
    callObject,
    callNotes,
    setCallNotes,
    callStatus,
    totalCallDuration,
    callNotesFormName,
    setTotalCallDuration,
    callerImageComponent,
    recordId,
    recordLink,
    searchValue,
    appProperties,
    customerNumber,
    skeletonImage,
    skeletonField,
    avatar,
    callProperties,
    customerInfoDetails,
  } = props;
  const [enteredNumber, setEnteredNumber] = useState("");
  const [mute, setMute] = useState(false);
  const [description, setDescription] = useState("");
  const [keyPad, setKeyPad] = useState(false);
  const [pauseTimer, setPauseTimer] = useState(false);

  const handleKeyPad = () => {
    setKeyPad(!keyPad);
  };
  const handleMute = () => {
    const onMute = !mute;
    setMute(onMute);
    if (callObject) {
      callObject.mute(onMute);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div className={`${callStatus} overflow-auto h-100`}>
      <CustomerInformationComponent
        callerImageComponent={callerImageComponent}
        lastName={lastName}
        module={module}
        appProperties={appProperties}
        recordId={recordId}
        recordLink={recordLink}
        endCall={endCall}
        searchValue={searchValue}
        customerNumber={customerNumber}
        skeletonImage={skeletonImage}
        skeletonField={skeletonField}
        avatar={avatar}
        callProperties={callProperties}
        showEndCallBtn={true}
        customerInfoDetails={customerInfoDetails}
      />
      <div className="text-white hs-fw-500 hs-fs-18 w-100">
        <Row className="hs-h-45 w-100">
          <div className="w-100 d-flex justify-content-center">
            <Timer
              pause={pauseTimer}
              setTotalCallDuration={setTotalCallDuration}
              totalCallDuration={totalCallDuration}
              callEnded={callEnded}
              setCallEnded={setCallEnded}
            />
          </div>
        </Row>
        {mute === true && (
          <Row className="hs-h-45 w-100">
            <div className="w-100 d-flex justify-content-center align-items-center">
              <div className="call-actions-text hs-h-7">
                <span className="mute-text">Muted</span>
              </div>
            </div>
          </Row>
        )}
      </div>

      {keyPad === true ? (
        <div className="p-4 hs-h-45 w-100 pt-0">
          <div className="hs-h-10 d-flex align-items-center w-100 justify-content-evenly">
            <div className="enteredNumbers d-flex justify-content-center w-100">
              {enteredNumber}
              {enteredNumber ? (
                <div className="d-flex justify-content-end">
                  <div className="clearIcon d-flex justify-content-end" onClick={() => setEnteredNumber("")}></div>
                </div>
              ) : null}
            </div>
          </div>

          <DialPadKeys numbers={enteredNumber} setNumbers={setEnteredNumber} twilioDevice={callObject} />
        </div>
      ) : (
        <div className="p-4 hs-h-45">
          <Form form={callNotesFormName} name={"callDescription"} rootClassName="h-100 w-100 call-textarea">
            <Form.Item name="callDescription" initialValue={callNotes} rootClassName="h-100 w-100">
              <textarea className="w-100 h-100 hs-border-16 p-2 ps-3" placeholder="Enter Call Description" />
            </Form.Item>
          </Form>
        </div>
      )}

      <div className="hs-h-20 d-flex align-items-center w-100 ps-4 pe-4">
        <div className="d-flex justify-content-center align-items-center flex-row  dialPadActionIcons  w-100">
          <div className={`${mute ? "onMuteIcon  cursor-pointer" : "muteIcon  cursor-pointer"}`} onClick={handleMute} />
          <div className={`${keyPad ? "onKeyPadIcon  cursor-pointer" : "keyPadIcon  cursor-pointer"}`} onClick={handleKeyPad} />
        </div>
      </div>
    </div>
  );
};

export default CallAnswered;
