import { Divider, Menu, Switch, Table, Skeleton, Drawer, message, Input, Spin, Tour, Button, Card } from "antd";
import React, { useState, useEffect, useContext, useRef } from "react";
import useHttp from "../../hooks/useHttp";
import Dragger from "antd/es/upload/Dragger";
import useAttachment from "../../hooks/useAttachment";
import { AppContext } from "../../context/AppContext";
import { LoadingOutlined } from "@ant-design/icons";
import onBoardingPhoneSwitch from "../../assets/gif/onBoardingPhoneSwitch.gif";
import { urlParams } from "./../../constants/AppConstants";
import { useVoiceNumbers } from "../../voice/hooks/useVoiceNumbers";
import Dialpad from "../../voice/components/Dialpad";
import { initialNodes, initialEdges } from "../../voice/components/nodes-edges";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallFlow } from "../../voice/hooks/useCallFlow";
import "../../voice/assets/css/voiceConfigPhone.css";
import { getCurrentFeature } from "../../voice/utils/VoiceUtils";
import { featureUpgradeAlertPopup } from "../utils/CommonNotifications";

const VoiceConfigPhone = ({ rightServiceObj, setShowFinishButton }) => {
  const { getCallFlow } = useCallFlow();
  const [upgradeAlertPopup, setUpgradeAlertPopup] = useState(false);
  const ref1 = useRef(null);
  const { getVoiceSavedNumbers, deleteVoiceSavedNumber, addNumber } = useVoiceNumbers();
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const [fetchingNumberList, setFetchingNumberList] = useState(true);
  const [loading, setLoading] = useState(false);
  const [phoneNumberList, setPhoneNumberList] = useState([]);
  const [voiceNumberList, setVoiceNumberList] = useState([]);
  const [savedNumbers, setSavedNumbers] = useState([]);
  const { fetchData } = useHttp();
  const [isEnabled, setIsEnabled] = useState({});
  const [appProperties] = useContext(AppContext);
  const [callFlows, setCallFlows] = useState([]);
  const [switchLoading, setSwitchLoading] = useState({});
  const integId = rightServiceObj?.integId;
  const authorized = rightServiceObj?.authorized;

  const [tourStart, setTourStart] = useState(false);
  const [onBoarding, setOnBoarding] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState(0);
  let onBoardingPage = urlParams.get("onBoardingPage");

  useEffect(() => {
    const featureName = getCurrentFeature(appProperties);
    if (featureName === "Message") {
      setUpgradeAlertPopup(true);
    }
  }, [appProperties]);

  const onBoardingTourSteps = [
    {
      description: (
        <>
          <img alt="onBoardingPhoneSwitch.gif" style={{ height: "70px" }} src={onBoardingPhoneSwitch} />
        </>
      ),
      placement: "right",
      target: () => ref1.current,
    },
  ];

  const handleClickOutside = (event) => {
    if (ref1.current && !ref1.current.contains(event.target)) {
      setTourStart(false);
    }
  };

  useEffect(() => {
    if (onBoardingPage === "true") {
      setTourStart(true);
    }
  }, [onBoardingPage]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const columns = [
    {
      title: (
        <div className="d-flex flex-row">
          <div className="ms-2">Numbers</div>
          <div className="callActionIcons numbersExclamatoryIcon " />
        </div>
      ),
      dataIndex: "numbers",
      key: "numbers",
    },
    {
      title: <div className="me-4">Call Flow</div>,
      dataIndex: "callFlow",
      key: "callFlow",
    },
    // {
    //   title: <div className="">Hold Music</div>,
    //   dataIndex: "holdMusic",
    //   key: "holdMusic",
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  const handleSwitchChange = async (checked, phoneNumber) => {
    setSwitchLoading((prev) => ({ ...prev, [phoneNumber]: true }));
    console.log("IRDPPP Switching phone number...checked >>", checked, ":: phone Number >>>>", phoneNumber);
    const payload = {
      phoneNumber: phoneNumber,
      nodes: initialNodes,
      edges: initialEdges,
    };
    console.log("IRDPPP payload>>>>", payload, integId);
    try {
      if (checked) {
        setIsEnabled((prev) => ({ ...prev, [phoneNumber]: true }));
        console.log("IRDPPP Saving phone number...");
        const response = await addNumber(payload, integId);
        if (response) {
          if (response) {
            setShowFinishButton(true);
          }
          goToNextStep();
          setSavedNumbers((prev) => [...prev, response]);
          message.success("Phone number saved successfully.");
        } else {
          message.error("An error occurred while saving the phone number. Please try again.");
        }
      } else {
        try {
          setIsEnabled((prev) => ({ ...prev, [phoneNumber]: false }));
          console.log("Deleting phone number...");
          goToNextStep();

          await deleteVoiceSavedNumber(phoneNumber).then((response) => {
            setSavedNumbers((prevNumberData) => prevNumberData.filter((num) => num.phoneNumber !== phoneNumber));
            message.success("Phone number deleted successfully.");
          });
        } catch (error) {
          console.error("Error while deleting phone number:", error);
          message.error("An error occurred while deleting the phone number.");
        }
      }
    } catch (error) {
      console.error("Error in handling phone number:", error);
      message.error("An error occurred while processing your request.");
    } finally {
      setSwitchLoading((prev) => ({ ...prev, [phoneNumber]: false }));
    }
  };

  useEffect(() => {
    if (integId) {
      setFetchingNumberList(true);
      fetchData(`omessage/${integId}/numbers?capabilities=voice`, "GET", null, appProperties)
        .then((response) => {
          const responseData = JSON.parse(response);
          const nestedData = JSON.parse(responseData?.data?.data || "{}");
          const phoneNumbers = nestedData?.phone || [];
          const voiceNumbers = nestedData?.voice || [];
          setPhoneNumberList(phoneNumbers);

          setVoiceNumberList(voiceNumbers);
        })
        .catch((error) => console.error("Error fetching or parsing data:", error))
        .finally(() => setFetchingNumberList(false));
    }
  }, [integId]);
  useEffect(() => {
    const loadCallFlows = async () => {
      setLoading(true);
      try {
        const res = await getCallFlow();
        if (res.length > 0) {
          setCallFlows(res);
        }
      } catch (error) {
        console.error("Error parsing response data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadCallFlows();
  }, []);
  useEffect(() => {
    if (integId) {
      console.log("integId>>>>>SavedNumber", integId);
      getVoiceSavedNumbers("all")
        .then((response) => {
          if (response) {
            setSavedNumbers(response);
            const fileNames = {};
            response?.forEach((number) => {
              fileNames[number.phoneNumber] = number.holdMusicFileName || "default.mp3";
            });
          }
        })
        .catch((err) => {
          console.log("Error fetching SavedNumbers:", err);
        });
    }
  }, [integId]);

  const findCallFlowNameById = (callFlowId, savedNumber) => {
    const flow = callFlows.find((flow) => flow.callFlowId === callFlowId);
    return flow?.callFlowName ? flow?.callFlowName : savedNumber?.callFlowName ? savedNumber?.callFlowName : "Call Flow";
  };

  const navigateCallFlow = (callFlowId, phoneNumber) => {
    try {
      const url = new URL(window.location.href);

      // Get the search parameters
      const params = new URLSearchParams(url.search);

      params.delete("sideBar");
      params.delete("onBoardingPage");
      params.delete("onInstall");

      // Change the endpoint to '/users'
      const newEndpoint = `/voice/callFlow/edit/${callFlowId}/phoneNumber/${encodeURIComponent(phoneNumber)}`;

      // Construct the new URL
      const newUrl = `${url.origin}${newEndpoint}?${params.toString()}`;

      // Update the URL in the browser without reloading
      window.history.pushState({}, "", newUrl);
      window.location.reload();
    } catch (error) {
      console.log("Error fetching callflowdata", error);
    }
  };

  const dataSource = phoneNumberList.map((num, index) => {
    const savedNumber = savedNumbers?.find((element) => element?.phoneNumber === num);
    const callFlowName = savedNumber ? findCallFlowNameById(savedNumber.callFlowId, savedNumber) : "-";
    return {
      key: index,
      numbers: (
        <div className=" me-2" style={{ fontWeight: 600 }}>
          {num}
        </div>
      ),
      callFlow: (
        <div style={{ fontWeight: 500, cursor: "default" }}>
          {callFlowName}
          {callFlowName !== "-" && (
            <span
              ref={ref3}
              className={isEnabled[num] || savedNumbers.some((element) => element.phoneNumber === num) ? "enabled-span" : "disabled-span"}
              onClick={() => {
                navigateCallFlow(savedNumber?.callFlowId, num, savedNumber);
              }}
            >
              {" "}
              Edit
            </span>
          )}
        </div>
      ),
      status: (
        <Switch
          ref={index === 0 ? ref1 : null}
          loading={switchLoading[num]}
          checked={savedNumbers?.some((element) => element?.phoneNumber === num)}
          className="addPhoneSwitch"
          onClick={(e) => handleSwitchChange(e, num)}
        />
      ),
    };
  });

  const goToNextStep = () => {
    setCurrentStep((prevStep) => (prevStep + 1 < onBoardingTourSteps.length ? prevStep + 1 : 0));
  };

  return (
    <>
      <div className="d-flex align-items-center mt-2" style={{ backgroundColor: "#fff" }}>
        <div className="w-100 mt-2 ms-2">
          {fetchingNumberList ? (
            <Skeleton className="w-100%" paragraph={{ rows: 4 }} active />
          ) : (
            <div className="">
              <Table
                // scroll={{ y: 350 }}
                pagination={false}
                className="phoneAddTable voiceNumberTable ms-0"
                columns={columns}
                dataSource={dataSource}
                style={{ width: "750px" }}
              />
              <Tour
                rootClassName="voice-tour"
                closeIcon={false}
                open={tourStart}
                onClose={() => setTourStart(false)}
                steps={onBoardingPage === "true" ? onBoardingTourSteps : onBoardingTourSteps}
                // current={currentStep}
                // onChange={setCurrentStep}
              />
            </div>
          )}
        </div>
      </div>
      {onBoarding && (
        <div className=" " ref={ref2} style={{ marginTop: "180px", marginLeft: "600px", width: 120, height: 100 }}>
          <Dialpad />
        </div>
      )}

      {upgradeAlertPopup && featureUpgradeAlertPopup(appProperties, upgradeAlertPopup, setUpgradeAlertPopup)}
    </>
  );
};

export default VoiceConfigPhone;
