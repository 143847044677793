import React, { useContext, useState } from "react";
import { message, Switch, Button } from "antd";
import useHttp from "../../../hooks/useHttp";
import { AppContext } from "../../../context/AppContext";
import VoiceSelect from "../common/VoiceSelect";
import HButton from "../../../components/custom/input/button/Button";

const CallRecording = ({ id, nodeId, callFlowId, recordData, setRecordData }) => {
  const [loading, setLoading] = useState(false);
  const { fetchData } = useHttp();
  const [appProperties] = useContext(AppContext);

  const handleApply = async () => {
    if (recordData?.file && recordData?.selectType === "playAudio") {
      setRecordData({
        ...recordData,
        music: recordData?.file,
        type: "Audio File",
        apply: true,
      });
      message.success(`${recordData?.recordMusicFile?.name} uploaded successfully.`);
    } else if (recordData?.text && recordData?.selectType === "textToSpeech") {
      setRecordData({
        ...recordData,
        music: recordData?.text,
        type: "TTS",
        apply: true,
      });
      message.success(` Applied successfully.`);
    } else if (recordData?.url && recordData?.selectType === "url") {
      setRecordData({
        ...recordData,
        music: recordData?.url,
        type: "URL",
        apply: true,
      });
      message.success(` Applied successfully.`);
    }
  };

  const props = {
    name: "file",
    multiple: false,
    accept: ".mp3,.wav",
    onChange(info) {
      const { status } = info.file;
      if (info) {
        setRecordData({
          ...recordData,
          fileName: info.file.name + " or",
          file: info.file.originFileObj,
        });
        console.log("info>>", info.file.originFileObj);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
      const droppedFile = e.dataTransfer.files[0];
      if (droppedFile) {
        setRecordData({
          ...recordData,
          fileName: droppedFile.name + " or",
          file: droppedFile,
        });
        console.log("Dropped file>>", droppedFile);
      }
    },
  };

  const onOutgoingCheckChange = (value) => {
    const isChecked = value.target.checked;
    setRecordData({ ...recordData, recordOutbound: isChecked });
    if (isChecked) {
      message.success("Applied Record for Outgoing Calls");
    }
  };

  const handleTypeChange = (value) => {
    setRecordData({ ...recordData, selectType: value });
  };

  const options = [
    { value: "textToSpeech", label: "Text to speech" },
    { value: "playAudio", label: "Play Audio" },
    { value: "url", label: "Url" },
  ];

  const handleCallRecordStatusChange = (status) => {
    setRecordData({
      ...recordData,
      recordStatus: status ? 1 : 0,
      apply: true,
    });
  };

  return (
    <>
      <div className="d-flex flex-row justify-content-between mb-4">
        <h6>Call Recording</h6>
        <Switch
          className="addPhoneSwitch"
          onChange={handleCallRecordStatusChange}
          checked={recordData?.recordStatus === 1 ? true : false}
        ></Switch>
      </div>
      {recordData?.recordStatus === 1 && (
        <>
          <h6 className=" mt-4 hs-fs-16">Record Music</h6>
          <br />
          <VoiceSelect
            onChange={handleTypeChange}
            options={options}
            value={recordData?.selectType}
            props={props}
            loading={loading}
            fileName={recordData?.fileName}
            textToSpeech={recordData?.text}
            setTextToSpeech={(value) =>
              setRecordData((prevState) => ({
                ...prevState,
                text: value,
              }))
            }
            url={recordData?.url}
            setUrl={(value) =>
              setRecordData((prevState) => ({
                ...prevState,
                url: value,
              }))
            }
            optionSpecification={true}
            label={"Type"}
          />

          <HButton buttonClassName="hs-fs-16 px-3 mt-2" onClick={handleApply} size="s">
            Apply
          </HButton>
        </>
      )}
    </>
  );
};

export default CallRecording;
