import React from 'react'
import { useContext } from 'react'
import useHttp from '../../hooks/useHttp'
import { AppContext } from '../../context/AppContext';
export const useCallFlow = () => {
    const { fetchData } = useHttp();
    const [appProperties] = useContext(AppContext);
    const getCallFlow = () => {
        return new Promise((resolve, reject) => {
            fetchData(`voice/callFlow`, "GET", null, appProperties).then((response) => {
                if (response) {
                    const resData = JSON.parse(response);
                    resolve(resData?.data);
                }
            })
        })
    }
    return { getCallFlow }
}
