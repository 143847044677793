import React, { useContext, useEffect, useState } from "react";
import Input from "../../../components/custom/input/input/Input";
import { Col, Form, Row } from "antd";
import HButton from "../../../components/custom/input/button/Button";
import useHttp from "../../../hooks/useHttp";
import { AppContext } from "../../../context/AppContext";
import { useVoiceContext } from "../../context/VoiceContext";
import { validateEmail } from "../../../pages/utils/commonUtils";

const CreateLeadOrContact = (props) => {
  const [form] = Form.useForm();
  let {
    module,
    setOpenCreateLeadOrContactForm,
    createModuleName,
    record,
    customerNumber,
    searchValue,
    setRecordId,
    setCustomerNumber,
    setCreateModuleName,
    setModule,
    setRecordLink,
    setLastName,
    callSid,
  } = props;
  console.log("CreateLeadOrContact createModuleName", createModuleName);
  const { fetchData } = useHttp();
  const [callProperties, setCallProperties] = useVoiceContext();
  const [appProperties] = useContext(AppContext);
  const [btnDisable, setBtnDisable] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const formData = {
        lastName: form.getFieldValue("lastName"),
        phone: form.getFieldValue("phoneNumber"),
        email: form.getFieldValue("email"),
        callSid: callSid,
      };
      console.log("Form data submitted:", formData);
      if (appProperties?.installedApps) {
        const voiceIntegrations = appProperties?.installedApps?.filter((integration) => integration.right.serviceType === "VOICE");
        if (voiceIntegrations?.[0]?.integProps?.integId) {
          if (formData?.lastName && formData?.email && formData?.phone) {
            setBtnLoading(true);
            fetchData(`voice/${voiceIntegrations?.[0]?.integProps?.integId}/${createModuleName}`, "POST", formData, appProperties).then(
              (response) => {
                let responseJson = typeof response === "string" ? JSON.parse(response) : response;
                let createdRecord = responseJson?.data?.record;
                if (createdRecord) {
                  setRecordId(createdRecord?.id);
                  setCustomerNumber(createdRecord?.phone);
                  setLastName(createdRecord?.lastName);
                  setCreateModuleName(createdRecord?.module);
                  setModule(createdRecord?.module);
                  setRecordLink(createdRecord?.recordLink);
                }

                setOpenCreateLeadOrContactForm(false);
                form.resetFields();
                setBtnLoading(false);
              }
            );
          } else {
            console.log("Please fill all the required fields");
          }
        }
      }
    } catch (error) {
      console.log("error", error);
      setBtnLoading(false);
    }
  };

  useEffect(() => {
    console.log("customerNumber >>>>>>>>>>>>>>> kjkjk : ", customerNumber);
    if (searchValue) {
      form.setFieldValue("phoneNumber", searchValue);
    }
    if (customerNumber) {
      form.setFieldValue("phoneNumber", customerNumber);
    }
  }, [customerNumber, searchValue]);

  const handleFormChange = (_, allValues) => {
    const { lastName, phoneNumber, email } = allValues;
    setBtnDisable(!(lastName && phoneNumber && email));
  };

  return (
    <div className="d-flex flex-column w-100 px-4 h-100">
      <Row className="d-flex hs-h-10 w-100" justify={"space-between"} align={"middle"}>
        <Col
          onClick={() => {
            setOpenCreateLeadOrContactForm(false);
          }}
        >
          <div className="d-flex flex-row align-items-center cursor-pointer">
            <div className="back-to-call-arrow-icon callActionIcons"></div>
            <div className="text-white hs-fw-500 hs-fs-12 hs-color-md-grey"> Back to Call</div>
          </div>
        </Col>

        <Col className="w-50">
          {callProperties?.callStatus === "answered" && (
            <div className="hs-bg-dark-green2 hs-xs-button hs-border-1-25-rem d-flex align-items-center justify-content-center">
              <div className="hs-color-light-green3 hs-fs-12 hs-fw-500"> Call is in progress</div>
            </div>
          )}
        </Col>
      </Row>

      <Row className="d-flex justify-content-center w-100 hs-h-65">
        <Form form={form} className="w-100 h-100 gap-3 d-flex flex-column justify-content-center" onValuesChange={handleFormChange}>
          <div className="text-white hs-fw-500 w-100 hs-h-3">Create {createModuleName}</div>
          <div className="d-flex flex-column hs-h-25 w-100">
            <Input
              customClasses="hs-h-35-px hs-bg-dark-grey-2 outline-none hs-color-light-grey-4 hs-fs-12"
              placeHolder="Enter name"
              formItemName="lastName"
              formItemLabel={<div className="hs-color-md-grey hs-fs-12">Name</div>}
              required={true}
              errorMessage="Please provide a name."
              autoFocus
            />
          </div>
          <div className="d-flex flex-column  hs-h-25 w-100">
            <Input
              customClasses="hs-h-35-px hs-bg-dark-grey-2 outline-none hs-color-light-grey-4 hs-fs-12"
              placeHolder="Enter a phone number"
              formItemName="phoneNumber"
              required={true}
              formItemLabel={<div className="hs-color-md-grey hs-fs-12">Phone Number</div>}
              errorMessage="Please provide a valid phone number."
              validator={(_, value) => {
                const phonePattern = /^\+?[0-9\s-]{10,15}$/;
                if (!value) {
                  return Promise.reject(new Error("Phone number is required."));
                }
                if (!phonePattern.test(value)) {
                  setBtnDisable(true);
                  return Promise.reject(new Error("Please provide a valid phone number."));
                }
                return Promise.resolve();
              }}
            />
          </div>
          <div className="d-flex flex-column   hs-h-25 w-100">
            <Input
              customClasses="hs-h-35-px hs-bg-dark-grey-2 outline-none hs-color-light-grey-4 hs-fs-12"
              placeHolder="Enter an email"
              formItemName="email"
              formItemLabel={<div className="hs-color-md-grey hs-fs-12">Email</div>}
              errorMessage="Please provide a valid email ID."
              required={true}
              validator={(_, value) => {
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!value) {
                  return Promise.reject(new Error("Email is required."));
                }
                if (!emailPattern.test(value)) {
                  setBtnDisable(true);
                  return Promise.reject(new Error("Please provide a valid email ID."));
                }
                return Promise.resolve();
              }}
            />
          </div>

          <HButton buttonClassName="" size="s" onClick={handleSubmit} loading={btnLoading} disabled={btnDisable}>
            Save
          </HButton>
        </Form>
      </Row>
    </div>
  );
};

export default CreateLeadOrContact;
