// export const getSMSGallery = (appName) => {
//     const smsGalleryForPipedrive = () =>
//       return [
//         {
//           headline: "Single and Bulk Messaging",
//           sourceUrl: "https://www.youtube.com/embed/aB6BTkcRBaM?si=7evfv3ukmLAh9Tul",
//           steps: ["Send WhatsApp messages to Pipedrive prospects", "Track all conversation history within Pipedrive"],
//         },
//         {
//           headline: "Automate SMS/WhatsApp message for Appointment Scheduling in Pipedrive",
//           sourceUrl: "https://www.youtube.com/embed/ojia6Q7VXYM?si=72NEoKwJYlOw8ais",
//           steps: ["Create a Webhook URL", "Set up automation in Pipedrive"],
//         },
//         {
//           headline: "Automate SMS/WhatsApp message When Deal Stages Change in Pipedrive",
//           sourceUrl: "https://www.youtube.com/embed/QMEwqIIxyI8?si=xYp5UV_UlaQL6ZZb",
//           steps: ["Send templated messages for each deal stage", "Boost conversions with timely engagement"],
//         },
//       ];

//     case "zohocrm":
//       return [
//         {
//           headline: "Boost Sales with Direct WhatsApp Messaging in Zoho CRM",
//           sourceUrl: "https://www.youtube.com/embed/Wtvb0a4FCis?si=wbH3DxkMm5IlZOFE",
//           steps: ["Send WhatsApp messages to Zoho CRM prospects", "Track all conversation history within Zoho CRM"],
//         },
//         {
//           headline: "Automate SMS and WhatsApp Reminders from Zoho CRM with HelloSend",
//           sourceUrl: "https://www.youtube.com/embed/BVLCy3R6C6E?si=CWHOgwAKK7eKaxpY",
//           steps: ["Save WhatsApp templates and turn on automation", "Drive more sales through engagement"],
//         },
//         {
//           headline: "Automate SMS / WhatsApp message When Deal Stages Change in Zoho CRM",
//           sourceUrl: "https://www.youtube.com/embed/41GWpzW8jXw?si=8Yn0cLu8HMVIy_jS",
//           steps: ["Send templated messages based on deal stage", "Engage at the right time and boost conversions"],
//         },
//       ];

//     default:
//       return [];
//   }
// };

export const getSMSGallery = (appName) => {
  if (appName?.toLowerCase() === "pipedrive") {
    return [
      {
        headline: "Single and Bulk Messaging",
        sourceUrl: "https://www.youtube.com/embed/aB6BTkcRBaM?si=7evfv3ukmLAh9Tul",
        steps: ["Send WhatsApp messages to Pipedrive prospects", "Track all conversation history within Pipedrive"],
      },
      {
        headline: "Automate SMS/WhatsApp message for Appointment Scheduling in Pipedrive",
        sourceUrl: "https://www.youtube.com/embed/ojia6Q7VXYM?si=72NEoKwJYlOw8ais",
        steps: ["Create a Webhook URL", "Set up automation in Pipedrive"],
      },
      {
        headline: "Automate SMS/WhatsApp message When Deal Stages Change in Pipedrive",
        sourceUrl: "https://www.youtube.com/embed/QMEwqIIxyI8?si=xYp5UV_UlaQL6ZZb",
        steps: ["Send templated messages for each deal stage", "Boost conversions with timely engagement"],
      },
    ];
  }

  // You can add more cases for different apps, or just return an empty array for unknown app names
  return [];
};
