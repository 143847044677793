import { Button, message, Switch } from 'antd';
import React from 'react';
const PhoneNumberNode = ({ id, data, nodeId, callFlowId, number, phoneNumberData, setPhoneNumberData }) => {
  console.log(nodeId, "nodeIdPhonenumber", callFlowId, number)
  console.log(phoneNumberData.selectType, "<<type>>")

  const props = {
    name: 'file',
    multiple: false,
    accept: ".mp3,.wav",
    onChange(info) {
      const { status } = info.file;
      if (info) {
        setPhoneNumberData({ ...phoneNumberData, holdMusicFileName: info.file.name + " or", holdMusicFile: info.file.originFileObj });
        console.log("info>>", info.file.originFileObj)
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
      const droppedFile = e.dataTransfer.files[0];
      if (droppedFile) {
        setPhoneNumberData({
          ...phoneNumberData,
          holdMusicFile: droppedFile,
          holdMusicFileName: droppedFile.name
        })
        console.log("Dropped file>>", droppedFile);
      }
    },
  };

  const handleApply = async () => {
    if (phoneNumberData?.fallBackNumber || phoneNumberData?.voiceMailStatus) {
      setPhoneNumberData({
        ...phoneNumberData,
        apply: true
      })
      message.success("Applied Successfully")
    }
  }

  const options = [
    { value: "textToSpeech", label: "Text to speech" },
    { value: "playAudio", label: "Play Audio" },
    { value: "url", label: "Url" }
  ]
  const handleVoiceMailStatusChange = (currentStatus) => {
    console.log("debug_t handleVoiceMailStatusChange", currentStatus);
    setPhoneNumberData({
      ...phoneNumberData,
      voiceMailStatus: currentStatus ? 1 : 0,
      apply: true
    })
  }

  return (
    <>
      <div>
        <label className="hs-fs-18 hs-fw-500 mb-3">Select Phone for this Call flow.</label>
        <label className="hs-fs-16 mt-1 mb-2">Fallback Number</label>
        <br />
        <input className="mt-2 fallbackInput"
          onChange={(e) => setPhoneNumberData({
            ...phoneNumberData,
            fallBackNumber: e.target.value
          })} value={phoneNumberData?.fallBackNumber}
        />
        <div className='d-flex flex-row  mb-4 mt-4'>
          <h6 >VoiceMail in Missed Calls</h6>
          <Switch className="addPhoneSwitch ms-3" onChange={handleVoiceMailStatusChange} checked={phoneNumberData?.voiceMailStatus === 1 ? true : false} ></Switch>

        </div>



        {/* <div className='mt-4'>
          <label className='hs-fs-16 mb-3 '>Hold Music</label>

          <VoiceSelect onChange={handleTypeChange} options={options} value={phoneNumberData.selectType} props={props} loading={loading} fileName={phoneNumberData?.fileName} textToSpeech={phoneNumberData?.text}
            setTextToSpeech={(value) => setPhoneNumberData(prevState => ({
              ...prevState,
              text: value
            }))} url={phoneNumberData?.url} setUrl={(value) => setPhoneNumberData(prevState => ({
              ...prevState,
              url: value
            }))} optionSpecification={true} label={"Type"} />
        </div> */}
        <Button className="callFlowButton mt-2" onClick={handleApply} >Apply</Button>
      </div >

    </>
  )
}

export default PhoneNumberNode