import { Col, Row } from "antd";

function CustomerInformationComponent(props) {
  let {
    callerImageComponent,
    lastName,
    endCall,
    customerNumber,
    skeletonImage,
    searchValue,
    callProperties,
    showEndCallBtn,
    customerInfoDetails,
  } = props;

  const openCustomerPage = (appProperties, recordId, recordModule, recordLink) => {
    console.log("openCustomerPage : callProperties >>>>>>>>>>>>>>>", callProperties);
    console.log("openCustomerPage : recordId,recordModule,recordLink >>>>>>>>>>>>>>>", recordId, recordModule, recordLink);
    if ((recordId, recordModule)) {
      appProperties.controller.openRecord(callProperties?.loadedSDK, recordId, recordModule, recordLink);
    }
  };
  return (
    <div className="onCallScreen d-flex align-items-center w-100  hs-h-15">
      <Row className={`w-100  d-flex align-items-center ps-2 ${showEndCallBtn ? "justify-content-around" : ""}`}>
        <Col span={18}>
          <Row className="w-100">
            <Col span={6}>
              <div className="d-flex flex-row align-items-center justify-content-center h-100">
                {lastName || customerNumber || searchValue ? <>{callerImageComponent}</> : <> {skeletonImage} </>}
              </div>
            </Col>
            <Col span={18}>
              <div className="d-flex flex-column align-items-middle h-100 justify-content-center">
                <span className="text-white hs-fs-14 hs-fw-500 cursor-default ellipsis" style={{ maxWidth: 150 }}>
                  {lastName}
                </span>
                <span className="text-white hs-fs-12 hs-fw-400 cursor-default ellipsis" style={{ maxWidth: 150 }}>
                  {customerNumber ? customerNumber : searchValue ? searchValue : null}
                </span>
                {customerInfoDetails}
              </div>
            </Col>
          </Row>
        </Col>

        {showEndCallBtn ? (
          <Col span={6} className="d-flex align-items-center justify-content-center">
            <div className="call-end-icon-in-answered-screen" onClick={endCall} />
          </Col>
        ) : null}
      </Row>
    </div>
  );
}

export default CustomerInformationComponent;
